import { AppData } from '@lightningjs/sdk';
import { comscoreSdk } from 'aliases';
import { EpgChannel, LiveEventChannel, Video } from 'types/api/media';
import {
  getCurrentEpgProgram,
  getEpisode,
  getSeason,
  isFullContent,
  isLiveEvent,
  isLiveProgram,
} from 'support/contentUtils';
import { AdType } from 'services/analytics/reportingServiceVideo';
import { Platform } from 'models/platforms/platform';
// @ts-ignore
import processEnv from 'processEnv';
import PrivacyService, { PrivacyConsent } from 'services/PrivacyService';

type ConstructorArgs = {
  privacyService: PrivacyService;
};

export default class ComscoreAnalytics {
  private readonly privacyService: PrivacyService;
  private readonly analytics = comscoreSdk.analytics;
  private readonly streamingAnalytics;

  private contentMetadata: any | null;
  private hasSetInitialMetadata = false;
  private hasPlaybackStarted = false;
  private isExtra: boolean | undefined;
  private isSeeking = false;
  private isPaused = false;

  constructor({ privacyService }: ConstructorArgs) {
    if (AppData === undefined) {
      throw new Error('AppData not set');
    }

    this.privacyService = privacyService;

    this.contentMetadata = null;
    const platform: Platform = processEnv.APP_PLATFORM;

    let platformApiDevice = undefined;
    switch (platform) {
      case Platform.TIZEN:
        platformApiDevice =
          this.analytics.PlatformApi.PlatformApis.SamsungTizenTV;
        break;
      case Platform.LG:
        platformApiDevice = this.analytics.PlatformApi.PlatformApis.webOS;
        break;
      case Platform.UNKNOWN:
      case Platform.VIZIO:
      default:
        platformApiDevice = this.analytics.PlatformApi.PlatformApis.html5;
    }

    this.analytics.PlatformApi.setPlatformAPI(platformApiDevice);

    /* 
    TODO: update this behaviour, at this point the privacyService won't be initialized so it will always have a csUcfr of 0
    note that currently there is no bugs/issues here. privacyService initialization does happen shortly after this
    construction (calling updatePrivacyStatus) so csUcfr is being updated correctly
    */
    const csUcfr = this.privacyService.getDoNotSell() ? '0' : '1';

    if (!AppData?.isProduction) {
      console.log(`[Privacy][Comscore] construct "csUcfr":${csUcfr}`);
    }

    const myPublisherConfig =
      new this.analytics.configuration.PublisherConfiguration({
        publisherId: AppData.comscore.publisherId,
        publisherSecret: AppData.comscore.publisherSecret,
        persistentLabels: {
          cs_ucfr: csUcfr, // indicates user has given consent to collect data
        },
      });

    this.analytics.configuration.addClient(myPublisherConfig);
    this.analytics.configuration.setApplicationName(`CW ${platform}`);

    if (!AppData.isProduction) {
      this.analytics.configuration.setDebugEnabled(true);
      this.analytics.configuration.enableImplementationValidationMode();
    }

    this.streamingAnalytics = new this.analytics.StreamingAnalytics();

    this.analytics.start();
  }

  private setVideoPlaybackMetadata(content: Video) {
    const { ContentMetadata } = this.analytics.StreamingAnalytics;

    const metadata = new ContentMetadata();

    this.isExtra = !isFullContent(content);

    let mediaType;
    if (!this.isExtra) {
      mediaType = ContentMetadata.ContentType.LONG_FORM_ON_DEMAND;
    } else {
      mediaType = ContentMetadata.ContentType.SHORT_FORM_ON_DEMAND;
    }

    const rawStartTime = new Date(content.startTime);
    const rawAirDate = new Date(content.airDate);

    const dateOfDigitalAiring = [
      rawStartTime.getFullYear(),
      rawStartTime.getMonth() + 1,
      rawStartTime.getDay() + 1,
    ];

    const timeOfDigitalAiring = [
      rawStartTime.getHours(),
      rawStartTime.getMinutes(),
    ];

    const airDate = [
      rawAirDate.getFullYear(),
      rawAirDate.getMonth() + 1,
      rawAirDate.getDay() + 1,
    ];

    metadata.setMediaType(mediaType);
    metadata.setDictionaryClassificationC3(AppData?.comscore.videoMetrixC3); // c3
    metadata.setPublisherName(AppData?.comscore.brandName); // ns_st_pu
    metadata.setStationTitle(AppData?.comscore.stationTitle); // ns_st_st
    metadata.setDictionaryClassificationC4(content.series); // c4
    metadata.setDictionaryClassificationC6(content.title); // c6
    metadata.setUniqueId(content.guid); // ns_st_ci
    metadata.setProgramTitle(content.seriesName || '*null'); // ns_st_pr
    metadata.setProgramId(content.guid);
    metadata.setEpisodeTitle(content.title); // ns_st_ep
    metadata.setEpisodeId(content.guid);
    metadata.setGenreName(content.genre || '*null'); // ns_st_ge
    metadata.setDateOfDigitalAiring(...dateOfDigitalAiring); // ns_st_ddt
    metadata.setTimeOfDigitalAiring(...timeOfDigitalAiring);
    metadata.setDateOfTvAiring(...airDate); // ns_st_tdt
    metadata.setEpisodeSeasonNumber(getSeason(content) || '*null'); // ns_st_sn
    metadata.setEpisodeNumber(getEpisode(content) || '*null'); // ns_st_en
    metadata.classifyAsCompleteEpisode(content.isFullEpisode); // ns_st_ce
    metadata.setLength(`${Number(content.durationSecs) * 1000}`); // ns_st_cl

    this.contentMetadata = metadata;
  }

  private setLivePlaybackMetadata(content: LiveEventChannel) {
    const { ContentMetadata } = this.analytics.StreamingAnalytics;

    const metadata = new ContentMetadata();

    const rawStartTime = new Date(content.startDateStream);

    const dateOfDigitalAiring = [
      rawStartTime.getFullYear(),
      rawStartTime.getMonth() + 1,
      rawStartTime.getDay() + 1,
    ];

    const timeOfDigitalAiring = [
      rawStartTime.getHours(),
      rawStartTime.getMinutes(),
    ];

    const airDate = [
      rawStartTime.getFullYear(),
      rawStartTime.getMonth() + 1,
      rawStartTime.getDay() + 1,
    ];

    metadata.setMediaType(ContentMetadata.ContentType.LIVE);
    metadata.setDictionaryClassificationC3(AppData?.comscore.videoMetrixC3); // c3
    metadata.setPublisherName(AppData?.comscore.brandName); // ns_st_pu
    metadata.setStationTitle(AppData?.comscore.stationTitle); // ns_st_st
    metadata.setDictionaryClassificationC4(content.analytics.seriesCode); // c4
    metadata.setDictionaryClassificationC6(content.title); // c6
    metadata.setUniqueId(content.analytics.guid); // ns_st_ci
    metadata.setProgramTitle(content.analytics.title || '*null'); // ns_st_pr
    metadata.setProgramId(content.analytics.guid);
    metadata.setEpisodeTitle(content.title); // ns_st_ep
    metadata.setEpisodeId(content.analytics.guid);
    metadata.setGenreName(content.analytics.comscoreGenre || '*null'); // ns_st_ge
    metadata.setDateOfDigitalAiring(...dateOfDigitalAiring); // ns_st_ddt
    metadata.setTimeOfDigitalAiring(...timeOfDigitalAiring);
    metadata.setDateOfTvAiring(...airDate); // ns_st_tdt
    metadata.classifyAsCompleteEpisode(false); // ns_st_ce
    metadata.setLength(`${Number(content.durationSecs) * 1000}`); // ns_st_cl

    this.contentMetadata = metadata;
  }

  private setEpgPlaybackMetadata(channel: EpgChannel) {
    const { ContentMetadata } = this.analytics.StreamingAnalytics;

    const epgProgram = getCurrentEpgProgram(channel);

    const metadata = new ContentMetadata();

    if (epgProgram?.startTime) {
      const rawStartTime = new Date(epgProgram!.startTime);

      const dateOfDigitalAiring = [
        rawStartTime.getFullYear(),
        rawStartTime.getMonth() + 1,
        rawStartTime.getDay() + 1,
      ];

      const timeOfDigitalAiring = [
        rawStartTime.getHours(),
        rawStartTime.getMinutes(),
      ];

      const airDate = [
        rawStartTime.getFullYear(),
        rawStartTime.getMonth() + 1,
        rawStartTime.getDay() + 1,
      ];
      metadata.setDateOfDigitalAiring(...dateOfDigitalAiring); // ns_st_ddt
      metadata.setTimeOfDigitalAiring(...timeOfDigitalAiring);
      metadata.setDateOfTvAiring(...airDate); // ns_st_tdt
    } else {
      metadata.setDateOfDigitalAiring('*null'); // ns_st_ddt
      metadata.setTimeOfDigitalAiring('*null');
      metadata.setDateOfTvAiring('*null'); // ns_st_tdt
    }

    const duration = epgProgram?.durationSecs;

    metadata.setMediaType(ContentMetadata.ContentType.LIVE);
    metadata.setDictionaryClassificationC3(AppData?.comscore.videoMetrixC3); // c3
    metadata.setPublisherName(AppData?.comscore.brandName); // ns_st_pu
    metadata.setStationTitle(AppData?.comscore.stationTitle); // ns_st_st
    metadata.setDictionaryClassificationC6(epgProgram?.title || '*null'); // c6
    metadata.setUniqueId(epgProgram?.assetId || '*null'); // ns_st_ci
    metadata.setProgramTitle(epgProgram?.title || '*null'); // ns_st_pr
    metadata.setProgramId(epgProgram?.seriesSlug || '*null');
    metadata.setEpisodeTitle(epgProgram?.subtitle || '*null'); // ns_st_ep
    metadata.setEpisodeId(epgProgram?.assetId || '*null');
    metadata.classifyAsCompleteEpisode(false); // ns_st_ce
    metadata.setLength(
      duration !== undefined ? `${Number(duration) * 1000}` : '0',
    ); // ns_st_cl

    this.contentMetadata = metadata;
  }

  private createPlaybackSession() {
    this.streamingAnalytics.createPlaybackSession();
  }

  private stopTracking() {
    this.streamingAnalytics.notifyEnd();
  }

  updatePrivacyStatus(privacyConsent: PrivacyConsent) {
    const value = privacyConsent === 'opt-in' ? '1' : '0';

    if (!AppData?.isProduction) {
      console.log(
        `[Privacy][Comscore] updatePrivacyStatus "consent":${privacyConsent} "passed value":${value}`,
      );
    }

    this.analytics.configuration
      .getPublisherConfiguration(AppData!.comscore.publisherId)
      .setPersistentLabel('cs_ucfr', value);
    this.analytics.notifyHiddenEvent();
  }

  setContentMetadata(content: Video | LiveEventChannel | EpgChannel) {
    // check if we haven't ended the previous session (this case can occur when programs change in the EPG)
    if (this.contentMetadata !== null) {
      this.contentMetadata = null;
      this.stopTracking();
    }

    // We create a new session when content changes
    this.createPlaybackSession();

    this.hasPlaybackStarted = false;

    // this will be set in function(s) below
    this.isExtra = undefined;

    if (isLiveProgram(content)) {
      this.setEpgPlaybackMetadata(content as EpgChannel);
      this.streamingAnalytics.setMetadata(this.contentMetadata);
      this.hasSetInitialMetadata = true;
    } else if (isLiveEvent(content)) {
      this.setLivePlaybackMetadata(content as LiveEventChannel);
      this.streamingAnalytics.setMetadata(this.contentMetadata);
      this.hasSetInitialMetadata = true;
    } else {
      // we don't set metadata until we've determined if there's a pre-roll
      this.setVideoPlaybackMetadata(content as Video);
      this.hasSetInitialMetadata = false;
    }
  }

  reportBufferStart(position: number) {
    if (!this.hasSetInitialMetadata) return;

    this.streamingAnalytics.startFromPosition(Math.ceil(position));
    this.streamingAnalytics.notifyBufferStart();
  }

  reportBufferEnd(position: number, resumePlayback: boolean) {
    if (!this.hasSetInitialMetadata) return;

    this.streamingAnalytics.startFromPosition(Math.ceil(position));
    this.streamingAnalytics.notifyBufferStop();
    if (resumePlayback) this.reportResume(position);
  }

  reportStart(startTime: number) {
    this.hasPlaybackStarted = true;
    this.isPaused = false;

    // We assume if the start time is > 0 we have skipped the pre-roll and can report the content metadata
    if (this.hasSetInitialMetadata) {
      this.streamingAnalytics.notifyPlay();
    } else if (startTime > 0 || this.isExtra) {
      this.streamingAnalytics.setMetadata(this.contentMetadata);
      this.hasSetInitialMetadata = true;
      this.streamingAnalytics.notifyPlay();
    } else {
      // we will report the ad metadata when the ad/pre-roll starts
    }
  }

  reportResume(position: number) {
    if (!this.hasSetInitialMetadata || !this.isPaused) return;
    if (this.isSeeking) this.reportSeekEnd(position);

    this.isPaused = false;
    this.streamingAnalytics.notifyPlay();
  }

  reportPause() {
    if (!this.hasSetInitialMetadata || this.isPaused) return;

    this.isPaused = true;
    this.streamingAnalytics.notifyPause();
  }

  reportAdPodStart(adType: AdType) {
    if (adType !== 'pre-roll') {
      this.stopTracking();
    }
  }

  reportAdStart(type: AdType, durationMs: number) {
    const { AdvertisementMetadata } = this.analytics.StreamingAnalytics;

    const metadata = new AdvertisementMetadata();

    let mediaType;
    if (type === 'pre-roll') {
      mediaType = AdvertisementMetadata.AdvertisementType.ON_DEMAND_PRE_ROLL;
    } else if (type === 'mid-roll') {
      mediaType =
        AdvertisementMetadata.AdvertisementType.BRANDED_ON_DEMAND_MID_ROLL;
    } else {
      mediaType = AdvertisementMetadata.AdvertisementType.ON_DEMAND_POST_ROLL;
    }

    metadata.setMediaType(mediaType);
    metadata.setRelatedContentMetadata(this.contentMetadata);
    metadata.setLength(Math.ceil(durationMs));

    this.streamingAnalytics.setMetadata(metadata);

    // if we have not yet reported the initial metadata but playback has started we should notify play
    if (!this.hasSetInitialMetadata && this.hasPlaybackStarted) {
      this.streamingAnalytics.notifyPlay();
    }

    this.hasSetInitialMetadata = true;
  }

  reportAdPodEnd() {
    this.streamingAnalytics.setMetadata(this.contentMetadata);
  }

  reportEnd() {
    if (this.contentMetadata === null) return;

    this.contentMetadata = null;
    this.stopTracking();
  }

  reportSeekStart(position: number) {
    if (!this.hasSetInitialMetadata || this.isSeeking) return;

    // Comscore does not report player updates if the state has not changed (calling
    // notifyPause after notifyPause will not send anything). Here, we call
    // notifyPlay to ensure notifySeekStart (treated as a pause) gets logged
    this.reportResume(position);
    this.streamingAnalytics.notifySeekStart(); // Appears as a pause event
    this.isPaused = true;
    this.isSeeking = true;
  }

  reportSeekEnd(position: number) {
    if (!this.hasSetInitialMetadata || !this.isSeeking) return;

    this.streamingAnalytics.startFromPosition(Math.ceil(position));
    this.isSeeking = false;
  }

  reportForegrounded = () => {
    this.analytics.notifyEnterForeground();
  };

  reportBackgrounded = () => {
    this.analytics.notifyExitForeground();
  };

  reportEpgProgramSwitch(channel: EpgChannel) {
    this.setContentMetadata(channel);
    this.streamingAnalytics.notifyPlay();
  }
}
