import { AppData } from '@lightningjs/sdk';
import { AppInstance } from '@lightningjs/sdk/src/Application';

/**
 * Calls the augmented Application's close app function.
 */
export const closeApp = () => {
  (AppInstance?.application as any)?.closeApp();
};

export const shouldUseDevAccessHeader = () => {
  const isProduction = AppData?.isProduction;
  const { header, key } = AppData?.devAccessHeader ?? {};
  return !isProduction && !!header && !!key;
};
