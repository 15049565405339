import { Router } from '@lightningjs/sdk';
import Page from 'components/Page';
import { PageId } from 'types/pageId';
import Request from '@lightningjs/sdk/src/Router/model/Request';
import { ErrorCause } from 'components/widgets/ErrorModal';

export default class ErrorPage extends Page {
  protected override _pageId = PageId.ERROR;

  override _onUrlParams(params: Router.PageParams) {
    const cause = params?.cause as ErrorCause | undefined;
    const navigationRequest = params?.request as Request | undefined;
    this.widgets.errormodal.update({ cause, navigationRequest });

    Router.focusWidget('ErrorModal');
  }
}
