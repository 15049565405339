import { Lightning } from '@lightningjs/sdk';

import { List } from '@lightningjs/ui';
import { STANDARD_TRANSLATION } from 'support/animations';
import PortraitTile from 'components/common/tiles/PortraitTile';
import ChannelTile from 'components/common/tiles/ChannelTile';
import VideoTile from 'components/common/tiles/VideoTile';
import { ExpandableList } from 'components/common/ExpandableList';
import FeaturedTile from 'components/common/tiles/FeaturedTile';
import { getFontFaceFromStyle } from 'support/textUtils';
import Highlight from 'components/common/Highlight';
import ContinueTile from 'components/common/tiles/ContinueTile';
import SearchTile from 'components/common/tiles/SearchTile';
import LandscapeTile from 'components/common/tiles/LandscapeTile';
import SquareContentHubTile from 'components/common/tiles/SquareContentHubTile';
import VerticalContentHubTile from 'components/common/tiles/VerticalContentHubTile';
import HorizontalContentHubTile from 'components/common/tiles/HorizontalContentHubTile';
import WatchHistoryRecommendationTile from 'components/common/tiles/WatchHistoryRecommendationTile';
import { constants } from 'aliases';
import { HoverableListItem } from 'components/common/HoverableListItem';
import LiveEventTile from 'components/common/tiles/LiveEventTile';

export type ListTypes =
  | typeof ChannelTile
  | typeof ContinueTile
  | typeof FeaturedTile
  | typeof LandscapeTile
  | typeof PortraitTile
  | typeof SearchTile
  | typeof VideoTile
  | typeof SquareContentHubTile
  | typeof VerticalContentHubTile
  | typeof HorizontalContentHubTile
  | typeof WatchHistoryRecommendationTile
  | typeof LiveEventTile;

export interface SizedRowListTemplateSpec
  extends Lightning.Component.TemplateSpec {
  label: string | undefined;
  listType: ListTypes;
  itemWidth: number;
  expandedItemWidth: number | null;
  enableRequests: boolean;
  items: any[];
  Content: {
    Label: Lightning.Component;
    ListContainer: {
      List: typeof List;
      Highlight: typeof Highlight;
    };
  };
}

const BORDER_GAP = 30;

const HIGHLIGHT_SIZE_OFFSET = 12;
const HIGHLIGHT_POSITION_OFFSET = constants.ui.highlightPositionOffset;

export const ROW_LIST_OFFSET_Y = 62;

export default class SizedRowList
  extends HoverableListItem<SizedRowListTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<SizedRowListTemplateSpec>
{
  private _Content = this.getByRef('Content')!;
  private _Label = this._Content.getByRef('Label')!;
  private _ListContainer = this._Content.getByRef('ListContainer')!;
  private _List = this._ListContainer.getByRef('List')!;
  private _Highlight = this._ListContainer.getByRef('Highlight')!;

  private _label: string | undefined;
  private _onRepositionedCallback: (() => void) | undefined;

  itemWidth = 0;
  expandedItemWidth = null;

  get title() {
    return this._Label.text?.text ?? '';
  }

  set label(label: string | undefined) {
    this._label = label;
    if (label) {
      this._Label.patch({ visible: true, text: { text: label } });
      this._ListContainer.patch({ y: ROW_LIST_OFFSET_Y });
    } else {
      this._Label.patch({ visible: false });
      this._ListContainer.patch({ y: 0 });
    }
  }

  get label() {
    return this._label;
  }

  set listType(type: ListTypes) {
    this._Highlight.patch({
      w: type.highlightWidth + HIGHLIGHT_SIZE_OFFSET,
      h: type.highlightHeight + HIGHLIGHT_SIZE_OFFSET,
      x: HIGHLIGHT_POSITION_OFFSET,
      y: HIGHLIGHT_POSITION_OFFSET,
    });
    this._List.patch({
      w: type.width,
      h: type.height,
      itemType: type,
    });
  }

  set items(items: any[]) {
    if (!items.length) this._List.clear();
    else this._List.items = items;
  }

  get items() {
    return this._List.items;
  }

  get itemWrappers() {
    return this._List.itemWrappers;
  }

  set enableRequests(value: boolean) {
    this._List.enableRequests = value;
  }

  get length() {
    return this._List.length;
  }

  get index() {
    return this._List.index;
  }

  static override _template(): Lightning.Component.Template<SizedRowListTemplateSpec> {
    return {
      Content: {
        Label: {
          visible: true,
          text: {
            verticalAlign: 'middle',
            lineHeight: 42,
            fontFace: getFontFaceFromStyle('medium'),
            fontSize: 36,
          },
        },
        ListContainer: {
          y: ROW_LIST_OFFSET_Y,
          List: {
            type: ExpandableList,
            direction: 'row',
            spacing: BORDER_GAP,
            scrollTransition: { ...STANDARD_TRANSLATION.x },
            requestThreshold: 1,
            signals: {
              onIndexChanged: '_onIndexChanged',
              onRequestItems: '_onRequestItems',
              onItemsRepositioned: '_onItemsRepositioned',
            },
          },
          Highlight: {
            type: Highlight,
            visible: false,
            zIndex: 3,
          },
        },
      },
    };
  }

  override _setup() {
    this._List.scroll = this.handleListScroll.bind(this);

    this._Highlight.transition('w').on('finish', () => {
      this._List.reposition(0);
    });
    this._Highlight.transition('w').on('progress', () => {
      this._List.reposition(0);
    });

    // Add margin to extend the rendering area to the bottom of the row, so that
    // it still allows hover when top portions are out of view.
    this.boundsMargin = [0, 0, 0, this._List.h * 0.6];
  }

  override _getFocused() {
    return this._List;
  }

  override _focus() {
    this._Highlight.patch({ visible: true });
  }

  override _unfocus() {
    this._Highlight.patch({ visible: false });
  }

  override _inactive() {
    this.transition('w').removeAllListeners('finish');
    this.transition('w').removeAllListeners('progress');
  }

  private _onIndexChanged(args: {
    previousIndex: number;
    index: number;
    dataLength: number;
  }) {
    this.fireAncestors('$onSizedRowListIndexChange', args);
  }

  private _onItemsRepositioned() {
    if (this._onRepositionedCallback) this._onRepositionedCallback();
    this._onRepositionedCallback = undefined;
  }

  private handleListScroll(itemWrapper: unknown, { index, dataLength }: any) {
    let scrollResult: number;

    const rowListWidth =
      this.w -
      (this.expandedItemWidth ? this.expandedItemWidth - this.itemWidth : 0); // if the list can expand, we subtract the amount it can expand by to make room
    const totalItemWidth = this.itemWidth + BORDER_GAP;
    const itemPosition = totalItemWidth * index;

    const remainingLength = dataLength - index;
    const remainingWidth = remainingLength * totalItemWidth + BORDER_GAP;

    if (itemPosition + remainingWidth < rowListWidth) {
      // the row is smaller then the screen, so no scrolling is needed
      scrollResult = 0;
    } else if (remainingWidth > rowListWidth) {
      // the remaining items can't all fit on screen, so we scroll the width of one item
      scrollResult = 0 - itemPosition;
    } else {
      // all items fit on screen, so we fix the scroll (note that itemPosition is inversely proportional to remainingWidth)
      scrollResult = rowListWidth - (itemPosition + remainingWidth);
    }

    // update highlight position
    const highlightX = itemPosition + scrollResult + HIGHLIGHT_POSITION_OFFSET;
    this._Highlight.patch({ smooth: { x: highlightX } });

    return scrollResult;
  }

  $repositionList(onRepositionedCallback?: () => void) {
    this._onRepositionedCallback = onRepositionedCallback;
    this._List.reposition(0);
  }

  $expandList(transition: object) {
    this._Highlight.patch(transition);
  }
}
