import { Colors, Lightning } from '@lightningjs/sdk';
import { Live, LiveEvent, Show } from 'types/api/media';
import { STANDARD_FADE } from 'support/animations';
import { translate } from 'support/translate';
import { isLiveContent, isLiveEvent, isMovie } from 'support/contentUtils';
import MetadataLabel from 'components/common/MetadataLabel';
import { getImageTextureContainObj, ttsTagRemoval } from 'support/generalUtils';
import { getFontFaceFromStyle } from 'support/textUtils';

export interface MoreInfoModalTemplateSpec
  extends Lightning.Component.TemplateSpec {
  mediaContent: Show | Live | LiveEvent | undefined;

  Background: Lightning.Component;
  Container: {
    ImageLogo: Lightning.Component;
    Title: Lightning.textures.TextTexture;
    Metadata: typeof MetadataLabel;
    Description: Lightning.textures.TextTexture;
    Casts: Lightning.textures.TextTexture;
    ProductionTeam: Lightning.textures.TextTexture;
  };
}
const MODAL_Z_INDEX = 51;

const BACKGROUND_WIDTH = 1920;
const BACKGROUND_HEIGHT = 1080;

const CONTAINER_MOUNT_X = 0.5;
const CONTAINER_MOUNT_POINT_X = 960;
const CONTAINER_MOUNT_POINT_Y = 175;

const LOGO_WIDTH = 565;
const LOGO_HEIGHT = 230;

const FONT_SIZE = 29;

const TITLE_MAX_LINES = 1;
const TITLE_MARGIN_TOP = 53;

const METADATA_MARGIN_TOP = 15;

const DESCRIPTION_WIDTH = 1176;
const DESCRIPTION_MAX_LINES = 8;
const DESCRIPTION_LINE_HEIGHT = 34;
const DESCRIPTION_MARGIN_TOP = 36;

const CAST_MARGIN_TOP = 20;
const CAST_MAX_LINES = 2;

const DIRECTORS_MARGIN_TOP = 20;
const DIRECTORS_MAX_LINES = 2;

export default class MoreInfoModal
  extends Lightning.Component<MoreInfoModalTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<MoreInfoModalTemplateSpec>
{
  private _Container = this.getByRef('Container')!;
  private _ImageLogo = this._Container.getByRef('ImageLogo')!;
  private _Title = this._Container.getByRef('Title')!;
  private _Metadata = this._Container.getByRef('Metadata')!;
  private _Description = this._Container.getByRef('Description')!;
  private _Casts = this._Container.getByRef('Casts')!;
  private _ProductionTeam = this._Container.getByRef('ProductionTeam')!;

  private _mediaContent: Show | Live | LiveEvent | undefined;

  get title() {
    if (!this._mediaContent) return '';

    let title = [];

    if (isLiveContent(this._mediaContent)) {
      const liveContent = this._mediaContent as Live;
      title = [liveContent.title ?? '', liveContent.description ?? ''];
    } else if (isLiveEvent(this._mediaContent)) {
      const liveEvent = this._mediaContent as LiveEvent;
      title = [
        liveEvent.title ?? '',
        liveEvent.tuneIn ?? '',
        liveEvent.description ?? '',
      ];
    } else {
      const showContent = this._mediaContent as Show;
      title = [
        showContent.title ?? '',
        showContent.rating ?? '',
        showContent.activeYears,
        showContent.genres.join(', '),
        showContent.description,
        ttsTagRemoval(this._Casts.text?.text ?? ''),
        ttsTagRemoval(this._ProductionTeam.text?.text ?? ''),
      ];
    }

    return title;
  }

  set mediaContent(mediaContent: Show | Live | LiveEvent) {
    this._mediaContent = mediaContent;
    this.updateTitle(mediaContent);
    this.updateMetadata(mediaContent);
    this.updateLogo(mediaContent);
    this._Description.patch({ text: mediaContent.description });
    if (!isLiveContent(mediaContent) && !isLiveEvent(mediaContent)) {
      this.setupCasts(mediaContent as Show);
      this.setupProductionTeam(mediaContent as Show);
    }
  }

  static override _template() {
    return {
      transitions: STANDARD_FADE,
      Background: {
        zIndex: MODAL_Z_INDEX,
        collision: true,
        rect: true,
        h: BACKGROUND_HEIGHT,
        w: BACKGROUND_WIDTH,
        color: Colors('modalBackground').get(),
      },
      Container: {
        zIndex: MODAL_Z_INDEX,
        mountX: CONTAINER_MOUNT_X,
        x: CONTAINER_MOUNT_POINT_X,
        y: CONTAINER_MOUNT_POINT_Y,
        flex: {
          direction: 'column',
          alignItems: 'center',
        },
        ImageLogo: {
          h: LOGO_HEIGHT,
          w: LOGO_WIDTH,
        },
        Title: {
          flexItem: { marginTop: TITLE_MARGIN_TOP },
          visible: false,
          w: DESCRIPTION_WIDTH,
          text: {
            textAlign: 'center',
            fontSize: FONT_SIZE,
            fontFace: getFontFaceFromStyle('bold'),
            maxLines: TITLE_MAX_LINES,
          },
        },
        Metadata: {
          type: MetadataLabel,
          flexItem: { marginTop: METADATA_MARGIN_TOP },
        },
        Description: {
          flexItem: { marginTop: DESCRIPTION_MARGIN_TOP },
          w: DESCRIPTION_WIDTH,
          text: {
            advancedRenderer: true,
            maxLines: DESCRIPTION_MAX_LINES,
            lineHeight: DESCRIPTION_LINE_HEIGHT,
            fontSize: FONT_SIZE,
          },
        },
        Casts: {
          flexItem: { marginTop: CAST_MARGIN_TOP },
          w: DESCRIPTION_WIDTH,
          text: {
            advancedRenderer: true,
            maxLines: CAST_MAX_LINES,
            lineHeight: DESCRIPTION_LINE_HEIGHT,
            fontSize: FONT_SIZE,
            wordBreak: true,
          },
        },
        ProductionTeam: {
          flexItem: { marginTop: DIRECTORS_MARGIN_TOP },
          w: DESCRIPTION_WIDTH,
          text: {
            advancedRenderer: true,
            maxLines: DIRECTORS_MAX_LINES,
            lineHeight: DESCRIPTION_LINE_HEIGHT,
            fontSize: FONT_SIZE,
            wordBreak: true,
          },
        },
      },
    };
  }

  override _inactive() {
    this._ImageLogo.removeAllListeners('txError');
  }

  private setupCasts(show: Show) {
    const { showCast } = show;
    if (!showCast) return;

    this._Casts.patch({
      text: {
        text: translate('moreInfo.castBoldWithNames', showCast),
      },
    });
  }

  private setupProductionTeam(show: Show) {
    const { showDirectors, showProducers } = show;
    let text = '';
    if (isMovie(show)) {
      text = translate('moreInfo.directorsBoldWithNames', showDirectors);
    } else {
      text = translate(
        'moreInfo.executiveProducersBoldWithNames',
        showProducers,
      );
    }

    this._ProductionTeam.patch({
      text: {
        text: text,
      },
    });
  }

  private updateTitle(mediaContent: Show | Live | LiveEvent) {
    if (isLiveEvent(mediaContent)) {
      const title = mediaContent.title;
      this._Title.patch({ visible: true, text: { text: title } });
      this._Metadata.patch({ flexItem: { marginTop: 0 } });
    } else {
      this._Title.patch({ visible: false });
      this._Metadata.patch({ flexItem: { marginTop: METADATA_MARGIN_TOP } });
    }
  }

  private updateMetadata(mediaContent: Show | Live | LiveEvent) {
    this._Metadata.patch({ mediaContent });
    this.stage.update();
  }

  private updateLogo(mediaContent: Show | Live | LiveEvent) {
    this._ImageLogo.removeAllListeners('txError');
    this._ImageLogo.on('txError', () => {
      this._ImageLogo.patch({ texture: undefined });
    });

    let logoSrc = '';
    if (isLiveContent(mediaContent)) {
      logoSrc = (mediaContent as Live).showcaseLogo ?? '';
    } else if (isLiveEvent(mediaContent)) {
      logoSrc = (mediaContent as LiveEvent).images.eventLockup ?? '';
    } else {
      logoSrc = (mediaContent as Show).images.image_logo_white ?? '';
    }

    const logoTexture = getImageTextureContainObj(
      logoSrc,
      LOGO_WIDTH,
      LOGO_HEIGHT,
    );

    this._ImageLogo.patch(logoTexture);
  }
}
