import { Platform } from 'models/platforms/platform';
import { Registry } from '@lightningjs/sdk';

import { metadata } from 'aliases';
import { AbstractDeviceIntegration } from 'config/platforms/AbstractDeviceIntegration';
import { loadVizioScript } from 'aliases';
import { Manufacturer } from 'models/platforms/manufacturer';
import { VizioKeyMapping } from 'models/platforms/VizioKeyMappings';
import { ApplicationInstance } from '@lightningjs/sdk/src/Launch';
import {
  VizioCcStyleKey,
  VizioCcStyleMenuValue,
} from 'models/platforms/vizioWindow';
import {
  CcEdgeType,
  translateCcSettingToCss,
} from 'config/platforms/deviceIntegration';
import { DeviceInfo } from 'models/platforms/deviceInfo';
import { constants } from 'aliases';

type TvInfoMenuValueStringMap = Partial<Record<VizioCcStyleMenuValue, string>>;
type TvInfoMenuValueEdgeTypeMap = Partial<
  Record<VizioCcStyleMenuValue, CcEdgeType>
>;
type TvInfoMenuValueNumberMap = Partial<Record<VizioCcStyleMenuValue, number>>;

const FLASH_INTERVAL = constants.timers.subtitleFlashingInterval;
export class VizioDeviceIntegration extends AbstractDeviceIntegration {
  protected platform = Platform.VIZIO;

  private vizio: any;
  readonly colorMap: TvInfoMenuValueStringMap = {
    [VizioCcStyleMenuValue.CAPTION_COLOR_BLACK]: '#000000',
    [VizioCcStyleMenuValue.CAPTION_COLOR_WHITE]: '#ffffff',
    [VizioCcStyleMenuValue.CAPTION_COLOR_GREEN]: '#00ff00',
    [VizioCcStyleMenuValue.CAPTION_COLOR_BLUE]: '#0000ff',
    [VizioCcStyleMenuValue.CAPTION_COLOR_RED]: '#ff0000',
    [VizioCcStyleMenuValue.CAPTION_COLOR_CYAN]: '#00ffff',
    [VizioCcStyleMenuValue.CAPTION_COLOR_YELLOW]: '#ffff00',
    [VizioCcStyleMenuValue.CAPTION_COLOR_MAGENTA]: '#ff00ff',
  };
  readonly opacityMap: TvInfoMenuValueStringMap = {
    [VizioCcStyleMenuValue.CAPTION_OPACITY_DEFAULT]: 'ff',
    [VizioCcStyleMenuValue.CAPTION_OPACITY_SOLID]: 'ff',
    [VizioCcStyleMenuValue.CAPTION_OPACITY_TRANSLUCENT]: '80',
    [VizioCcStyleMenuValue.CAPTION_OPACITY_TRANSPARENT]: '00',
    [VizioCcStyleMenuValue.CAPTION_OPACITY_FLASH]: 'ff',
  };
  readonly fontSizeMap: TvInfoMenuValueNumberMap = {
    [VizioCcStyleMenuValue.CAPTION_SIZE_DEFAULT]: 36,
    [VizioCcStyleMenuValue.CAPTION_SIZE_SMALL]: 24,
    [VizioCcStyleMenuValue.CAPTION_SIZE_MEDIUM]: 36,
    [VizioCcStyleMenuValue.CAPTION_SIZE_LARGE]: 60,
  };
  readonly fontFaceMap: TvInfoMenuValueStringMap = {
    // best guess
    [VizioCcStyleMenuValue.CAPTION_FONT_DEFAULT]: 'Roboto',
    [VizioCcStyleMenuValue.CAPTION_FONT_STYLE0]: 'Century',
    [VizioCcStyleMenuValue.CAPTION_FONT_STYLE1]: 'Times New Roman',
    [VizioCcStyleMenuValue.CAPTION_FONT_STYLE2]: 'Roboto',
    [VizioCcStyleMenuValue.CAPTION_FONT_STYLE3]: 'Tiresias Screenfont',
    [VizioCcStyleMenuValue.CAPTION_FONT_STYLE4]: 'Arial Condensed Bold',
    // cannot identify
    [VizioCcStyleMenuValue.CAPTION_FONT_STYLE5]: 'Dolce',
    [VizioCcStyleMenuValue.CAPTION_FONT_STYLE6]: 'Spectral SC REgular',
  };
  readonly edgeTypeMap: TvInfoMenuValueEdgeTypeMap = {
    [VizioCcStyleMenuValue.CAPTION_EDGE_DEFAULT]: '0px 0px 0px',
    [VizioCcStyleMenuValue.CAPTION_EDGE_NONE]: '0px 0px 0px',
    [VizioCcStyleMenuValue.CAPTION_EDGE_RAISED]: '-2px -2px 2px',
    [VizioCcStyleMenuValue.CAPTION_EDGE_DEPRESSED]: '2px 0px 2px',
    [VizioCcStyleMenuValue.CAPTION_EDGE_UNIFORM]: '0px 0px 4px',
    [VizioCcStyleMenuValue.CAPTION_EDGE_DROP_SHADOWED]: '-4px -4px 4px',
  };

  private _flashTimer: number | null = null;
  private _flashOn = true;
  private _getDeviceInfoPromise: Promise<DeviceInfo> | null = null;

  constructor() {
    super();
    this.deviceInfo.manufacturer = Manufacturer.VIZIO;
  }

  private callbackToPromise<T>(
    fn: (cb: (value: T) => void) => void,
  ): Promise<T> {
    return new Promise<T>(resolve => fn.bind(this.vizio)(resolve));
  }

  override getKeyMapping() {
    return VizioKeyMapping;
  }

  getDeviceId(): string {
    return this.deviceId;
  }

  override getPerformanceMode(): boolean {
    return false;
  }

  getAppId(): string {
    return metadata.identifier;
  }

  async load() {
    const listenToDidLoad = new Promise<void>(resolve =>
      document.addEventListener('VIZIO_LIBRARY_DID_LOAD', (e: unknown) => {
        console.log('[vizio]', 'DID Loaded');
        resolve();
      }),
    );

    await loadVizioScript();
    await listenToDidLoad;

    this.vizio = (window as any).VIZIO;

    // We don't await here, await will be handled when getDeviceInfo is called
    this._getDeviceInfoPromise = this.initializeDeviceInfo();

    this.vizio.setClosedCaptionHandler((isCcEnabled: boolean) => {
      this._ccSettings.enabled = isCcEnabled;
    });

    this.vizio.getClosedCaptionStyles((captionStyleObj: any) => {
      if (captionStyleObj) {
        try {
          for (const menuKey of Object.values(VizioCcStyleKey)) {
            this.updateCcSetting(menuKey, captionStyleObj[menuKey]);
          }
        } catch (e) {
          // catching anything where menuKey cannot be found in captionStyleObj
        }
      }
    });

    this.handleDeepLinkChange();
    this.handleCcChange();
  }

  beforeAppClose(callback: (showPopup: boolean) => void) {
    callback(true);
  }

  closeApp() {
    (window as any).VIZIO.exitApplication();
  }

  handleDeepLink() {
    // Not Supported
    return false;
  }

  handleNetworkChange(callback: (arg: boolean) => void) {
    Registry.addEventListener(window, 'online', function () {
      callback(true);
    });
    Registry.addEventListener(window, 'offline', function () {
      callback(false);
    });
  }

  setScreenSaver(state: boolean) {
    return;
  }

  getAnnouncerEnabled() {
    return false;
  }

  handleAnnouncerChange(callback: (arg: boolean) => void): void {
    Registry.addEventListener(document, 'VIZIO_TTS_ENABLED', () => {
      callback(true);
    });
    Registry.addEventListener(document, 'VIZIO_TTS_DISABLED', () => {
      callback(false);
    });
  }

  override getDeviceInfo(): Promise<DeviceInfo> {
    return this._getDeviceInfoPromise!;
  }

  private async initializeDeviceInfo() {
    const setAdId = new Promise<void>(resolve => {
      this.vizio.setAdvertiserIDListener((AdvertiserID: any) => {
        console.log('[vizio]', 'Advertiser ID set ', AdvertiserID);
        this.deviceInfo.adId = AdvertiserID.IFA;
        this.deviceInfo.isLat = AdvertiserID.LMT;
        this.isLat = AdvertiserID.LMT;
        this.deviceInfo.ifaType = AdvertiserID.IFA_TYPE;
        resolve();
      });
    });

    const [deviceIdResult, networkInfoResult, systemInfoResult] =
      await Promise.allSettled([
        this.callbackToPromise<string>(this.vizio.getDeviceId),
        this.vizio.getNetworkInformation(),
        this.vizio.getSystemInformation(),
        setAdId,
      ]);

    this.deviceId =
      deviceIdResult.status === 'fulfilled' ? deviceIdResult.value : '';

    this.deviceInfo.manufacturer = Manufacturer.VIZIO;

    if (systemInfoResult.status === 'fulfilled') {
      const { MODEL_NAME, VERSION } = systemInfoResult.value;

      this.deviceInfo.model = MODEL_NAME;
      this.deviceInfo.osVersion = VERSION;
    } else {
      this.deviceInfo.model = '';
      this.deviceInfo.osVersion = '';
    }

    if (
      networkInfoResult.status === 'fulfilled' &&
      networkInfoResult.value.result === 'SUCCESS'
    ) {
      const info = (networkInfoResult.value.value as any[]).find(
        info => info.CNAME === 'ip_address',
      );

      this.deviceInfo.ip = info.VALUE;
    } else {
      this.deviceInfo.ip = '';
    }

    return this.deviceInfo;
  }

  private updateCcSetting(
    key: (typeof VizioCcStyleKey)[keyof typeof VizioCcStyleKey],
    value: VizioCcStyleMenuValue,
  ) {
    const style =
      document.getElementById('caption-styles') ??
      document.createElement('style');
    style.id = 'caption-styles';

    switch (key) {
      case VizioCcStyleKey.BACKGROUND_COLOR:
        if (value === VizioCcStyleMenuValue.CAPTION_COLOR_DEFAULT) {
          value = VizioCcStyleMenuValue.CAPTION_COLOR_BLACK;
        }

        this._ccSettings.backgroundColor = this.colorMap[value];
        break;
      case VizioCcStyleKey.BACKGROUND_OPACITY:
        this._ccSettings.backgroundFlashing =
          value === VizioCcStyleMenuValue.CAPTION_OPACITY_FLASH;

        this._ccSettings.backgroundOpacity = this.opacityMap[value];
        break;
      case VizioCcStyleKey.TEXT_COLOR:
        if (value === VizioCcStyleMenuValue.CAPTION_COLOR_DEFAULT) {
          value = VizioCcStyleMenuValue.CAPTION_COLOR_WHITE;
        }

        this._ccSettings.color = this.colorMap[value];
        break;
      case VizioCcStyleKey.TEXT_EDGES:
        this._ccSettings.edgeType = this.edgeTypeMap[value];
        break;
      case VizioCcStyleKey.TEXT_EDGES_COLOR:
        if (value === VizioCcStyleMenuValue.CAPTION_COLOR_DEFAULT) {
          value = VizioCcStyleMenuValue.CAPTION_COLOR_BLACK;
        }

        this._ccSettings.edgeColor = this.colorMap[value];
        break;
      case VizioCcStyleKey.TEXT_OPACITY:
        this._ccSettings.colorFlashing =
          value === VizioCcStyleMenuValue.CAPTION_OPACITY_FLASH;

        this._ccSettings.colorOpacity = this.opacityMap[value];
        break;
      case VizioCcStyleKey.TEXT_SIZE:
        this._ccSettings.fontSize = this.fontSizeMap[value];
        break;
      case VizioCcStyleKey.TEXT_STYLE:
        this._ccSettings.fontFace = this.fontFaceMap[value];
        break;
      case VizioCcStyleKey.WINDOW_COLOR:
        if (value === VizioCcStyleMenuValue.CAPTION_COLOR_DEFAULT) {
          value = VizioCcStyleMenuValue.CAPTION_COLOR_BLACK;
        }

        this._ccSettings.windowColor = this.colorMap[value];
        break;
      case VizioCcStyleKey.WINDOW_OPACITY:
        this._ccSettings.windowFlashing =
          value === VizioCcStyleMenuValue.CAPTION_OPACITY_FLASH;

        this._ccSettings.windowOpacity = this.opacityMap[value];
        break;
      default:
        break;
    }

    if (
      !this._flashTimer &&
      (this._ccSettings.backgroundFlashing || this._ccSettings.colorFlashing)
    ) {
      this._flashTimer = Registry.setInterval(() => {
        if (this._ccSettings.colorFlashing) {
          this._ccSettings.colorOpacity = this._flashOn ? 'ff' : '00';
        }
        if (this._ccSettings.backgroundFlashing) {
          this._ccSettings.backgroundOpacity = this._flashOn ? 'ff' : '00';
        }
        if (this._ccSettings.windowFlashing) {
          this._ccSettings.windowOpacity = this._flashOn ? 'ff' : '00';
        }

        const style =
          document.getElementById('caption-styles') ??
          document.createElement('style');
        style.id = 'caption-styles';
        style.innerHTML = translateCcSettingToCss(this._ccSettings);
        document.head.appendChild(style);

        this._flashOn = !this._flashOn;
      }, FLASH_INTERVAL);
    } else {
      if (this._flashTimer) {
        Registry.clearInterval(this._flashTimer);
        this._flashTimer = null;
      }
      style.innerHTML = translateCcSettingToCss(this._ccSettings);
      document.head.appendChild(style);
    }
  }

  private handleDeepLinkChange(): void {
    // Assume URL will be in the form `[http|https]://[DOMAIN]/#[RoutePath]/[...params]`
    this.vizio.setContentChangeHandler((url: string) => {
      this.handleDeepLinkURL(url);
    });
  }

  private handleCcChange() {
    (window as any).VIZIO.setClosedCaptionHandler((ccEnabled: boolean) => {
      this._ccSettings.enabled = ccEnabled;
      ApplicationInstance?.emit('ccChange', ccEnabled);
    });
  }
}
