import { VideoPlayer } from '@lightningjs/sdk';
import mParticle from '@mparticle/web-sdk';

import '@mparticle/web-google-analytics-4-client-kit';
import { EpgChannel, LiveEventChannel, Media, Video } from 'types/api/media';
import {
  getCurrentEpgProgram,
  getSeason,
  isFullContent,
  isLiveEvent,
  isLiveProgram,
} from 'support/contentUtils';
import { PageId } from 'types/pageId';
import { ErrorType, SeekType, StartType } from 'types/analytics';
import {
  CartCustomAttrs,
  MParticleVideoEvents,
} from 'types/analytics/mParticle';
import { ListItemContext } from 'types/events';
import { createMParticleProduct, getSourceSite } from 'support/mParticleUtils';

export default class MParticleVideo {
  private payload: Record<string, any> = {};
  private hasReportedFinish = false;

  reportStart(currentPosition: number, type?: StartType) {
    let event: MParticleVideoEvents;
    if (type === StartType.AUTO_PLAY) {
      event = MParticleVideoEvents.AUTO_START;
    } else {
      event = MParticleVideoEvents.START;
    }

    this.hasReportedFinish = false;

    this.trackPlaybackEvent(event, currentPosition);
    this.trackProductEvents(mParticle.ProductActionType.AddToCart);
  }

  reportPause(currentPosition: number) {
    this.trackPlaybackEvent(MParticleVideoEvents.PAUSE, currentPosition);
  }

  reportResume(currentPosition: number) {
    this.trackPlaybackEvent(MParticleVideoEvents.RESUME, currentPosition);
  }

  reportSeek(currentPosition: number, type?: SeekType) {
    let event: MParticleVideoEvents;
    if (type === SeekType.START_OVER) {
      event = MParticleVideoEvents.START_OVER;
    } else if (type === SeekType.STEP_FORWARD) {
      event = MParticleVideoEvents.ACTION_STEP_FORWARD;
    } else if (type === SeekType.STEP_BACKWARD) {
      event = MParticleVideoEvents.ACTION_STEP_BACKWARD;
    } else {
      event = MParticleVideoEvents.ACTION_SEEK;
    }

    this.trackPlaybackEvent(event, currentPosition);
  }

  reportMidPoint(currentPosition: number) {
    this.trackPlaybackEvent(MParticleVideoEvents.MIDPOINT, currentPosition);
  }

  reportCreditsReached(currentPosition: number) {
    this.reportFinish(currentPosition, true);
  }

  reportEnd(currentPosition: number, hasCompleted: boolean) {
    this.reportFinish(currentPosition, hasCompleted);
  }

  reportCloseCaptions(language: string) {
    const { Episode_Name, Source_Page, Series_Name, Source_Site } =
      this.payload;

    const payload = {
      Language: language,
      Source_Page,
      Series_Name,
      Episode_Name,
      Source_Site,
    };

    mParticle.logEvent(
      MParticleVideoEvents.TOGGLE_CLOSED_CAPTIONS,
      mParticle.EventType.Other,
      payload,
    );
  }

  reportAdStart(currentPosition: number) {
    this.trackPlaybackEvent(MParticleVideoEvents.AD_START, currentPosition);
  }

  reportAdEnd(currentPosition: number) {
    this.trackPlaybackEvent(MParticleVideoEvents.AD_COMPLETE, currentPosition);
  }

  reportPlaybackError(errorType: ErrorType, message: string) {
    const payload = {
      Error_Type: errorType,
      Error_Message: message,
      Content_ID: this.payload.ContentId ?? '',
      Source_Page: this.payload.SourceShowPage ?? '',
    };

    mParticle.logEvent(
      MParticleVideoEvents.ERROR,
      mParticle.EventType.Other,
      payload,
    );
  }

  setContentPayload(
    content: Video | LiveEventChannel | EpgChannel,
    fromPage: PageId | null,
    listItemContext?: ListItemContext,
  ) {
    const payload: Record<string, any> = this.createPayload(content);

    if (fromPage) {
      payload['Source_Page'] = fromPage;
    }

    if (listItemContext) {
      listItemContext.itemIndex += 1;
      listItemContext.rowIndex += 1;
      payload.listItemContext = listItemContext;
    }

    this.payload = payload;
  }

  reportPlayRecommendation(type: 'clicked' | 'autoPlay', content: Media) {
    const payload = this.createPayload(content);

    mParticle.logEvent(
      type === 'clicked'
        ? MParticleVideoEvents.RECOMMENDATION_CLICKED
        : MParticleVideoEvents.RECOMMENDATION_AUTO_PLAY,
      mParticle.EventType.Other,
      payload,
    );
  }

  private reportFinish(currentPosition: number, hasCompleted: boolean) {
    if (this.hasReportedFinish) return;

    this.trackPlaybackEvent(MParticleVideoEvents.FINISH, currentPosition);
    if (hasCompleted) {
      this.trackProductEvents(mParticle.ProductActionType.Purchase);
    } else {
      this.trackProductEvents(mParticle.ProductActionType.RemoveFromCart);
    }

    this.hasReportedFinish = true;
  }

  private createPayload(content: Media): Record<string, any> {
    let payload: Record<string, any>;

    if (isLiveProgram(content)) {
      payload = this.parseEpgProgramContent(content as EpgChannel);
    } else if (isLiveEvent(content)) {
      payload = this.parseLiveContent(content as LiveEventChannel);
    } else {
      payload = this.parseVideoContent(content as Video);
    }

    return { ...payload, Source_Site: getSourceSite() };
  }

  private parseVideoContent(content: Video): Record<string, any> {
    return {
      Air_Date: content.airDate,
      Content_ID: content.guid,
      Episode_Name: content.title,
      Episode_Number: content.episode,
      Genre: content.imdbGenre,
      Length: content.durationSecs,
      Nielsen_Player: 'False',
      Series_Name: content.seriesName,
      Season_Number: getSeason(content),
      Type: isFullContent(content) ? 'Full' : 'Clip',
      Source_Site: getSourceSite(),
      Source_Show_Page: content.showSlug,
    };
  }

  private parseLiveContent(content: LiveEventChannel): Record<string, any> {
    return {
      Air_Date: content.startDateStream,
      Content_ID: content.analytics.guid,
      Episode_Name: content.title,
      Episode_Number: '',
      Genre: content.analytics.comscoreGenre ?? '',
      Length: content?.durationSecs ?? '',
      Nielsen_Player: 'False',
      Series_Name: content.analytics.title,
      Season_Number: '',
      Type: 'Live',
      Source_Site: getSourceSite(),
      Source_Show_Page: content.slug,
    };
  }

  private parseEpgProgramContent(channel: EpgChannel): Record<string, any> {
    const epgProgram = getCurrentEpgProgram(channel);

    return {
      Air_Date: epgProgram?.startTime ?? '',
      Content_ID: epgProgram?.assetId,
      Episode_Name: epgProgram?.title ?? '',
      Episode_Number: '',
      Length: epgProgram?.durationSecs ?? '',
      Nielsen_Player: 'False',
      Series_Name: epgProgram?.title ?? '',
      Type: 'Live',
      Source_Site: getSourceSite(),
      Source_Show_Page: epgProgram?.seriesSlug ?? '',
    };
  }

  private trackPlaybackEvent(event: string, currentPosition?: number) {
    let modifiedPayload = { ...this.payload };

    if (currentPosition !== undefined) {
      const positionInSecs = Math.round(currentPosition / 1000);

      let duration = 0;
      if (VideoPlayer?.duration && VideoPlayer?.duration !== Infinity) {
        duration = Math.round(VideoPlayer.duration);
      }

      if (duration > 0) {
        const elapsed = Math.min(positionInSecs, duration);
        const remaining = Math.max(duration - positionInSecs, 0);
        modifiedPayload = {
          ...modifiedPayload,
          Time_Elapsed: elapsed,
          Time_Remaining: remaining,
        };
      } else {
        modifiedPayload = this.payload;
      }
    }

    mParticle.logEvent(event, mParticle.EventType.Other, modifiedPayload);
  }

  private trackProductEvents(event: mParticle.ProductActionType) {
    if (!this.payload.listItemContext) return;

    const { Episode_Name, Content_ID, Source_Page, Series_Name, Source_Site } =
      this.payload;
    const { rowIndex, itemIndex, imageUrl, rowTitle } =
      this.payload.listItemContext;

    const product = createMParticleProduct({
      item_id: Content_ID,
      item_name: Episode_Name,
      item_brand: Series_Name,
      item_list_name: Source_Page,
      item_category: itemIndex,
      item_variant: imageUrl,
    });

    const customAttributes: CartCustomAttrs = {
      image_file_title: imageUrl,
      Series_Name: Series_Name,
      row_name: rowTitle,
      row_position: rowIndex,
      creative_position: itemIndex,
      Source_Site: Source_Site,
    };

    mParticle.eCommerce.logProductAction(event, product, customAttributes);
  }
}
