import { Registry } from '@lightningjs/sdk';
import { ListWithSpeech } from 'components/common/CollectionWrappersWithSpeech';

export class ExpandableList extends ListWithSpeech {
  private _isHovered = false;

  override _focus(newFocusedComponent: any, prevFocusedComponent: any) {
    super._focus(newFocusedComponent, prevFocusedComponent);

    this.signal('onListFocus', this.index);
  }

  set isHovered(flag: boolean) {
    this._isHovered = flag;
  }

  get isHovered() {
    return this._isHovered;
  }
}
