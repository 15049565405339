import CwApiClient, { Endpoints, HttpOptions } from 'services/CwApiClient';
import { AxiosResponse, Method } from 'axios';
import CwCache, { CacheVersions } from 'services/CwCache';
import { Config } from 'types/api/config';
import {
  CwResponse,
  parseSwimlanes,
  parseShow,
  parseShowVideos,
  parseVideo,
  parseVideos,
  CwErrors,
  parseLive,
  parseEpgChannels,
  Swimlanes,
  Lane,
  parseNavigationItems,
  parseLiveEvent,
  parseLiveEvents,
} from 'services/cwData';
import ApiServiceError from 'support/ApiServiceError';
import { EpgChannel, Season, Video } from 'types/api/media';
import { ErrorType } from 'types/analytics';
import { AppData } from '@lightningjs/sdk';
import NavBar from 'components/widgets/NavBar';
import AppLaunch from 'components/widgets/AppLaunch';
import { constants } from 'aliases';
import { ContentHubSlugs } from 'types/contentHubs';
import { getTivoApiScreens } from 'services/tivoService';

type RequestProps<TResult> = {
  method: Method;
  cacheKey: keyof CacheVersions;
  endpoint: string;
  preventCaching?: boolean;
  parser?: (response: any) => TResult;
  options?: HttpOptions;
};

type ResponseProps<TResult, TAxiosResponse> = {
  endpoint: string;
  timeout: number;
  response: TAxiosResponse;
  preventCaching?: boolean;
  parser?: (response: any) => TResult;
};

export const MAX_GUID_COUNT = 32;

const client = new CwApiClient();
const cache = new CwCache(client);

const sendRequest = async <TResult>({
  method,
  cacheKey,
  endpoint,
  preventCaching = false,
  parser,
  options,
}: RequestProps<TResult>): Promise<TResult> => {
  const cachedResponse = cache.getCachedResponse<TResult>(endpoint);
  if (cachedResponse) return cachedResponse;

  // Update timeouts if they haven't already been initialized
  if (
    !cache.areTimeoutsInitialized() &&
    !preventCaching &&
    cacheKey !== 'config'
  ) {
    await initializeCacheTimeouts();
  }
  const timeout = cache.getTimeout(cacheKey);

  const cacheVersion = await cache.getCacheVersion(cacheKey);

  const path = `${endpoint}cacheversion_${cacheVersion}/`;
  const response = await client.request<CwResponse>(method, path, options);

  return handleResponse<TResult, typeof response>({
    endpoint,
    timeout,
    response,
    preventCaching,
    parser,
  });
};

const handleResponse = <
  TResult,
  TAxiosResponse extends AxiosResponse<CwResponse>,
>({
  endpoint,
  timeout,
  response,
  preventCaching,
  parser,
}: ResponseProps<TResult, TAxiosResponse>): TResult => {
  if (response.data.result !== 'ok') {
    throw new ApiServiceError(
      response.data.msg === CwErrors.NOT_FOUND
        ? ErrorType.NOT_FOUND
        : ErrorType.MISSING_CONTENT,
    );
  }

  let result: TResult;
  if (parser) {
    try {
      // Attempt to parse the data received
      result = parser(response.data);
    } catch (e) {
      throw new ApiServiceError(ErrorType.MISSING_CONTENT);
    }
  } else {
    result = response.data as TResult;
  }

  if (!preventCaching) {
    cache.cacheResponse<TResult>(endpoint, result, timeout);
  }
  return result;
};

const createEndpoint = <T extends Record<string, string>>(
  endpoint: Endpoints,
  params: T = {} as T,
): string => {
  const { apiversion, channel } = constants.api;
  const device = AppData?.api.device ?? '';

  params = {
    ...params,
    apiversion,
    channel,
    device,
  };

  const paramsStr = Object.keys(params).reduce((prevStr, key) => {
    const value = params[key];
    return prevStr + `${key}_${value}/`;
  }, '');

  return `${endpoint}${paramsStr}`;
};

export const initializeCacheTimeouts = async () => {
  // Don't update timeouts if they have already been initialized
  if (cache.areTimeoutsInitialized()) return;

  const config = await getConfig();
  cache.updateTimeouts(config);
};

export const getConfig = async () => {
  const endpoint = createEndpoint(Endpoints.CONFIG);
  const config = await sendRequest<Config>({
    method: 'GET',
    cacheKey: 'config',
    endpoint,
  });

  // Update any properties that rely on the new config
  AppData!.privacyModifiedDate = config.privacy['modified_date'];
  AppData!.tivoEndpoints = config.endpoints;
  AppData!.google.contentSourceId = config['ad-params'].global.gam_cms_id;
  AppData!.ayswIdleTimes = config.ux.aysw_minutes;
  AppData!.adParams = config['ad-params'];
  AppData!.privacy = config.privacy;

  AppData!.navigationItems = parseNavigationItems(config);
  NavBar.updateTabs();

  AppLaunch.termsUrl = config.privacy.terms_of_use_url;
  AppLaunch.promptData = config.privacy.launch_prompt;

  return config;
};

export const getHomePageSwimlanes = async () => {
  const endpoint = createEndpoint(Endpoints.SWIMLANES, {
    type: ContentHubSlugs.HOME,
  });
  return await getSwimlanes(endpoint);
};

export const getContentHub = async (hubSlug: string) => {
  const endpoint = createEndpoint(Endpoints.SWIMLANES, {
    type: hubSlug,
  });
  const swimlanes = await getSwimlanes(endpoint);
  if (!swimlanes) return null;
  return await updateTivoLanes(swimlanes);
};

// update swimlanes with the multiple tivo api screens
const updateTivoLanes = async (swimlanes: Swimlanes): Promise<Swimlanes> => {
  const lanes = swimlanes.lanes as Lane[];
  const tivoLaneIndexes: number[] = [];
  // find all tivo lanes and store their indexes
  lanes.forEach((lane, index) => {
    if (lane.laneType === 'tivo-api-screens') {
      tivoLaneIndexes.push(index);
    }
  });
  if (tivoLaneIndexes.length < 1) return swimlanes;

  const tivoScreensPromise = tivoLaneIndexes.map(index =>
    getTivoApiScreens(lanes[index]?.apiUrl),
  );
  const tivoApiScreensArray = await Promise.allSettled(tivoScreensPromise);

  for (let i = 0; i < tivoLaneIndexes.length; i++) {
    const index = tivoLaneIndexes[i]!;
    const tivoLane = lanes[index]!;
    const tivoApiScreens = tivoApiScreensArray[i];
    const screens =
      tivoApiScreens?.status === 'fulfilled' &&
      tivoApiScreens.value.map((tivoApiScreen: any) => {
        tivoApiScreen.presentation = tivoLane.presentation;
        tivoApiScreen.slug = tivoLane.slug;
        return tivoApiScreen;
      });
    if (screens?.length > 0) {
      lanes.splice(index, 1, ...screens);
    } else {
      lanes.splice(index, 1);
    }
  }

  swimlanes.lanesCount = swimlanes.lanes.length;
  return swimlanes;
};

const getSwimlanes = async (endpoint: string) => {
  return await sendRequest({
    method: 'GET',
    cacheKey: 'swimlanes',
    endpoint,
    parser: parseSwimlanes,
  });
};

export const getShowByShowSlug = async (slug: string) => {
  const endpoint = createEndpoint(Endpoints.SHOWS, { show: slug });
  const showResponse = await sendRequest({
    method: 'GET',
    cacheKey: 'shows',
    endpoint,
    parser: parseShow,
  });

  if (showResponse?.showSlug !== slug) {
    throw new ApiServiceError(ErrorType.MISSING_CONTENT);
  }

  return showResponse;
};

export const getEpisodeListByShowSlug = async (
  slug: string,
  season: Season,
) => {
  const endpoint = createEndpoint(Endpoints.VIDEOS, {
    show: slug,
    type: 'episodes',
    season: season.code,
  });

  const showVideos = await sendRequest({
    method: 'GET',
    cacheKey: 'videos',
    endpoint,
    parser: parseShowVideos,
  });
  showVideos.season = season;
  return showVideos;
};

export const getExtrasListByShowSlug = async (slug: string) => {
  const endpoint = createEndpoint(Endpoints.VIDEOS, {
    show: slug,
    type: 'extras',
  });
  return await sendRequest({
    method: 'GET',
    cacheKey: 'videos',
    endpoint,
    parser: parseShowVideos,
  });
};

export const getVideo = async (guid: string) => {
  const endpoint = createEndpoint(Endpoints.VIDEO_METADATA, { guid });
  return await sendRequest({
    method: 'GET',
    cacheKey: 'videos',
    endpoint,
    parser: parseVideo,
  });
};

/**
 * Requests video data for multiple GUIDs at once
 *
 * @param guids List of GUIDs. If more than 32 are provided, it will batch request.
 * @returns The video data for every GUID provided
 */
export const getVideos = async (guids: string[]) => {
  const videos: Array<Video> = [];
  const requests: Promise<Video[]>[] = [];

  // Loop to create chunks of media identifiers that are MAX_GUID_COUNT sized.
  // This ensures we don't exceed the GUID limit for videos
  for (let left = 0; left < guids.length; left += MAX_GUID_COUNT) {
    const right = Math.min(left + MAX_GUID_COUNT, guids.length);
    const chunk = guids.slice(left, right);

    // create string of up to 32 video GUIDs split with a comma
    const batch = chunk.reduce((prevStr, value) => {
      return prevStr ? `${prevStr},${value}` : value;
    }, '');

    const endpoint = createEndpoint(Endpoints.VIDEOS_METADATA, { guid: batch });
    requests.push(
      sendRequest({
        method: 'GET',
        cacheKey: 'videos',
        endpoint,
        preventCaching: true, // GUIDs are fairly unique so we prevent caching
        parser: parseVideos,
      }),
    );
  }

  (await Promise.allSettled(requests)).forEach(result => {
    if (result.status === 'fulfilled')
      result.value.forEach(video => videos.push(video));
  });

  return videos;
};

export const getLiveEvent = async (slug: string) => {
  const endpoint = createEndpoint(Endpoints.LIVE_EVENT, { event: slug });
  return await sendRequest({
    method: 'GET',
    cacheKey: 'shows',
    endpoint,
    parser: parseLiveEvent,
  });
};

export const getAllLiveEvents = async () => {
  const endpoint = createEndpoint(Endpoints.LIVE_EVENT, {
    event: 'list-all-events-details-page',
  });
  return await sendRequest({
    method: 'GET',
    cacheKey: 'shows',
    endpoint,
    parser: parseLiveEvents,
  });
};

export const getLiveStream = async (slug?: string) => {
  const config = await getConfig();
  const liveResponse = parseLive(config['live-stream']);

  if (!liveResponse || !liveResponse.id) {
    throw new ApiServiceError(ErrorType.MISSING_CONTENT);
  } else if (slug && liveResponse.id !== slug) {
    throw new ApiServiceError(ErrorType.MISSING_CONTENT);
  } else {
    return liveResponse;
  }
};

export const getEpgPage = async (pageNum: number, pageSize: number) => {
  const endpoint = createEndpoint(Endpoints.EPG, {
    page: pageNum.toString(),
    pagesize: pageSize.toString(),
  });
  return await sendRequest<{ channels: EpgChannel[]; maxChannels: number }>({
    method: 'GET',
    cacheKey: 'epg',
    endpoint,
    preventCaching: true,
    parser: parseEpgChannels,
  });
};

export const getEpgChannelBySlug = async (slug: string) => {
  const endpoint = createEndpoint(Endpoints.EPG, { epgchannel: slug });
  return await sendRequest({
    method: 'GET',
    cacheKey: 'epg',
    endpoint,
    parser: parseEpgChannels,
  });
};

export const getSettingsText = async (
  textFor: 'TERMS_OF_USE' | 'PRIVACY_POLICY' | 'NIELSEN_MEASUREMENT',
) => {
  const endpoint = createEndpoint(Endpoints[textFor]);

  const parser = (data: any): string => data.content;
  return await sendRequest({
    method: 'GET',
    cacheKey: 'config',
    endpoint,
    preventCaching: true,
    parser,
  });
};

export const useCacheBackup = async () => {
  await cache.useCacheBackup();
};
