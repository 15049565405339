import { Privacy } from 'services/PrivacyService';
import { History } from 'services/history/AbstractHistoryService';
import { RecentSearches } from 'services/RecentSearchService';
import { AbstractDeviceIntegration } from 'config/platforms/AbstractDeviceIntegration';
import { ParentalPinData } from 'services/ParentalPinService';

// known keys
const APP_VERSION_KEY = 'appVersion';
const CACHE_KEY = 'CWCache';
const HISTORY_KEY = 'history';
const RECENT_SEARCHES_KEY = 'recentSearches';
const MPID_KEY = 'mpid';
const PRIVACY_KEY = 'privacy';
const AUTOPLAY_KEY = 'autoplay';
const SUBTITLE_KEY = 'subtitle';
const TERMS_ACCEPTED_KEY = 'terms-accepted';
const AUDIO_KEY = 'audio';
const SHARE_HISTORY_WITH_TIZEN_KEY = 'share-history-with-tizen';
const PARENTAL_PIN_KEY = 'parental-pin';

// when key is known
interface StorageMethodStrict<T> {
  get: () => T | null;
  set: (value: T) => void;
  remove: () => void;
}

// when storage key has specific storage space. Ex. Conviva.sdkConfig
interface StorageMethodWithStorageSpace<T> {
  get: (storageSpace: string, key: string) => T | null;
  set: (storageSpace: string, key: string, value: T) => void;
  remove: (storageSpace: string, key: string) => void;
}

type LazyGetFunction<T> = () => (key: string) => T | null | undefined;
type LazySetFunction<T> = () => (key: string, value: T | null) => void;
type LazyRemoveFunction = () => (key: string) => void;

export default class StorageService {
  private readonly deviceIntegration: AbstractDeviceIntegration;

  private readonly lazyGetLocal = () => this.deviceIntegration.getLocalStorage;
  private readonly lazySetLocal = () => this.deviceIntegration.setLocalStorage;
  private readonly lazyRemoveLocal = () =>
    this.deviceIntegration.removeLocalStorage;

  private readonly lazyGetSession = () =>
    this.deviceIntegration.getSessionStorage;
  private readonly lazySetSession = () =>
    this.deviceIntegration.setSessionStorage;
  private readonly lazyRemoveSession = () =>
    this.deviceIntegration.removeSessionStorage;

  constructor(deviceIntegration: AbstractDeviceIntegration) {
    this.deviceIntegration = deviceIntegration;
  }

  appVersion: StorageMethodStrict<string> =
    this.createLocalStorageMethodStrict<string>(APP_VERSION_KEY);

  mpid: StorageMethodStrict<string> =
    this.createSessionStorageMethodStrict<string>(MPID_KEY);

  privacy: StorageMethodStrict<Privacy> =
    this.createLocalStorageMethodStrict<Privacy>(PRIVACY_KEY);

  cache: StorageMethodStrict<any> =
    this.createSessionStorageMethodStrict<any>(CACHE_KEY);

  history: StorageMethodStrict<History> =
    this.createLocalStorageMethodStrict<History>(HISTORY_KEY);

  recentSearches: StorageMethodStrict<RecentSearches> =
    this.createLocalStorageMethodStrict<RecentSearches>(RECENT_SEARCHES_KEY);

  conviva: StorageMethodWithStorageSpace<string> =
    this.createLocalStorageMethodWithStorageSpace<string>();

  autoplaySettings: StorageMethodStrict<boolean> =
    this.createLocalStorageMethodStrict<boolean>(AUTOPLAY_KEY);

  subtitle: StorageMethodStrict<string> =
    this.createLocalStorageMethodStrict<string>(SUBTITLE_KEY);

  termsAcceptedTimestamp: StorageMethodStrict<number> =
    this.createLocalStorageMethodStrict(TERMS_ACCEPTED_KEY);

  audio: StorageMethodStrict<string> =
    this.createLocalStorageMethodStrict<string>(AUDIO_KEY);

  shareHistoryWithTizen: StorageMethodStrict<boolean> =
    this.createLocalStorageMethodStrict<boolean>(SHARE_HISTORY_WITH_TIZEN_KEY);

  parentalPin: StorageMethodStrict<ParentalPinData> =
    this.createLocalStorageMethodStrict<ParentalPinData>(PARENTAL_PIN_KEY);

  clearSession() {
    // Explicitly clear session storage since it uses local storage when session
    // storage is unavailable
    this.mpid.remove();
    this.cache.remove();
  }

  // Storage for values with Strict value type
  private createGenericStorageMethodStrict<T>(
    key: string,
    getMethod: LazyGetFunction<T>,
    setMethod: LazySetFunction<T>,
    removeMethod: LazyRemoveFunction,
  ): StorageMethodStrict<T> {
    return {
      get: (): T | null => {
        return getMethod()(key) ?? null;
      },
      set: (value: T) => {
        setMethod()(key, value);
      },
      remove: () => {
        removeMethod()(key);
      },
    };
  }

  private createLocalStorageMethodStrict<T>(
    key: string,
  ): StorageMethodStrict<T> {
    return this.createGenericStorageMethodStrict<T>(
      key,
      this.lazyGetLocal,
      this.lazySetLocal,
      this.lazyRemoveLocal,
    );
  }

  // Storage for values with Strict value type
  private createSessionStorageMethodStrict<T>(
    key: string,
  ): StorageMethodStrict<T> {
    return this.createGenericStorageMethodStrict<T>(
      key,
      this.lazyGetSession,
      this.lazySetSession,
      this.lazyRemoveSession,
    );
  }

  // Storage for values with Strict value type Storage Space version. Ex. key: Conviva.sdkConfig
  private createGenericStorageMethodWithStorageSpace<T>(
    getMethod: LazyGetFunction<T>,
    setMethod: LazySetFunction<T>,
    removeMethod: LazyRemoveFunction,
  ): StorageMethodWithStorageSpace<T> {
    return {
      get: (storageSpace: string, key: string): T | null => {
        return getMethod()(storageSpace + '.' + key) ?? null;
      },
      set: (storageSpace: string, key: string, value: T) => {
        setMethod()(storageSpace + '.' + key, value);
      },
      remove: (storageSpace: string, key: string) => {
        removeMethod()(storageSpace + '.' + key);
      },
    };
  }

  private createLocalStorageMethodWithStorageSpace<
    T,
  >(): StorageMethodWithStorageSpace<T> {
    return this.createGenericStorageMethodWithStorageSpace<T>(
      this.lazyGetLocal,
      this.lazySetLocal,
      this.lazyRemoveLocal,
    );
  }

  private createSessionStorageMethodWithStorageSpace<
    T,
  >(): StorageMethodWithStorageSpace<T> {
    return this.createGenericStorageMethodWithStorageSpace<T>(
      this.lazyGetSession,
      this.lazySetSession,
      this.lazyRemoveSession,
    );
  }
}
