import { Colors, Lightning } from '@lightningjs/sdk';
import Tile, { TileTemplateSpec } from 'components/common/tiles/Tile';
import { Channel } from 'types/api/media';

export interface ChannelTileTemplateSpec extends TileTemplateSpec {
  data: Channel;

  Title: Lightning.Component;
}

const TILE_WIDTH = 300;
const TILE_HEIGHT = 342;

const TILE_HIGHLIGHT_WIDTH = 300;
const TILE_HIGHLIGHT_HEIGHT = 300;

const TITLE_Y = 319;
const TITLE_FONT_SIZE = 23;

export default class ChannelTile
  extends Tile<ChannelTileTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<ChannelTileTemplateSpec>
{
  protected _Title = this.getByRef('Title')!;

  override set data(data: Channel) {
    super.data = data;

    const title = data.title;
    this._Title.patch({ text: { text: title } });
  }

  static override get width() {
    return TILE_WIDTH;
  }

  static override get height() {
    return TILE_HEIGHT;
  }

  static override get highlightWidth() {
    return TILE_HIGHLIGHT_WIDTH;
  }

  static override get highlightHeight() {
    return TILE_HIGHLIGHT_HEIGHT;
  }

  static override _template() {
    return {
      ...super._template(),
      Title: {
        y: TITLE_Y,
        w: TILE_WIDTH,
        text: {
          fontSize: TITLE_FONT_SIZE,
          textAlign: 'center',
          maxLines: 1,
          textColor: Colors('text').get(),
        },
      },
    };
  }
}
