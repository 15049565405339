import { AppData } from '@lightningjs/sdk';
import mParticle from '@mparticle/web-sdk';

import '@mparticle/web-google-analytics-4-client-kit';
import {
  Channel,
  Episode,
  Live,
  LiveEventChannel,
  Media,
  Show,
  Video,
} from 'types/api/media';
import {
  isChannel,
  isContentHub,
  isEpisode,
  isLiveContent,
  isPromoChannel,
  isShow,
  isVideo,
} from 'support/contentUtils';
import {
  CategoryEngagementPayload,
  HeaderNavigationPayload,
  HeroEngagementPayload,
  MParticleEvents,
  RowEngagementPayload,
  ScrollTrackingPayload,
  PromotionCustomAttrs,
  ShowPageEngagement,
  ItemCustomAttrs,
} from 'types/analytics/mParticle';
import MParticleVideo from 'services/analytics/MParticleVideo';
import { ContentHub, Promo } from 'services/cwData';
import {
  createMParticleProduct,
  createMParticlePromotion,
  getSourceSite,
} from 'support/mParticleUtils';
import { metadata as appMetadata } from 'aliases';
import StorageService from 'services/StorageService';
import { AbstractDeviceIntegration } from 'config/platforms/AbstractDeviceIntegration';
import { PrivacyConsent } from 'services/PrivacyService';

export type ViewItemProps = {
  crossIndex: number | undefined;
  imageUrl: string | undefined;
  mainIndex: number | undefined;
  // @ts-ignore
  data: Media | ContentHub | undefined;
  rowTitle: string | undefined;
  isTivoTileSource: boolean;
};
export type SelectItemProps = ViewItemProps;

export type SelectPromoProps = {
  promo: Promo | Live | undefined;
  imageFileTitle: string | undefined;
  index: number | undefined;
};

export type CategoryEngagementProps = {
  category: string;
  tabIndex: number;
  tabName: string;
};

type ConstructorArgs = {
  storageService: StorageService;
  deviceIntegration: AbstractDeviceIntegration;
};

export default class MParticleAnalytics {
  private readonly storageService: StorageService;
  private readonly deviceIntegration: AbstractDeviceIntegration;

  video: MParticleVideo;
  private consentStateOn: PrivacyConsent | null = null;

  constructor({ storageService, deviceIntegration }: ConstructorArgs) {
    if (AppData === undefined) {
      throw new Error('AppData not set');
    }

    this.storageService = storageService;
    this.deviceIntegration = deviceIntegration;

    const { isProduction } = AppData;
    const { dataPlan, key } = AppData.mParticle;

    const mParticleConfig: mParticle.MPConfiguration = {
      appName: appMetadata.title,
      appVersion: appMetadata.version,
      isDevelopmentMode: !isProduction,
      dataPlan: { planId: dataPlan.id },
      identityCallback: this.storeUserIdentity.bind(this),
    };

    if (!isProduction) {
      mParticleConfig.logLevel = 'verbose';
    }

    mParticle.init(key, mParticleConfig);

    this.video = new MParticleVideo();
  }

  private storeUserIdentity(data: mParticle.IdentityResult) {
    this.storageService.mpid.set(data.getUser().getMPID());

    // Update privacy status if consent state was set before user identity
    if (this.consentStateOn !== null)
      this.updatePrivacyStatus(this.consentStateOn);
  }

  getMpid() {
    return this.storageService.mpid.get();
  }

  // Must be called before navigation
  reportNavBarNavigation(title: string) {
    const payload: HeaderNavigationPayload = {
      category: title,
    };

    this.logEvent(
      MParticleEvents.HEADER_NAVIGATION,
      mParticle.EventType.Navigation,
      payload,
    );
  }

  reportShowPageEngagement(
    mediaItem: Video | LiveEventChannel,
    season: string,
    imageSrc: string,
    episodeIndex: number,
    seasonIndex: number,
  ) {
    const seriesName = this.getName(mediaItem);
    const title = mediaItem.title;

    const payload: ShowPageEngagement = {
      Series_Name: seriesName ?? '',
      Season_Name: season ?? '',
      Episode_Name: title ?? '',
      row_position: `${seasonIndex + 1}`,
      row_name: season ?? '',
      image_file_title: imageSrc ?? '',
      creative_position: `${episodeIndex + 1}`,
    };

    this.logEvent(
      MParticleEvents.SHOW_LIST_ITEM_CLICKED,
      mParticle.EventType.Other,
      payload,
    );
  }

  reportScrollTracking(quartile: number) {
    const payload: ScrollTrackingPayload = {
      scroll_depth: quartile.toString() + '%',
    };

    this.logEvent(
      MParticleEvents.SCROLL_TRACKING,
      mParticle.EventType.Other,
      payload,
    );
  }

  reportViewPromotion(props: { promo: Promo; index: number }) {
    const { promo, index } = props;
    const seriesName = this.getName(promo);

    const promotion = createMParticlePromotion({
      id: promo.id,
      creative: `${index + 1}`,
      name: seriesName,
    });

    const customAttributes: Partial<PromotionCustomAttrs> = {
      Series_Name: seriesName,
      image_file_title: promo.promoImageId,
      creative_position: `${index + 1}`,
    };

    this.logPromotionEvent(
      mParticle.PromotionType.PromotionView,
      promotion,
      customAttributes,
    );
  }

  reportPromoSelected(props: SelectPromoProps) {
    this.reportSelectPromotion(props);
    this.reportHeroEngagement(props);
  }

  private reportSelectPromotion(props: SelectPromoProps) {
    const { imageFileTitle, index, promo } = props;
    if (
      imageFileTitle === undefined ||
      index === undefined ||
      promo === undefined
    )
      return;

    const seriesName = this.getName(promo);

    const promotion = createMParticlePromotion({
      id: promo.id,
      creative: `${index + 1}`,
      name: seriesName,
    });

    const customAttributes: Partial<PromotionCustomAttrs> = {
      Series_Name: seriesName,
      image_file_title: imageFileTitle,
      creative_position: `${index + 1}`,
    };

    this.logPromotionEvent(
      mParticle.PromotionType.PromotionClick,
      promotion,
      customAttributes,
    );
  }

  private reportHeroEngagement(props: SelectPromoProps) {
    const { imageFileTitle, index, promo } = props;
    if (
      imageFileTitle === undefined ||
      index === undefined ||
      promo === undefined
    )
      return;

    const name = this.getName(promo);

    const payload: HeroEngagementPayload = {
      banner_type: 'hero',
      image_file_title: imageFileTitle,
      Series_Name: name,
      creative_position: `${index + 1}`,
    };

    this.logEvent(
      MParticleEvents.HERO_ENGAGEMENT,
      mParticle.EventType.Other,
      payload,
    );
  }

  reportItemSelected(props: SelectItemProps) {
    this.reportSelectItem(props);
    this.reportRowEngagement(props);
  }

  private reportSelectItem(args: SelectItemProps) {
    const { data } = args;
    if (!data) return null;

    const props = isContentHub(data)
      ? this.getContentHubProductProps(args)
      : this.getMediaProductProps(args);
    if (!props) return;

    const customAttributes: ItemCustomAttrs = props.customAttributes;
    const product = createMParticleProduct(props.productAttributes);

    this.logProductEvent(
      mParticle.ProductActionType.Click,
      product,
      customAttributes,
    );
  }

  private reportRowEngagement(props: SelectItemProps) {
    const {
      data,
      mainIndex,
      crossIndex,
      imageUrl,
      rowTitle,
      isTivoTileSource,
    } = props;

    if (
      data === undefined ||
      mainIndex === undefined ||
      crossIndex === undefined ||
      rowTitle === undefined
    ) {
      return;
    }

    const name = this.getName(data);

    const payload: RowEngagementPayload = {
      banner_type: 'row',
      image_file_title: imageUrl ?? '',
      row_name: rowTitle,
      Series_Name: name,
      row_position: `${mainIndex + 1}`,
      creative_position: `${crossIndex + 1}`,
      Tile_Source: isTivoTileSource ? 'Tivo Screens' : 'CMS',
    };

    this.logEvent(
      MParticleEvents.ROW_ENGAGEMENT,
      mParticle.EventType.Other,
      payload,
    );
  }

  reportCategoryEngagement(props: CategoryEngagementProps) {
    const { category, tabIndex, tabName } = props;
    if (
      category === undefined ||
      tabIndex === undefined ||
      tabName === undefined
    ) {
      return;
    }

    const payload: CategoryEngagementPayload = {
      category: category,
      row_name: tabName,
      row_position: `${tabIndex + 1}`,
    };

    this.logEvent(
      MParticleEvents.CATEGORY_ENGAGEMENT,
      mParticle.EventType.Other,
      payload,
    );
  }

  reportViewItemList(products: ViewItemProps[]) {
    const eCommProducts: Array<mParticle.Product | null> = products.map(
      args => {
        const { data } = args;
        if (!data) return null;

        const props = isContentHub(data)
          ? this.getContentHubProductProps(args)
          : this.getMediaProductProps(args);
        if (!props) return null;

        const customAttributes: ItemCustomAttrs = props.customAttributes;

        return createMParticleProduct({
          ...props.productAttributes,
          customProductAttributes: customAttributes,
        });
      },
    );

    const filteredProducts = eCommProducts.filter(
      p => p !== null,
    ) as mParticle.Product[];

    if (filteredProducts.length === 0) return;

    this.logImpressionEvent('Views', filteredProducts);
  }

  private getName(data: Media | Promo | ContentHub) {
    if (isContentHub(data)) {
      return this.getMediaSeriesName(data as Media) || data.title;
    } else if (isPromoChannel(data)) {
      return (data as Promo).title;
    } else {
      return this.getMediaSeriesName(data as Media);
    }
  }

  private getMediaSeriesName(media: Media): string {
    if (isShow(media) || isChannel(media) || isLiveContent(media)) {
      return (media as Show | Channel | Live).title;
    } else if (isVideo(media) || isEpisode(media)) {
      return (media as Video | Episode).seriesName;
    }

    // Default to media title
    return media.title;
  }

  private getMediaProductProps = (props: SelectItemProps | ViewItemProps) => {
    const { crossIndex, imageUrl, mainIndex, rowTitle, isTivoTileSource } =
      props;
    const media = props.data as Media | undefined;
    if (!media || mainIndex === undefined || crossIndex === undefined)
      return null;

    const id = media.id ?? media.guid ?? media.slug ?? '';
    const seriesName = this.getName(media);

    let name = '';
    if (isVideo(media) || isEpisode(media)) {
      name = (media as Video | Episode).title;
    } else {
      name = seriesName;
    }
    name = name ?? '';

    return this.buildProductProps({
      crossIndex,
      imageUrl,
      mainIndex,
      rowTitle,
      id,
      seriesName,
      name,
      isTivoTileSource,
    });
  };

  private getContentHubProductProps = (
    props: SelectItemProps | ViewItemProps,
  ) => {
    const { crossIndex, imageUrl, mainIndex, rowTitle, isTivoTileSource } =
      props;
    const data = props.data as ContentHub | undefined;
    if (!data || mainIndex === undefined || crossIndex === undefined)
      return null;

    const id = data.id ?? '';
    const seriesName = this.getName(data);
    const name = data.title ?? '';

    return this.buildProductProps({
      crossIndex,
      imageUrl,
      mainIndex,
      rowTitle,
      id,
      seriesName,
      name,
      isTivoTileSource,
    });
  };

  private buildProductProps = (props: {
    crossIndex: number;
    imageUrl: string | undefined;
    mainIndex: number;
    rowTitle: string | undefined;
    id: string;
    seriesName: string;
    name: string;
    isTivoTileSource: boolean;
  }) => {
    const {
      crossIndex,
      imageUrl,
      rowTitle,
      mainIndex,
      seriesName,
      isTivoTileSource,
    } = props;
    const customAttributes: ItemCustomAttrs = {
      creative_position: `${crossIndex + 1}`,
      image_file_title: imageUrl ?? '',
      row_name: rowTitle ?? '',
      row_position: `${mainIndex + 1}`,
      Series_Name: seriesName,
      Tile_Source: isTivoTileSource ? 'Tivo Screens' : 'CMS',
    };

    const { id, name } = props;
    return {
      productAttributes: {
        item_id: id,
        item_name: name,
        item_brand: seriesName,
        item_list_name: rowTitle ?? '',
        item_category: mainIndex + 1,
        item_variant: imageUrl ?? '',
      },
      customAttributes,
    };
  };

  updatePrivacyStatus(privacyConsent: PrivacyConsent) {
    this.consentStateOn = privacyConsent;

    const user = mParticle.Identity.getCurrentUser();
    if (!user) return; // User not initialized, can't update yet

    const isConsentOptIn = this.consentStateOn === 'opt-in';

    if (!AppData?.isProduction) {
      console.log(
        '[Privacy][MParticle] updatePrivacyStatus',
        `"consent":${privacyConsent}`,
        `"opt-in":${isConsentOptIn}`,
      );
    }

    if (isConsentOptIn) {
      const deviceId = this.deviceIntegration.getDeviceId();
      // Using 'Performance' as Document value to match Android TV
      const ccpaConsentState = mParticle.Consent.createCCPAConsent(
        isConsentOptIn,
        Date.now(), // Timestamp
        'Performance', // Document
        '', // Location
        `IDFA:${deviceId}`, // Hardware ID
      );

      const consentState =
        user.getConsentState() ?? mParticle.Consent.createConsentState();
      consentState.setCCPAConsentState(ccpaConsentState);
      user.setConsentState(consentState);
    } else {
      const consentState = user.getConsentState();
      if (consentState) {
        consentState.removeCCPAConsentState();
        user.setConsentState(consentState);
      }
    }
  }

  private logEvent(
    eventName: string,
    eventType: mParticle.EventType,
    eventInfo: mParticle.SDKEventAttrs,
  ) {
    const payload = { ...eventInfo, Source_Site: getSourceSite() };

    mParticle.logEvent(eventName, eventType, payload);
  }

  private logProductEvent(
    productActionType: mParticle.ProductActionType,
    product: mParticle.Product | mParticle.Product[],
    attrs?: mParticle.SDKEventAttrs | undefined,
  ) {
    const customAttributes = { ...attrs, Source_Site: getSourceSite() };

    mParticle.eCommerce.logProductAction(
      productActionType,
      product,
      customAttributes,
    );
  }

  private logPromotionEvent(
    promotionType: mParticle.PromotionType,
    promotion: mParticle.Promotion | mParticle.Promotion[],
    attrs?: mParticle.SDKEventAttrs,
  ) {
    const customAttributes = { ...attrs, Source_Site: getSourceSite() };

    mParticle.eCommerce.logPromotion(
      promotionType,
      promotion,
      customAttributes,
    );
  }

  private logImpressionEvent(
    name: string,
    product: mParticle.Product | mParticle.Product[],
  ) {
    const customAttributes = { Source_Site: getSourceSite() };

    const impression = mParticle.eCommerce.createImpression(name, product);
    mParticle.eCommerce.logImpression(impression, customAttributes);
  }
}
