import { CommonKeyMapping } from 'models/platforms/commonKeyMappings';

// These keys do not need to be registered - done by default
const MandatoryVizioKeyMapping = {
  37: 'Left',
  38: 'Up',
  39: 'Right',
  40: 'Down',
  13: 'Enter',
  8: 'Back',
  27: 'Exit',
};

export const AdditionalVizioKeyMapping = {
  ...CommonKeyMapping,
} as const;

export const VizioKeyMapping = {
  ...MandatoryVizioKeyMapping,
  ...AdditionalVizioKeyMapping,
};
