import Tile, { TileTemplateSpec } from 'components/common/tiles/Tile';
import { ContentHub } from 'services/cwData';

export interface HorizontalContentHubTileTemplateSpec extends TileTemplateSpec {
  data: ContentHub;
}

const TILE_WIDTH = 400;
const TILE_HEIGHT = 225;

export default class HorizontalContentHubTile extends Tile<HorizontalContentHubTileTemplateSpec> {
  static override get width() {
    return TILE_WIDTH;
  }

  static override get height() {
    return TILE_HEIGHT;
  }

  static override get highlightWidth() {
    return TILE_WIDTH;
  }

  static override get highlightHeight() {
    return TILE_HEIGHT;
  }
}
