import { translate } from 'support/translate';
import ToggleSettingSection, {
  ToggleSettingSectionTemplateSpec,
} from 'components/pages/settings/sections/toggleSection/ToggleSettingSection';
import { AppData, Colors, Lightning } from '@lightningjs/sdk';
import { getFocusDepth } from 'support/generalUtils';
import ParentalControlsChangePin from 'components/pages/settings/sections/toggleSection/ParentalControlsChangePin';
import SetParentalPin from 'components/widgets/parental-pin/SetParentalPin';
import Router from '@lightningjs/sdk/src/Router';
import EnterParentalPin from 'components/widgets/parental-pin/EnterParentalPin';

interface ParentalControlsSectionTemplateSpec
  extends ToggleSettingSectionTemplateSpec {
  ChangePinOption: typeof ParentalControlsChangePin;
}

export default class ParentalControlsSection extends ToggleSettingSection<ParentalControlsSectionTemplateSpec> {
  protected override _getToggleState = this.isParentalControlActive.bind(this);

  private _ChangePinOption = this.getByRef('ChangePinOption')!;

  override get title() {
    if (this._getState() === 'Toggle') {
      return super.title;
    } else if (this._getState() === 'ChangePin') {
      return this._ChangePinOption.title;
    }

    return [];
  }

  static override _template(): Lightning.Component.Template<ParentalControlsSectionTemplateSpec> {
    return {
      ...super._template(),
      ChangePinOption: {
        type: ParentalControlsChangePin,
        signals: {
          $onHover: '$onChangePinHover',
        },
      },
    };
  }

  override _setup() {
    super._setup();

    this.label = translate('settings.ParentalControls.label');
    this.description = translate('settings.ParentalControls.description');

    this._ChangePinOption.setVisibility(
      AppData!.parentalPinService.isEnabled(),
    );
  }

  override _focus() {
    this._setState('Toggle');
  }

  override _unfocus() {
    this._setState('Unset');
  }

  private isParentalControlActive(): boolean {
    return AppData!.parentalPinService.isEnabled();
  }

  private updateToggleCallback(enabled: boolean): () => void {
    const callback = () => {
      AppData!.parentalPinService.togglePin(enabled);

      this._ChangePinOption.setVisibility(enabled);
      this._ToggleButton.isActive = enabled;

      this.fireAncestors(
        '$announcerRefresh',
        getFocusDepth(
          // @ts-ignore
          this.application,
          this as ToggleSettingSection,
        ),
      );
    };
    return callback.bind(this);
  }

  private handleParentalControlsOn() {
    SetParentalPin.onValidateCallback = this.updateToggleCallback(true);
    Router.focusWidget('SetParentalPin');
  }

  private handleParentalControlsOff() {
    EnterParentalPin.onValidateCallback = this.updateToggleCallback(false);
    EnterParentalPin.focusPageOnCancel = true;
    Router.focusWidget('EnterParentalPin');
  }

  private toggleParentalControls(enabled: boolean) {
    if (enabled) {
      this.handleParentalControlsOn();
    } else {
      this.handleParentalControlsOff();
    }
  }

  $onChangePinHover() {
    this._setState('ChangePin');
  }

  override $onToggleHover() {
    this._setState('Toggle');
  }

  static override _states() {
    return [
      class Unset extends this {},
      class Toggle extends this {
        override $enter() {
          this._Toggle.color = Colors('buttonInactive').get();

          this.fireAncestors(
            '$announcerRefresh',
            getFocusDepth(
              // @ts-ignore
              this.application,
              this as ToggleSettingSection,
            ),
          );
        }

        override $exit() {
          this._Toggle.color = Colors('transparent').get();
        }

        override _handleEnter() {
          const toggleState = !this.getToggleState();

          this.toggleParentalControls(toggleState);
        }

        override _handleDown() {
          const toggleState = this.getToggleState();
          if (toggleState) {
            this._setState('ChangePin');
          }
        }
      },
      class ChangePin extends this {
        override $enter() {
          this._ChangePinOption.setHighlightVisibility(true);

          this.fireAncestors(
            '$announcerRefresh',
            getFocusDepth(
              // @ts-ignore
              this.application,
              this as ToggleSettingSection,
            ),
          );
        }

        override $exit() {
          this._ChangePinOption.setHighlightVisibility(false);
        }

        override _handleEnter() {
          Router.focusWidget('ResetParentalPin');
        }

        override _handleUp() {
          this._setState('Toggle');
        }
      },
    ];
  }
}
