import { AppData, Colors, Lightning, Registry, Router } from '@lightningjs/sdk';
import Page, { PageTemplateSpec } from 'components/Page';
import { PageId } from 'types/pageId';
import { EpgChannel, EpgProgram } from 'types/api/media';
import { constants } from 'aliases';
import EpgHero from 'components/pages/epg/EpgHero';
import EpgGuide from 'components/pages/epg/EpgGuide';
import EpgPlayer, {
  MINI_PLAYER_HEIGHT,
  MINI_PLAYER_RADIUS,
  MINI_PLAYER_WIDTH,
  MINI_PLAYER_X,
  MINI_PLAYER_Y,
} from 'components/pages/epg/EpgPlayer';
import EpgModal from 'components/pages/epg/EpgModal';
import {
  getClosestMinute,
  getClosestThirtyMinuteOffsetDate,
  MILLISECONDS_IN_MINUTE,
  minutesToMilliseconds,
} from 'support/dateUtils';
import { isProgramPlaying } from 'support/contentUtils';
import { ErrorCause } from 'components/widgets/ErrorModal';
import { translate } from 'support/translate';
import Modal from 'components/widgets/Modal';
import { StaticViewContexts, ViewContext } from 'types/analytics';

export interface EpgPageTemplateSpec extends PageTemplateSpec {
  pageData: {
    channels: EpgChannel[];
    maxChannels: number;
    addedChannelSlug: string | undefined;
    viewContext: ViewContext;
  };

  Content: {
    Hero: typeof EpgHero;
    EpgPlayer: typeof EpgPlayer;
    MiniplayerErrorIndicator: {
      ErrorText: Lightning.textures.TextTexture;
    };
    Guide: typeof EpgGuide;
  };
  Modal: typeof EpgModal;
}

const NAVBAR_COLLAPSED_WIDTH = constants.sizing.navbar.collapsedWidth;

const EPG_PLAYER_ERROR_INDICATOR_FONT_SIZE = 23;

const EPG_GUIDE_Y = 478;

const MODAL_FOCUSED_CONTENT_ALPHA = 0.5;

export default class EpgPage
  extends Page<EpgPageTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<EpgPageTemplateSpec>
{
  protected override _pageId = PageId.EPG;

  // Internal properties
  private _epgTimeout?: number;
  private _channelError = false;
  private _ayswTimeout?: number;
  private _shouldPromptAysw = false;

  private _Content = this.getByRef('Content')!;
  private _Modal = this.getByRef('Modal')!;

  private _Hero = this._Content.getByRef('Hero')!;
  private _EpgPlayer = this._Content.getByRef('EpgPlayer')!;
  private _MiniplayerErrorIndicator = this._Content.getByRef(
    'MiniplayerErrorIndicator',
  )!;
  private _Guide = this._Content.getByRef('Guide')!;

  private static getMiniplayerHoleShader(): Lightning.types.Shader.SettingsLoose {
    return {
      type: Lightning.shaders.Hole,
      // small offsets added to fixed rendering issues
      x: MINI_PLAYER_X + 3.75,
      y: MINI_PLAYER_Y + 0.5,
      w: MINI_PLAYER_WIDTH - 7.5,
      h: MINI_PLAYER_HEIGHT - 1,
      radius: MINI_PLAYER_RADIUS,
    };
  }

  static override _template(): Lightning.Component.Template<EpgPageTemplateSpec> {
    const { Background, ...PageTemplate } = super._template();
    const miniplayerHoleShader = this.getMiniplayerHoleShader();

    return {
      ...PageTemplate,
      Background: {
        ...Background,
        shader: miniplayerHoleShader,
      },
      Content: {
        x: NAVBAR_COLLAPSED_WIDTH,
        Hero: {
          type: EpgHero,
          signals: {
            expandChannelVideo: '_onExpandChannelVideo',
          },
          miniplayerHoleShader: {
            ...miniplayerHoleShader,
            x: miniplayerHoleShader.x - NAVBAR_COLLAPSED_WIDTH,
          },
        },
        EpgPlayer: {
          xAbsoluteOffset: NAVBAR_COLLAPSED_WIDTH,
          type: EpgPlayer,
          signals: {
            backToGuide: '$backToGuide',
            $fatalError: '$fatalError',
            onFullscreenIdle: '_onFullscreenIdle',
            onFullscreenInteractive: '_onFullscreenInteractive',
          },
        },
        MiniplayerErrorIndicator: {
          visible: false,
          flex: {
            justifyContent: 'center',
            alignItems: 'center',
          },
          x: MINI_PLAYER_X - NAVBAR_COLLAPSED_WIDTH,
          y: MINI_PLAYER_Y,
          w: MINI_PLAYER_WIDTH,
          h: MINI_PLAYER_HEIGHT,
          rect: true,
          color: Colors('black').get(),
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            radius: MINI_PLAYER_RADIUS,
          },
          ErrorText: {
            text: {
              fontSize: EPG_PLAYER_ERROR_INDICATOR_FONT_SIZE,
              text: translate('epg.miniplayerError'),
            },
          },
        },
        Guide: {
          type: EpgGuide,
          y: EPG_GUIDE_Y,
          signals: {
            loading: '_isLoading',
            focusedProgramChange: '_onFocusedProgramChange',
          },
        },
      },
      Modal: {
        type: EpgModal,
        alpha: 0,
        signals: { dismissModal: '_dismissModal' },
        zIndex: 1,
      },
    };
  }

  override _setup() {
    super._setup();
    this._setState('Guide');
  }

  override _active(): void {
    super._active();

    this._setState('Guide');
    this._EpgPlayer.minify();
    this._handleCcChange = this.handleCcChange;
    this._setupAysw();
    this._resetAyswTimeout();
    this.isNavBarVisible = true;
  }

  override _inactive() {
    super._inactive();

    this.resetPlaybackError();
    this.clearEpgTimeout();
    this.clearAyswTimeout();
    this._handleCcChange = undefined;
  }

  override _onDataProvided() {
    const { channels, maxChannels, addedChannelSlug, viewContext } =
      this.pageData;
    if (!channels.length) return;
    const firstChannel = channels[0]!;

    this._Guide.patch({
      initialChannels: channels,
      maxChannels: maxChannels,
      addedChannelSlug,
    });
    this._Hero.patch({ channel: firstChannel });
    this._EpgPlayer.patch({
      // Order matters: View context must be defined before channel
      viewContext: viewContext,
      channel: firstChannel,
    });

    // Select first channel after initializing channels
    this._Guide.selectChannel(firstChannel);
    this._Hero.startFullscreenInterval();
    this.setEpgTimeout();
  }

  override _handleBack() {
    Router.focusWidget('NavBar');
  }

  private handleCcChange(tvCcEnabled: boolean) {
    const availableSubtitle = this._EpgPlayer.player.getSubtitles()[0];
    if (availableSubtitle) {
      this._EpgPlayer.$onSubtitlesChange(
        tvCcEnabled ? availableSubtitle : constants.closedCaption.none,
      );
    }
  }

  private $onChannelSelect(channel: EpgChannel) {
    const playerChannel = this._EpgPlayer.channel;
    const channelError = this._channelError;

    if (playerChannel && playerChannel.id === channel.id) {
      // Reselected currently playing channel program
      // Do nothing if there was an error playing channel
      if (channelError) return;

      this._setState('FullScreenPlayback');
      this._Hero.clearFullscreenInterval();
    } else {
      this.resetPlaybackError();

      this._Guide.selectChannel(channel);

      this._Hero.patch({ channel });
      this._Hero.startFullscreenInterval();

      this._EpgPlayer.patch({
        // Order matters: View context must be defined before channel
        viewContext: StaticViewContexts.EPG,
        channel,
      });
      // TODO: Use this._Hero.title() for TTS
    }
  }

  // Event only triggered on selecting non-live programs
  private $onProgramSelect(program: EpgProgram, channel: EpgChannel) {
    this._Modal.patch({ program, channel });
    this._setState('Modal');
  }

  private $backToGuide() {
    this._setState('Guide');
    this._resetAyswTimeout();
  }

  $fatalError() {
    this._Hero.clearFullscreenInterval();

    this._channelError = true;
    this._MiniplayerErrorIndicator.visible = true;

    this.clearAyswTimeout();
  }

  private resetPlaybackError() {
    // Reset channel error state
    this._channelError = false;
    this._MiniplayerErrorIndicator.visible = false;
  }

  private _onExpandChannelVideo(channel: EpgChannel) {
    if (!this._channelError) this._setState('FullScreenPlayback');
  }

  private _onFullscreenIdle() {
    this._resetAyswTimeout();
  }

  private _onFullscreenInteractive() {
    this.clearAyswTimeout();
  }

  private _isLoading(isLoading: boolean) {
    this.loaded = !isLoading;
  }

  private _onFocusedProgramChange(program: EpgProgram | null) {
    this._Hero.clearFullscreenInterval();
    this._resetAyswTimeout();
    if (!program) return;

    const playerChannel = this._EpgPlayer.channel;
    const guideChannel = this._Guide.focusedChannel;

    if (playerChannel?.id === guideChannel?.id) {
      if (isProgramPlaying(program) && !this._channelError)
        this._Hero.startFullscreenInterval();
    } else {
      this._Hero.patch({ channel: guideChannel! });
    }
    this._Hero.patch({ program });
  }

  private _dismissModal() {
    this._setState('Guide');
  }

  private setEpgTimeout() {
    // Use current time as source of truth
    const currentTime = new Date();
    const currentTimeClosestToMinute = getClosestMinute(new Date(currentTime));
    const epgStartTime = getClosestThirtyMinuteOffsetDate(
      new Date(currentTime),
    );

    this._Hero.updateTime(currentTimeClosestToMinute);
    this._Guide.updateTime(currentTimeClosestToMinute, epgStartTime);
    this._EpgPlayer.updateTime(currentTimeClosestToMinute);

    let nextMinute = new Date(currentTime); // Find time 1 minute away
    nextMinute.setTime(nextMinute.getTime() + MILLISECONDS_IN_MINUTE);
    nextMinute = getClosestMinute(nextMinute);

    this._epgTimeout = Registry.setTimeout(
      this.setEpgTimeout.bind(this),
      nextMinute.getTime() - currentTime.getTime(),
    );
  }

  private clearEpgTimeout() {
    if (this._epgTimeout) Registry.clearTimeout(this._epgTimeout);
    this._epgTimeout = undefined;
  }

  private clearAyswTimeout() {
    this._shouldPromptAysw = false;
    if (this._ayswTimeout) {
      Registry.clearTimeout(this._ayswTimeout);
      this._ayswTimeout = undefined;
    }
  }

  private _resetAyswTimeout() {
    this.clearAyswTimeout();
    if (this._channelError) return;

    const ayswTimeoutMinutes = AppData?.ayswIdleTimes?.epg ?? 0;
    if (ayswTimeoutMinutes > 0)
      this._ayswTimeout =
        ayswTimeoutMinutes &&
        Registry.setTimeout(() => {
          this._shouldPromptAysw = true;
          this._EpgPlayer.pause();
          this._showAysw();
        }, minutesToMilliseconds(ayswTimeoutMinutes));
  }

  private _setupAysw() {
    Modal.construct(this.widgets.modal, {
      title: '', // Title will be set in _showAysw
      confirmLabel: translate('global.continue'),
      cancelLabel: '', // Cancel label will be set in _showAysw
      bgAlpha: 0.9,
      bgColor: 'black',
      onBack: this._handleContinueOnAysw.bind(this),
      onConfirm: this._handleContinueOnAysw.bind(this),
      onCancel: this._handleBackOnAysw.bind(this),
    });
  }

  private _handleContinueOnAysw() {
    this._EpgPlayer.resume();
    this._resetAyswTimeout();
  }

  private _handleBackOnAysw() {
    this._EpgPlayer.minify();
    this.$backToGuide();
  }

  private _showAysw() {
    if (!this._shouldPromptAysw) return;
    this.clearAyswTimeout();

    const showName = this._EpgPlayer.channel?.title ?? 'the content';
    this.widgets.modal.title = translate('playback.stillWatching', showName);
    this.widgets.modal.cancelLabel =
      this._getState() === 'FullScreenPlayback'
        ? translate('global.returnToEPG')
        : '';
    Router.focusWidget('Modal');
  }

  static override _states() {
    return [
      class Guide extends this {
        override _getFocused() {
          return this._Guide;
        }
      },
      class Modal extends this {
        override $enter() {
          this._Modal.setSmooth('alpha', 1);
          this._Content.setSmooth('alpha', MODAL_FOCUSED_CONTENT_ALPHA);
          (this.widgets as any).navbar.setSmooth(
            'alpha',
            MODAL_FOCUSED_CONTENT_ALPHA,
          );
        }

        override $exit() {
          this._Modal.setSmooth('alpha', 0);
          this._Content.setSmooth('alpha', 1);
          (this.widgets as any).navbar.setSmooth('alpha', 1);
        }

        override _getFocused() {
          return this._Modal;
        }
      },

      class FullScreenPlayback extends this {
        override $enter() {
          this._Hero.patch({ visible: false });
          this._Guide.patch({ visible: false });

          this.hideBackground();
          this.widgets?.navbar.patch({ visible: false });

          this._EpgPlayer.fullscreen();

          if (this.widgets.navbar.hasFocus()) {
            Router.focusPage();
          }
        }

        override $exit() {
          this._Hero.patch({ visible: true });
          this._Guide.patch({ visible: true });

          this.showBackground();
          this.widgets?.navbar.patch({ visible: true });

          this._EpgPlayer.minify();
        }

        override _getFocused() {
          return this._EpgPlayer;
        }

        override _handleLeft() {
          // prevent navbar focus
        }

        override _handleBack() {
          this._setState('Guide');
        }

        override $fatalError() {
          this.widgets.errormodal.update({ cause: ErrorCause.CHANNEL });
          Router.focusWidget('ErrorModal');

          this._setState('Guide');
          this.clearAyswTimeout();
          this._Hero.clearFullscreenInterval();

          this._channelError = true;
          this._MiniplayerErrorIndicator.visible = true;
        }
      },
    ];
  }
}
