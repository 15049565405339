/* eslint-disable @typescript-eslint/no-empty-function */
import { Lightning } from '@lightningjs/sdk';
import AbstractVideoOverlay from 'components/pages/playback/AbstractVideoOverlay';
import { constants } from 'aliases';
import VideoDetails from 'components/pages/playback/VideoDetails';
import AdDetails from 'components/pages/playback/AdDetails';
import CcAudioMenu from 'components/pages/playback/CcAudioMenu';
import { LiveEventChannel, Video } from 'types/api/media';
import { AdCuePoint } from 'components/pages/playback/PlaybackPage';
import VodControls from 'components/pages/playback/VodControls';
import LiveControls from 'components/pages/playback/LiveControls';

const PADDING_X = (1920 - constants.ui.progressBarWidth) / 2;
const VOD_CONTROL_Y = 1080 - 251;
const LIVE_CONTROL_Y = 1080 - 174.5;

interface VideoOverlayTemplateSpec extends Lightning.Component.TemplateSpec {
  duration: number;
  mediaContent: Video | LiveEventChannel;
  contentType: 'vod' | 'live' | null;
  VideoDetails: typeof VideoDetails;
  AdDetails: typeof AdDetails;
  VodControls: typeof VodControls;
  LiveControls: typeof LiveControls;
  CcAudioMenu: typeof CcAudioMenu;
}

interface VideoOverlaySignalMap extends Lightning.Component.SignalMap {
  skip(): void;
  seek(): void;
  pause(): void;
  seekPause(): void;
  play(): void;
  seeking(): void;
  startOver(): void;
  ccAudio(): void;
  onSubtitlesChange(): void;
  onAudioChange(): void;
  openMenu(): void;
  closeMenu(): void;
  onControlHover(): void;
}

interface VideoOverlayTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: VideoOverlaySignalMap;
}

export default class VideoOverlay extends AbstractVideoOverlay<
  VideoOverlayTemplateSpec,
  VideoOverlayTypeConfig
> {
  private _VideoDetails = this.getByRef('VideoDetails')!;
  private _AdDetails = this.getByRef('AdDetails')!;
  private _VodControls = this.getByRef('VodControls')!;
  private _LiveControls = this.getByRef('LiveControls')!;
  private _CcAudioMenu = this.getByRef('CcAudioMenu')!;

  _contentType: 'vod' | 'live' | null = null;

  set duration(duration: number) {
    this._VodControls.duration = duration;
  }
  set mediaContent(mediaContent: Video | LiveEventChannel) {
    this._VideoDetails.mediaContent = mediaContent;
  }

  set contentType(contentType: 'vod' | 'live' | null) {
    this._contentType = contentType;

    if (this._contentType === 'vod') {
      this._setState('VodControls');
    } else if (this._contentType === 'live') {
      this._setState('LiveControls');
    }
  }

  static override _template(): Lightning.Component.Template<VideoOverlayTemplateSpec> {
    return {
      ...super._template(),
      VideoDetails: {
        x: PADDING_X,
        y: 90,
        type: VideoDetails,
      },
      AdDetails: {
        x: 1920 - PADDING_X,
        y: 90,
        mountX: 1,
        type: AdDetails,
        visible: false,
      },
      VodControls: {
        x: PADDING_X,
        y: VOD_CONTROL_Y,
        type: VodControls,
        passSignals: {
          skip: true,
          seek: true,
          pause: true,
          seekPause: true,
          play: true,
          seeking: true,
          startOver: true,
          ccAudio: true,
          onControlHover: true,
        },
        signals: {
          announceProgressBar: 'announceProgressBar',
        },
        visible: false,
      },
      LiveControls: {
        x: PADDING_X,
        y: LIVE_CONTROL_Y,
        type: LiveControls,
        passSignals: {
          ccAudio: true,
          onControlHover: true,
        },
        visible: false,
      },
      CcAudioMenu: {
        x: constants.ui.ccAudioX,
        y: constants.ui.ccAudioY,
        mountY: 1,
        type: CcAudioMenu,
        passSignals: {
          onSubtitlesChange: true,
          onAudioChange: true,
        },
        visible: false,
      },
    };
  }

  override _setup() {
    super._setup();
  }

  override _active() {
    if (this._contentType === 'vod') {
      this._setState('VodControls');
    } else if (this._contentType === 'live') {
      this._setState('LiveControls');
    } else {
      this._setState('Unset');
    }
  }

  initialize() {
    this._AdDetails.visible = false;
    this._VodControls.duration = 0;
    this.setIsAdPlaying(false);
    this.hide();
  }

  adStarted(isPreroll: boolean, duration: number) {
    this._AdDetails.isPreroll = isPreroll;
    this._AdDetails.visible = true;
  }

  adBreakEnded(videoDuration: number) {
    this._AdDetails.visible = false;
  }

  updateAdProgress(remainingTime: number) {
    this._AdDetails.countdown = remainingTime;
  }

  updateCc(ccIndex: number, audioIndex: number, cc: string[], audio: string[]) {
    this._CcAudioMenu.cc = cc;
    this._CcAudioMenu.audio = audio;
    this._CcAudioMenu.ccIndex = ccIndex;
    this._CcAudioMenu.audioIndex = audioIndex;

    this._setState('CcAudioMenu');
  }

  setIsAdPlaying(isAdPlaying: boolean) {
    this._VideoDetails.setSmooth('alpha', isAdPlaying ? 0.5 : 1);
  }

  isInMenu() {
    return this._CcAudioMenu.visible;
  }

  handleMediaFFRW(seekType: 'FF' | 'RW') {
    seekType === 'FF'
      ? this._VodControls.onFastForward()
      : this._VodControls.onRewind();
  }

  private announceProgressBar(progressBarAnnounce: string) {
    const videoDetails = this._VideoDetails.announce;
    const phrase = [progressBarAnnounce, ...videoDetails];
    this.fireAncestors('$announce', phrase.join(', '));
  }

  // Implemented in state
  updateCuePoints(cuepoints: AdCuePoint[]) {}
  updateProgress(relativeTime: number) {}
  updateFromPlay() {}
  updateFromPause() {}

  static override _states() {
    return [
      class Unset extends this {
        override $enter() {
          console.error('Overlay type is unset');
        }
      },

      class LiveControls extends this {
        override _getFocused() {
          return this._LiveControls;
        }

        override $enter() {
          this._VideoDetails.visible = true;
          this._LiveControls.visible = true;
        }

        override $exit() {
          this._VideoDetails.visible = false;
          this._LiveControls.visible = false;
        }
      },

      class VodControls extends this {
        override _getFocused() {
          return this._VodControls;
        }

        override $enter() {
          this._VideoDetails.visible = true;
          this._VodControls.visible = true;
        }

        override $exit() {
          this._VideoDetails.visible = false;
          this._VodControls.visible = false;
        }

        override adStarted(isPreroll: boolean, duration: number) {
          super.adStarted(isPreroll, duration);

          // note setting _VideoControls.isAdPlaying is handled in player event consumer
          this._VodControls.duration = duration;
        }

        override adBreakEnded(videoDuration: number) {
          super.adBreakEnded(videoDuration);

          // note setting _VideoControls.isAdPlaying is handled in player event consumer
          this._VodControls.duration = videoDuration;
        }

        override setIsAdPlaying(isAdPlaying: boolean) {
          super.setIsAdPlaying(isAdPlaying);

          this._VodControls.isAdPlaying = isAdPlaying;
        }

        override updateCuePoints(cuepoints: AdCuePoint[]) {
          this._VodControls.adCuePoints = cuepoints;
        }

        override updateProgress(relativeTime: number) {
          this._VodControls.progress = relativeTime;
        }

        override updateFromPlay() {
          this._VodControls.updateFromPlay();
        }

        override updateFromPause() {
          this._VodControls.updateFromPause();
        }
      },
      class CcAudioMenu extends this {
        override _getFocused() {
          return this._CcAudioMenu;
        }

        override $enter() {
          this._CcAudioMenu.visible = true;
          this.signal('openMenu');
        }

        override $exit() {
          this._CcAudioMenu.visible = false;
          this.signal('closeMenu');
        }

        override _handleBack() {
          if (this._contentType === 'vod') {
            this._setState('VodControls');
          } else if (this._contentType === 'live') {
            this._setState('LiveControls');
          }
        }
      },
    ];
  }
}
