import { Colors, Lightning, Registry } from '@lightningjs/sdk';
import Button from 'components/common/Button';
import MoreInfo from 'components/common/MoreInfo';
import MoreInfoModal from 'components/common/MoreInfoModal';
import { getFocusDepth, getImageTextureObj } from 'support/generalUtils';
import { translate } from 'support/translate';
import { LiveEventChannel, LiveEventOverlays } from 'types/api/media';
import { getFontFaceFromStyle } from 'support/textUtils';
import Placeholder from 'components/common/Placeholder';
import LiveEventOverlay from 'components/common/LiveEventOverlay';
import { constants } from 'aliases';
import { getLiveEventStatus } from 'support/contentUtils';

export interface LiveEventShowcaseTemplateSpec
  extends Lightning.Component.TemplateSpec {
  media: LiveEventChannel;

  Placeholder: typeof Placeholder;
  Billboard: Lightning.textures.ImageTexture;
  HorizontalGradient: Lightning.textures.RectangleTexture;
  Content: {
    Metadata: {
      Overlay: typeof LiveEventOverlay;
      Title: Lightning.textures.TextTexture;
      TuneIn: Lightning.textures.TextTexture;
    };
    MoreInfo: typeof MoreInfo;
    PlayButton: typeof Button;
  };
  MoreInfoModal: typeof MoreInfoModal;
}

const REFRESH_TIME_OFFSET = 1000;
const MAX_TIMEOUT = constants.timers.timeoutMaximum;

const BILLBOARD_W = 1920;
const BILLBOARD_H = 768;

const HORIZ_GRADIENT_W = BILLBOARD_W * 0.4; // Gradient is 40%
const HORIZ_GRADIENT_H = BILLBOARD_H;

const CONTENT_X = 96;
const CONTENT_Y = 333;
const CONTENT_MARGIN_BOTTOM = 18;

const METADATA_WIDTH = 777;
const METADATA_MARGIN_BOTTOM = 8;
const METADATA_FONT_SIZE = 29;

const MORE_INFO_PADDING = 20;

const PLAY_BUTTON_WIDTH = 207;
const PLAY_BUTTON_FONT_SIZE = 23;

const BUTTON_HEIGHT = 60;
const PLAY_ICON_UNFOCUSED = getImageTextureObj(
  'static/images/playback/play-icon-light.svg',
  28,
  30,
);
const PLAY_ICON_FOCUSED = getImageTextureObj(
  'static/images/playback/play-icon-dark.svg',
  28,
  30,
);

export default class LiveEventShowcase
  extends Lightning.Component<LiveEventShowcaseTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<LiveEventShowcaseTemplateSpec>
{
  private _media: LiveEventChannel | null = null;
  private refreshTimeout: number | null = null;

  private _Billboard = this.getByRef('Billboard')!;
  private _Content = this.getByRef('Content')!;
  private _Metadata = this._Content.getByRef('Metadata')!;
  private _Overlay = this._Metadata.getByRef('Overlay')!;
  private _Title = this._Metadata.getByRef('Title')!;
  private _TuneIn = this._Metadata.getByRef('TuneIn')!;
  private _MoreInfo = this._Content.getByRef('MoreInfo')!;
  private _PlayButton = this._Content.getByRef('PlayButton')!;
  private _MoreInfoModal = this.getByRef('MoreInfoModal')!;

  static get h() {
    return BILLBOARD_H;
  }

  get announce() {
    const overlayStatus = this._Overlay.title;
    const title = this._Title.text?.text ?? '';
    const tuneIn = this._TuneIn.text?.text ?? '';
    const announcement = [overlayStatus, title, tuneIn];

    // Add more info description to TTS if focused on play button
    if (this._getState() === 'PlayButton') {
      const description = this._MoreInfo.description;
      announcement.push(description);
    }

    return announcement;
  }

  set media(media: LiveEventChannel) {
    this._media = media;

    this.updateComponents();
  }

  static override _template(): Lightning.Component.Template<LiveEventShowcaseTemplateSpec> {
    return {
      w: BILLBOARD_W,
      h: BILLBOARD_H,
      Placeholder: {
        type: Placeholder,
        w: (w: number) => w,
        h: (h: number) => h,
      },
      Billboard: {
        w: (w: number) => w,
        h: (h: number) => h,
      },
      HorizontalGradient: {
        rect: true,
        w: HORIZ_GRADIENT_W,
        h: HORIZ_GRADIENT_H,
        colorLeft: Colors('appBackground').get(),
        colorRight: Colors('appBackground').alpha(0).get(),
      },
      Content: {
        x: CONTENT_X,
        y: CONTENT_Y,
        flex: { direction: 'column' },
        Metadata: {
          flexItem: { marginBottom: CONTENT_MARGIN_BOTTOM },
          flex: { direction: 'column' },
          Overlay: {
            flexItem: { marginBottom: METADATA_MARGIN_BOTTOM },
            type: LiveEventOverlay,
          },
          Title: {
            flexItem: { marginBottom: METADATA_MARGIN_BOTTOM },
            h: METADATA_FONT_SIZE + 6,
            text: {
              fontSize: METADATA_FONT_SIZE,
              fontFace: getFontFaceFromStyle('bold'),
              textColor: Colors('text').get(),
              maxLines: 1,
              wordWrapWidth: METADATA_WIDTH,
            },
          },
          TuneIn: {
            h: METADATA_FONT_SIZE + 6,
            text: {
              fontSize: METADATA_FONT_SIZE,
              textColor: Colors('text').get(),
              maxLines: 1,
              wordWrapWidth: METADATA_WIDTH,
            },
          },
        },
        MoreInfo: {
          flexItem: { marginBottom: CONTENT_MARGIN_BOTTOM },
          type: MoreInfo,
          x: -MORE_INFO_PADDING,
          action: '$showModal',
          signals: {
            $showModal: '$showModal',
            $onHover: '$onHover',
          },
        },
        PlayButton: {
          type: Button,
          visible: true,
          minWidth: PLAY_BUTTON_WIDTH,
          height: BUTTON_HEIGHT,
          label: translate('global.watchLive'),
          fontSize: PLAY_BUTTON_FONT_SIZE,
          action: '$playContent',
          startIcon: {
            focused: PLAY_ICON_FOCUSED,
            unfocused: PLAY_ICON_UNFOCUSED,
          },
          passSignals: {
            $playContent: '$playContent',
          },
          signals: {
            $onHover: '$onHover',
          },
        },
      },
      MoreInfoModal: {
        type: MoreInfoModal,
        visible: false,
      },
    };
  }

  override _inactive() {
    this._Billboard.removeAllListeners('txError');
  }

  private updateBillboardImage() {
    this._Billboard.removeAllListeners('txError');
    this._Billboard.on('txError', () => {
      this._Billboard.patch({ texture: undefined });
    });

    const src = this._media!.images.liveStreamBackground ?? '';
    const billboardTexture = getImageTextureObj(src, BILLBOARD_W, BILLBOARD_H);
    this._Billboard.patch(billboardTexture);
  }

  private updateMetadata(eventStatus: keyof LiveEventOverlays) {
    this.updateOverlay(eventStatus);
    this.updateTitle();
    this.updateTuneIn();
  }

  private updateOverlay(eventStatus: keyof LiveEventOverlays) {
    const overlays = this._media!.overlays;
    this._Overlay.patch({ overlays, eventStatus });
  }

  private updateTitle() {
    const title = this._media!.title;
    this._Title.patch({ text: { text: title } });
  }

  private updateTuneIn() {
    const tuneIn = this._media!.tuneIn;
    this._TuneIn.patch({ text: { text: tuneIn } });
  }

  private updateMoreInfo() {
    const description = this._media!.description;
    this._MoreInfo.patch({ description });
  }

  private updateMoreInfoModal() {
    this._MoreInfoModal.patch({ mediaContent: this._media! });
  }

  private updatePlayButton(eventStatus: keyof LiveEventOverlays) {
    if (eventStatus === 'live') {
      this._PlayButton.patch({ visible: true });
      this._setState('PlayButton');
    } else {
      this._PlayButton.patch({ visible: false });
      this._setState('MoreInfo');
    }
  }

  private hideModal() {
    this._MoreInfoModal.patch({ visible: false });
  }

  private clearRefreshTimeout() {
    if (this.refreshTimeout !== null) {
      Registry.clearTimeout(this.refreshTimeout);
      this.refreshTimeout = null;
    }
  }

  private updateComponents() {
    if (!this._media) return;

    const { eventStatus, refreshDate } = getLiveEventStatus(this._media);

    if (refreshDate) {
      const refreshTime =
        refreshDate.getTime() - new Date().getTime() + REFRESH_TIME_OFFSET; // add offset to ensure event status change

      if (refreshTime <= MAX_TIMEOUT) {
        this.refreshTimeout = Registry.setTimeout(() => {
          this.updateComponents();

          // @ts-ignore (prevents "excessively deep and possibly infinite" error)
          this.fireAncestors(
            '$announcerRefresh',
            getFocusDepth(
              // @ts-ignore
              this.application,
              this as LiveEventShowcase,
            ),
          );
        }, refreshTime);
      }
    }

    this.updateBillboardImage();
    this.updateMetadata(eventStatus);
    this.updateMoreInfo();
    this.updateMoreInfoModal();
    this.updatePlayButton(eventStatus);
  }

  private $showModal() {
    this._MoreInfoModal.patch({ visible: true });
    this._setState('MoreInfoModal');
  }

  private $onHover(target: any) {
    switch (target) {
      case this._MoreInfo:
        this._setState('MoreInfo');
        break;
      case this._PlayButton:
        this._setState('PlayButton');
        break;
    }
    this.signal('$onHover', this);
  }

  static override _states() {
    return [
      class MoreInfo extends this {
        override _getFocused() {
          return this._MoreInfo;
        }

        override _handleDown() {
          if (this._PlayButton.visible) {
            this._setState('PlayButton');
          } else {
            return false;
          }
        }
      },
      class MoreInfoModal extends this {
        override _getFocused() {
          return this._MoreInfoModal;
        }

        override _handleBack() {
          this._setState('MoreInfo');
          this.hideModal();
        }

        override _handleKey() {
          return true;
        }
      },
      class PlayButton extends this {
        override _getFocused() {
          return this._PlayButton;
        }

        override _handleUp() {
          this._setState('MoreInfo');
        }
      },
    ];
  }
}
