import { Lightning, Colors, AppData, Router } from '@lightningjs/sdk';
import { getFontFaceFromStyle } from 'support/textUtils';
import { translate } from 'support/translate';
import { LaunchPromptData } from 'types/api/config';
import Button from 'components/common/Button';
import ActionsList from 'components/common/ActionsList';
import { closeApp } from 'support/appUtils';
import { HoverableComponent } from 'components/common/HoverableComponent';
import ExitModal from 'components/widgets/ExitModal';

const TITLE_MARGIN_BOTTOM = 30;
const DESCRIPTION_MARGIN_BOTTOM = 60;

const TITLE_FONT_SIZE = 63;
const DEFAULT_FONT_SIZE = 32;

const MIN_BUTTON_WIDTH = 300;

export interface AppLaunchTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Title: Lightning.textures.TextTexture;
  Description: Lightning.textures.TextTexture;
  Actions: typeof ActionsList;
}

export default class AppLaunch
  extends HoverableComponent<AppLaunchTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<AppLaunchTemplateSpec>
{
  private _Title = this.getByRef('Title')!;
  private _Description = this.getByRef('Description')!;
  private _Actions = this.getByRef('Actions')!;

  private _CancelButton = this._Actions.children[0]! as Button;
  private _AgreeButton = this._Actions.children[1]! as Button;

  private static _termsUrl = '';
  private static _promptData?: LaunchPromptData;
  private static _widgetAfter: keyof Router.Widgets | null = null;

  get announce() {
    const title = this._Title.text?.text ?? '';
    const description = this._Description.text?.text ?? '';

    return [title, description];
  }

  static set termsUrl(url: string) {
    AppLaunch._termsUrl = url;
  }

  static set promptData(data: LaunchPromptData) {
    AppLaunch._promptData = data;
  }

  static set widgetAfter(widget: keyof Router.Widgets | null) {
    AppLaunch._widgetAfter = widget;
  }

  static override _template(): Lightning.Component.Template<AppLaunchTemplateSpec> {
    return {
      alpha: 0,
      rect: true,
      w: 1920,
      h: 1080,
      color: Colors('modalBackground').get(),
      flex: {
        direction: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
      Title: {
        flexItem: { marginBottom: TITLE_MARGIN_BOTTOM },
        text: {
          fontSize: TITLE_FONT_SIZE,
          fontFace: getFontFaceFromStyle('bold'),
        },
      },
      Description: {
        flexItem: { marginBottom: DESCRIPTION_MARGIN_BOTTOM },
        text: { fontSize: DEFAULT_FONT_SIZE },
      },
      Actions: {
        type: ActionsList,
        signals: {
          $cancel: true,
          $agree: true,
        },
        index: 1,
        items: [
          {
            type: Button,
            minWidth: MIN_BUTTON_WIDTH,
            action: '$cancel',
            passSignals: { $cancel: true },
            signals: {
              $onHover: '$onHover',
            },
            zIndex: 2,
          },
          {
            type: Button,
            minWidth: MIN_BUTTON_WIDTH,
            action: '$agree',
            passSignals: { $agree: true },
            signals: {
              $onHover: '$onHover',
            },
            zIndex: 2,
          },
        ],
      },
      zIndex: 2,
    };
  }

  override _handleBack() {
    this.focusExitModal();
  }

  override _handleKey() {
    // Do not allow the user to bypass the app launch screen
    return true;
  }

  override _getFocused() {
    return this._Actions;
  }

  override _focus() {
    this.update();
    this.alpha = 1;
  }

  private $cancel() {
    this.focusExitModal();
  }

  private $agree() {
    const currentTime = new Date().getTime();
    AppData!.storageService.termsAcceptedTimestamp.set(currentTime);

    this.alpha = 0;
    if (AppLaunch._widgetAfter) {
      Router.focusWidget(AppLaunch._widgetAfter);
    } else {
      Router.focusPage();
    }
  }

  private focusExitModal() {
    this.alpha = 0;

    ExitModal.onClose = () => {
      this.alpha = 1;
      Router.focusWidget('AppLaunch');
    };
    Router.focusWidget('ExitModal');
  }

  private update() {
    const { title, description, accept_title, decline_title } =
      AppLaunch._promptData ?? {};
    const termsUrl = AppLaunch._termsUrl;

    this._Title.patch({ text: { text: title || translate('') } });
    this._Description.patch({
      text: { text: `${description} ${termsUrl}` || translate('') },
    });

    this._CancelButton.patch({
      label: decline_title || translate('global.cancelButton'),
    });
    this._AgreeButton.patch({
      label: accept_title || translate('global.agreeButton'),
    });
  }
}
