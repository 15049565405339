import { Colors, Lightning } from '@lightningjs/sdk';
import { Video } from 'types/api/media';
import { getFontFaceFromStyle } from 'support/textUtils';
import { translate } from 'support/translate';
import Placeholder from 'components/common/Placeholder';
import MediaProgressBar from 'components/common/progressBar/MediaProgressBar';
import { getImageTextureObj } from 'support/generalUtils';
import Badge from 'components/common/Badge';
import { getExpiringDateString, isMediaExpiring } from 'support/contentUtils';
import { HoverableListItem } from 'components/common/HoverableListItem';

export interface HorizontalMediaItemTemplateSpec
  extends Lightning.Component.TemplateSpec {
  mediaItem: Video | undefined;

  ImageContainer: {
    Placeholder: typeof Placeholder;
    SeriesImage: Lightning.Texture;
    EpisodeImage: Lightning.Texture;
    Badge: typeof Badge;
    PlayButton: Lightning.Texture;
    ProgressBar: typeof MediaProgressBar;
  };
  Content: {
    Title: Lightning.Element;
    Metadata: Lightning.Element;
    Description: Lightning.Element;
  };
}

const BADGE_PADDING = 5;

const PLAY_BUTTON_WIDTH = 34;
const PLAY_BUTTON_HEIGHT = 43;

export default abstract class HorizontalMediaItem<
    TemplateSpec extends Lightning.Component.TemplateSpec = Lightning.Component.TemplateSpecLoose,
  >
  extends HoverableListItem<TemplateSpec & HorizontalMediaItemTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<HorizontalMediaItemTemplateSpec>
{
  protected _mediaItem: HorizontalMediaItemTemplateSpec['mediaItem'];

  protected _ImageContainer = (this as HorizontalMediaItem).getByRef(
    'ImageContainer',
  )!;
  protected _SeriesImage = this._ImageContainer.getByRef('SeriesImage')!;
  protected _EpisodeImage = this._ImageContainer.getByRef('EpisodeImage')!;
  protected _Badge = this._ImageContainer.getByRef('Badge')!;
  protected _PlayButton = this._ImageContainer.getByRef('PlayButton')!;
  protected _ProgressBar = this._ImageContainer.getByRef('ProgressBar')!;

  protected _Content = (this as HorizontalMediaItem).getByRef('Content')!;
  protected _Title = this._Content.getByRef('Title')!;
  protected _Metadata = this._Content.getByRef('Metadata')!;
  protected _Description = this._Content.getByRef('Description')!;
  protected _imageUrl: string | undefined;

  get title() {
    return [
      this._Title.text?.text ?? '',
      this._Metadata.text?.text ?? '',
      this._Description.text?.text ?? '',
      this._ProgressBar.visible ? this._ProgressBar.title : '',
    ];
  }

  set mediaItem(mediaItem: HorizontalMediaItemTemplateSpec['mediaItem']) {
    this._mediaItem = mediaItem;
    this.updateText();
    this.updateImages();
    this.updateLabel();
    this.updateProgressBar();
  }

  get mediaItem() {
    return this._mediaItem;
  }

  // Extending components should define the following static values
  static get width() {
    return 0;
  }

  static get height() {
    return 0;
  }

  static get imageSpacing() {
    return 0;
  }

  static get imageWidth() {
    return 0;
  }

  static get imageHeight() {
    return 0;
  }

  static get progressBarWidth() {
    return 0;
  }

  static get progressBarBottomPadding() {
    return 0;
  }

  static get contentY() {
    return 0;
  }

  static get titleTextWidth() {
    return this.width - this.contentX;
  }

  static get metadataTextWidth() {
    return this.width - this.contentX;
  }

  static get descriptionTextWidth() {
    return this.width - this.contentX;
  }

  static get titleTextStyle(): Lightning.textures.TextTexture.Settings {
    return {};
  }

  static get metadataTextStyle(): Lightning.textures.TextTexture.Settings {
    return {};
  }

  static get descriptionTextStyle(): Lightning.textures.TextTexture.Settings {
    return {};
  }

  // The following calculations shouldn't need to change
  private static get progressBarX() {
    return (this.imageWidth - this.progressBarWidth) / 2;
  }

  private static get progressBarY() {
    return (
      this.imageHeight -
      MediaProgressBar.defaultHeight -
      this.progressBarBottomPadding
    );
  }

  static get contentX() {
    return this.imageWidth + this.imageSpacing;
  }

  static override _template(): Lightning.Component.Template<HorizontalMediaItemTemplateSpec> {
    const defaultMaxLineSuffix = translate('global.defaultMaxLineSuffix');
    const playButtonTexture = getImageTextureObj(
      'static/images/playback/player-play-icon.svg',
      PLAY_BUTTON_WIDTH,
      PLAY_BUTTON_HEIGHT,
    );

    return {
      w: this.width,
      h: this.height,
      ImageContainer: {
        w: this.imageWidth,
        h: this.imageHeight,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 10,
        },
        Placeholder: {
          type: Placeholder,
          w: (w: number) => w,
          h: (h: number) => h,
        },
        SeriesImage: {
          alpha: 0,
          w: (w: number) => w,
          h: (h: number) => h,
        },
        EpisodeImage: {
          alpha: 0,
          w: (w: number) => w,
          h: (h: number) => h,
        },
        Badge: {
          type: Badge,
          mountX: 1,
          x: w => w - BADGE_PADDING,
          y: BADGE_PADDING,
        },
        PlayButton: {
          alpha: 0,
          mount: 0.5,
          x: (w: number) => w / 2,
          y: (h: number) => h / 2,
          ...playButtonTexture,
        },
        ProgressBar: {
          type: MediaProgressBar,
          visible: false,
          width: this.progressBarWidth,
          x: this.progressBarX,
          y: this.progressBarY,
        },
      },
      Content: {
        flex: { direction: 'column' },
        x: this.contentX,
        y: this.contentY,
        Title: {
          w: this.titleTextWidth,
          text: {
            textColor: Colors('text').get(),
            maxLinesSuffix: defaultMaxLineSuffix,
            ...this.titleTextStyle,
          },
        },
        Metadata: {
          w: this.metadataTextWidth,
          text: {
            textColor: Colors('text').get(),
            fontFace: getFontFaceFromStyle('light'),
            maxLinesSuffix: defaultMaxLineSuffix,
            ...this.metadataTextStyle,
          },
        },
        Description: {
          w: this.descriptionTextWidth,
          text: {
            textColor: Colors('text').get(),
            maxLinesSuffix: defaultMaxLineSuffix,
            ...this.descriptionTextStyle,
          },
        },
      },
    };
  }

  override _focus() {
    this.updatePlayButton();
  }

  override _unfocus() {
    this.updatePlayButton();
  }

  private updatePlayButton() {
    const playButtonAlpha = this.hasFocus() ? 0.6 : 0;
    this._PlayButton.setSmooth('alpha', playButtonAlpha);
  }

  protected updateText() {
    // Defined by extending components
  }

  protected updateImages() {
    // Defined by extending components
  }

  protected updateLabel() {
    const mediaItem = this.mediaItem;
    if (!mediaItem) return;

    const isNew = mediaItem.isNew;
    const expiring = isMediaExpiring(mediaItem);

    if (isNew) {
      this._Badge.label = translate('episode.new');
    } else if (expiring) {
      this._Badge.label = getExpiringDateString(mediaItem);
    }
  }

  private updateProgressBar() {
    const mediaItem = this._mediaItem;
    this._ProgressBar.patch({ mediaItem });

    const visible = this._ProgressBar.progressPercent > 0;
    this._ProgressBar.patch({ visible });
  }

  override _handleEnter() {
    this.fireAncestors('$onTileSelected', this._mediaItem, {
      action: 'play',
      imageUrl: this._imageUrl,
    });
  }
}
