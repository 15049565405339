import { AppData } from '@lightningjs/sdk';
import { Episode, Video } from 'types/api/media';
import ProgressBar, {
  ProgressBarTemplateSpec,
} from 'components/common/progressBar/ProgressBar';

export interface MediaProgressBarTemplateSpec extends ProgressBarTemplateSpec {
  mediaItem: Episode | Video | undefined;
}

const DEFAULT_WIDTH = 380;
const DEFAULT_HEIGHT = 10;

export default class MediaProgressBar extends ProgressBar<MediaProgressBarTemplateSpec> {
  override _width = DEFAULT_WIDTH;

  static override get defaultWidth() {
    return DEFAULT_WIDTH;
  }

  static override get defaultHeight() {
    return DEFAULT_HEIGHT;
  }

  set mediaItem(mediaItem: MediaProgressBarTemplateSpec['mediaItem']) {
    if (!mediaItem) {
      this._progressPercent = 0;
      return;
    }

    const { showSlug, guid, durationSecs } = mediaItem;
    const durationSecsParsed = parseInt(durationSecs);

    const progress =
      'progress' in mediaItem
        ? mediaItem.progress
        : AppData?.historyService.getVideoProgress(showSlug, guid);

    this.progressPercent = (progress ?? 0) / durationSecsParsed;
  }
}
