import { translate } from 'support/translate';
import {
  CarouselWithIndexing,
  GridWithIndexing,
  ListWithIndexing,
} from 'components/common/CollectionWrappersWithIndexing';

const setItemsHelper = (items: unknown) => {
  if (Array.isArray(items)) {
    items.forEach((item, index) => {
      if (typeof item === 'object' && item) {
        item.announceContext = translate(
          'ttsPrompts.listInfo',
          index + 1,
          items.length,
        );
      }
    });
  }
};

export class ListWithSpeech extends ListWithIndexing {
  override get items() {
    return super.items;
  }

  override set items(items: any) {
    setItemsHelper(items);
    super.items = items;
  }

  override add(item: any) {
    const addedItem = super.add(item);
    setItemsHelper(this.items);
    return addedItem;
  }
}

export class CarouselWithSpeech extends CarouselWithIndexing {
  override get items() {
    return super.items;
  }

  override set items(items: any) {
    setItemsHelper(items);
    super.items = items;
  }

  override add(item: any) {
    const addedItem = super.add(item);
    setItemsHelper(this.items);
    return addedItem;
  }
}

export class GridWithSpeech extends GridWithIndexing {
  override get items() {
    return super.items;
  }

  override set items(items: any) {
    setItemsHelper(items);
    super.items = items;
  }

  override add(item: any) {
    const addedItem = super.add(item);
    setItemsHelper(this.items);
    return addedItem;
  }
}
