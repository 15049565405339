import { Colors, Lightning } from '@lightningjs/sdk';
import {
  getSeasonEpisodeFormatted,
  isLiveEvent,
  isSeries,
} from 'support/contentUtils';
import { getFontFaceFromStyle } from 'support/textUtils';
import { Episode, LiveEventChannel, Video } from 'types/api/media';

const TITLE_FONT_SIZE = 39;
const METADATA_FONT_SIZE = 29;

export interface VideoDetailsTemplateSpec
  extends Lightning.Component.TemplateSpec {
  mediaContent: Video | LiveEventChannel | null;

  Title: Lightning.textures.TextTexture;
  SubTitle: Lightning.textures.TextTexture;
}

export default class VideoDetails
  extends Lightning.Component<VideoDetailsTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<VideoDetailsTemplateSpec>
{
  private _mediaContent: VideoDetailsTemplateSpec['mediaContent'] = null;

  private _Title = this.getByRef('Title')!;
  private _SubTitle = this.getByRef('SubTitle')!;

  get mediaContent() {
    return this._mediaContent;
  }

  set mediaContent(mediaContent: VideoDetailsTemplateSpec['mediaContent']) {
    this._mediaContent = mediaContent;
    this.updateDetails();
  }

  get announce() {
    return [this._Title.text?.text, this._SubTitle.text?.text];
  }

  static override _template(): Lightning.Component.Template<VideoDetailsTemplateSpec> {
    return {
      flex: { direction: 'column' },
      Title: {
        text: {
          fontFace: getFontFaceFromStyle('black'),
          fontSize: TITLE_FONT_SIZE,
          textColor: Colors('text').get(),
        },
      },
      SubTitle: {
        // Season+Episode or live event subtitle
        text: {
          fontFace: getFontFaceFromStyle('medium'),
          fontSize: METADATA_FONT_SIZE,
          textColor: Colors('text').get(),
        },
      },
    };
  }

  private updateDetails() {
    this._Title.patch({ text: { text: this.mediaContent?.title ?? '' } });

    let description = '';
    if (!this.mediaContent) {
      // No description available
    } else if (isSeries(this.mediaContent)) {
      description = getSeasonEpisodeFormatted(this.mediaContent as Episode);
    } else if (isLiveEvent(this.mediaContent)) {
      description = (this.mediaContent as LiveEventChannel).tuneIn;
    }

    this._SubTitle.patch({ text: { text: description } });
  }
}
