import { Colors, Lightning } from '@lightningjs/sdk';
import {
  millisecondsToMinutes,
  timeTo12HourClockHoursMinutes,
} from 'support/dateUtils';
import { getFontFaceFromStyle } from 'support/textUtils';
import { EpgProgram } from 'types/api/media';
import { constants } from 'aliases';
import { translate } from 'support/translate';
import {
  HoverableComponentSignalMap,
  HoverableComponentTypeConfig,
} from 'components/common/HoverableComponent';
import { HoverableListItem } from 'components/common/HoverableListItem';

const CELL_HEIGHT = constants.ui.epgCellHeight;
const CELL_BORDER_RADIUS = constants.ui.epgBorderRadius;
const CELL_BORDER_WIDTH = 2;
const CELL_PADDING = 15;
const CELL_WIDTH_PER_30_MIN = constants.ui.epgWidthPerThirtyMin;

const TITLE_FONT_SIZE = 23;
const SUBTITLE_FONT_SIZE = 18;

export type EpgCellRowInfo = {
  channelTitle: string;
  index: number;
  rowLength: number;
};

export interface EpgCellTemplateSpec extends Lightning.Component.TemplateSpec {
  epgProgram: EpgProgram | null;
  updateTime: { startTime: Date; currentTime: Date };
  epgCellRowInfo?: EpgCellRowInfo;
  rowSelected: boolean;

  Background: object;
  CompletedSection: object;
  ContentWrapper: {
    Title: Lightning.textures.TextTexture;
    Subtitle: Lightning.textures.TextTexture;
  };
}

interface EpgCellSignalMap extends HoverableComponentSignalMap {
  onSelect(epgProgram: EpgProgram | null): void;
}

interface EpgCellTypeConfig extends HoverableComponentTypeConfig {
  SignalMapType: EpgCellSignalMap;
}

export default class EpgCell
  extends HoverableListItem<EpgCellTemplateSpec, EpgCellTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<EpgCellTemplateSpec>
{
  private _epgProgram: EpgCellTemplateSpec['epgProgram'] = null;
  private _epgCellRowInfo: EpgCellTemplateSpec['epgCellRowInfo'];
  private _rowSelected = false;

  // Internal properties
  private _currentTime?: Date;
  private _startTime?: Date;

  private _Background = this.getByRef('Background')!;
  private _CompletedSection = this.getByRef('CompletedSection')!;
  private _ContentWrapper = this.getByRef('ContentWrapper')!;
  private _Title = this._ContentWrapper.getByRef('Title')!;
  private _Subtitle = this._ContentWrapper.getByRef('Subtitle')!;

  get title() {
    const epgCellRowInfo = [];
    if (this._epgCellRowInfo) {
      const channelTitle = this._epgCellRowInfo.channelTitle;
      epgCellRowInfo.push(
        translate('ttsPrompts.epg.channelTitle', channelTitle),
      );
    }

    if (this.isCurrentlyPlaying())
      epgCellRowInfo.push(
        translate(
          'ttsPrompts.epg.fullscreenCountdown',
          constants.epg.fullScreenTimeoutShow,
        ),
      );

    if (this._epgCellRowInfo) {
      const { index, rowLength } = this._epgCellRowInfo;
      epgCellRowInfo.push(
        translate('ttsPrompts.listInfo', index + 1, rowLength),
      );
    }

    const cellInfo = [
      this._Title.text?.text ?? '',
      this.timeLeft(),
      this._Subtitle.text?.text ?? '',
      this._epgProgram?.description ?? '',
    ];
    const final = [...epgCellRowInfo, ...cellInfo];
    return final;
  }

  get epgProgram() {
    return this._epgProgram;
  }

  set epgProgram(epgProgram: EpgCellTemplateSpec['epgProgram']) {
    this._epgProgram = epgProgram;
    this.updateCellData();
  }

  set epgCellRowInfo(epgCellRowInfo: EpgCellRowInfo) {
    this._epgCellRowInfo = epgCellRowInfo;
  }

  set rowSelected(isSelected: EpgCellTemplateSpec['rowSelected']) {
    this._rowSelected = isSelected;
  }

  set updateTime(time: EpgCellTemplateSpec['updateTime']) {
    const { startTime, currentTime } = time;

    if (this._startTime?.getTime() !== startTime.getTime()) {
      this._startTime = startTime;
      this.updateCellSizing();
    }

    this._currentTime = currentTime;
    this.updateCellShading();
  }

  static override _template(): Lightning.Component.Template<EpgCellTemplateSpec> {
    return {
      clipping: true,
      rtt: true,
      shader: {
        type: Lightning.shaders.RoundedRectangle,
        radius: CELL_BORDER_RADIUS,
      },
      Background: {
        w: (w: number) => w,
        h: CELL_HEIGHT,
        rect: true,
      },
      CompletedSection: {
        h: CELL_HEIGHT,
        rect: true,
        color: Colors('buttonInactive').alpha(0.5).get(),
      },
      ContentWrapper: {
        h: h => h - 2 * CELL_PADDING,
        flex: { direction: 'column', padding: CELL_PADDING },
        Title: {
          text: {
            fontSize: TITLE_FONT_SIZE,
            fontFace: getFontFaceFromStyle('bold'),
            maxLines: 1,
          },
        },
        Subtitle: {
          text: {
            fontSize: SUBTITLE_FONT_SIZE,
            fontFace: getFontFaceFromStyle('regular'),
            textColor: Colors('text').alpha(0.65).get(),
            maxLines: 1,
          },
        },
      },
    };
  }

  override _focus() {
    this.shader = {
      type: Lightning.shaders.RoundedRectangle,
      radius: CELL_BORDER_RADIUS,
      stroke: CELL_BORDER_WIDTH,
      strokeColor: Colors('highlight').get(),
    };
  }

  override _unfocus() {
    this.shader = {
      type: Lightning.shaders.RoundedRectangle,
      radius: CELL_BORDER_RADIUS,
    };
  }

  override _handleEnter() {
    this.fireAncestors('$onProgramSelect', this.epgProgram);
  }

  private getCellWidth(startTimeMs: number, endTimeMs: number) {
    if (!this._startTime) return 0;
    const timelineStart = this._startTime.getTime();

    let duration = endTimeMs - startTimeMs;
    if (startTimeMs < timelineStart && endTimeMs > timelineStart) {
      duration = endTimeMs - timelineStart;
    }

    duration = millisecondsToMinutes(duration);
    // Assumes the width of the cell will be greater than the cell spacing
    const cellWidth =
      (CELL_WIDTH_PER_30_MIN / 30) * duration - constants.ui.epgSpacing;

    // Cells should always be at least 1px width
    return Math.max(cellWidth, 1);
  }

  private getCompletedSectionWidth(startTimeMs: number, endTimeMs: number) {
    if (!this._currentTime || !this._startTime) return 0;
    const timelineStart = this._startTime.getTime();
    const now = this._currentTime.getTime();

    const expiryStartTime = Math.max(timelineStart, startTimeMs);
    const expiryEndTime = Math.min(now, endTimeMs);

    const expiredDurationMs = expiryEndTime - expiryStartTime;
    const expiredDurationMinutes = millisecondsToMinutes(expiredDurationMs);

    return (CELL_WIDTH_PER_30_MIN * expiredDurationMinutes) / 30;
  }

  private updateCellData() {
    if (!this.epgProgram) return;
    const { title, subtitle } = this.epgProgram;

    this._Title.patch({ text: { text: title } });
    this._Subtitle.patch({ text: { text: subtitle } });
  }

  private updateCellSizing() {
    if (!this.epgProgram) return;
    const { startTime, endTime } = this.epgProgram;
    const startTimeMs = new Date(startTime).getTime();
    const endTimeMs = new Date(endTime).getTime();

    const fullWidth = this.getCellWidth(startTimeMs, endTimeMs);
    this.w = fullWidth;
    const wordWrapWidth = Math.max(
      fullWidth,
      CELL_WIDTH_PER_30_MIN - 2 * CELL_PADDING,
    );

    this._Title.patch({ text: { wordWrapWidth } });
    this._Subtitle.patch({ text: { wordWrapWidth } });
  }

  private updateCellShading() {
    if (!this.epgProgram || !this._currentTime) return;
    const { startTime, endTime } = this.epgProgram;
    const startTimeMs = new Date(startTime).getTime();
    const endTimeMs = new Date(endTime).getTime();
    const now = this._currentTime.getTime();

    if (startTimeMs > now) {
      // Upcoming program
      this._CompletedSection.w = 0;
      this._Background.color = Colors('buttonInactive').get();
      this.alpha = 1;
    } else if (endTimeMs <= now) {
      // Completed program
      this._CompletedSection.w = 0;
      this._Background.color = Colors('alternateBackground').alpha(0.65).get();
      this.alpha = 0.3;
    } else {
      // Live cell - set width of the portion of cell that has past
      this._CompletedSection.w = this.getCompletedSectionWidth(
        startTimeMs,
        endTimeMs,
      );
      this._Background.color = Colors('alternateBackground').alpha(0.65).get();
      this.alpha = 1;
    }
  }
  private isCurrentlyPlaying() {
    if (!this.epgProgram || !this._currentTime || !this._rowSelected) return;
    const { startTime, endTime } = this.epgProgram;
    const startTimeMs = new Date(startTime).getTime();
    const endTimeMs = new Date(endTime).getTime();
    const now = this._currentTime.getTime();

    return startTimeMs < now && endTimeMs > now;
  }

  private timeLeft(): string {
    if (!this.epgProgram || !this._currentTime) return '';
    const { startTime, endTime } = this.epgProgram;
    const startTimeMs = new Date(startTime).getTime();
    const endTimeMs = new Date(endTime).getTime();
    const now = this._currentTime.getTime();

    if (startTimeMs > now) {
      // Upcoming Program (start time)
      return timeTo12HourClockHoursMinutes(new Date(startTime));
    } else if (endTimeMs <= now) {
      // Completed program (ended time)
      return translate(
        'ttsPrompts.epg.timeEnded',
        timeTo12HourClockHoursMinutes(new Date(endTime)),
      );
    } else {
      //Live cell (time left)
      const timeLeftMs = endTimeMs - now;
      return translate(
        'ttsPrompts.epg.timeLeft',
        Math.ceil(millisecondsToMinutes(timeLeftMs)),
      );
    }
  }
}
