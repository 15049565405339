import { isEpisodeReleased } from 'support/contentUtils';
import { daysToMilliseconds } from 'support/dateUtils';
import { Video } from 'types/api/media';
import { VideoLaneItem } from 'services/cwData';
import { getVideo, getVideos } from 'services/cwService';
import { constants } from 'aliases';
import StorageService from 'services/StorageService';

export type VideoHistory = {
  expireTime: string;
  isComplete: boolean;
  lastModified: number;
  progress: number;
  nextEpisode: NextEpisode | null;
};

export type NextEpisode = {
  guid: string;
  durationSecs: string;
};

type ShowHistory = {
  videos: {
    [guid: string]: VideoHistory;
  };
  lastWatched: string | null;
  lastWatchedDate: number | null; // Date as number
};

export type History = {
  [showSlug: string]: ShowHistory;
};

type ConstructorArgs = {
  storageService: StorageService;
};

export default abstract class AbstractHistoryService {
  protected readonly DAYS_TO_KEEP_HISTORY = constants.watchHistory.daysToKeep;
  protected readonly storageService: StorageService;

  protected history: History | undefined;

  constructor({ storageService }: ConstructorArgs) {
    this.storageService = storageService;
  }

  // Lazily initialize history once and use history class property as source of truth
  // to minimize fetching history from storage
  protected lazyInit(): void {
    if (this.history) return;

    const history = this.storageService.history.get();
    this.history = history ?? {};
    this.purge();
  }

  protected updateHistory(): void {
    if (this.history !== undefined)
      this.storageService.history.set(this.history);
  }

  protected removeVideo(showHistory: ShowHistory, videoGuid: string) {
    const { videos, lastWatched } = showHistory;
    if (!videos[videoGuid]) return;

    delete videos[videoGuid];
    if (lastWatched === videoGuid) {
      showHistory.lastWatched = null;
      showHistory.lastWatchedDate = null;
    }
  }

  protected removeShow(history: History, showSlug: string) {
    if (history[showSlug]) delete history[showSlug];
  }

  /**
   * Iterates through history removing any shows with no saved videos
   * and ensuring the last watched video GUID for each show is correct
   */
  protected cleanupHistory(): void {
    this.lazyInit();

    Object.entries(this.history!).forEach(([showSlug, showHistory]) => {
      const { videos, lastWatched, lastWatchedDate } = showHistory;

      if (!Object.keys(videos).length) {
        delete this.history![showSlug];
      } else if (!lastWatched || !lastWatchedDate) {
        let newLastWatched: string | null = null;
        let newLastWatchedDate = 0;

        Object.entries(videos).forEach(([guid, video]) => {
          const lastModified = video.lastModified!;

          if (newLastWatchedDate < lastModified) {
            newLastWatched = guid;
            newLastWatchedDate = lastModified;
          }
        });

        showHistory.lastWatched = newLastWatched;
        showHistory.lastWatchedDate = newLastWatchedDate;
      }
    });
  }

  protected appendWatchHistoryToVideo(video: Video): Video {
    const { showSlug, guid } = video;
    const videoHistory = this.getVideoHistory(showSlug, guid);
    if (!videoHistory) return video;

    video.progress = videoHistory.progress;
    video.isComplete = videoHistory.isComplete;
    video.lastModified = videoHistory.lastModified;
    return video;
  }

  /**
   * Gets a sorted array of the next or last watched episode GUIDs
   * @param fetchNextEpisode Get next episodes if true. Otherwise, get last watched episode
   * @returns Sorted array of episode GUIDs
   */
  protected getSortedWatchHistoryEpisodeGuids(
    fetchNextEpisode: boolean,
  ): string[] {
    const sortedShowSlugs = this.getSortedHistoryShowSlugs();
    if (sortedShowSlugs.length === 0) return [];

    // Get either the next episode GUID or the last watched episode GUID
    const getEpisodeGuidFn = fetchNextEpisode
      ? this.getShowNextEpisodeGuid.bind(this)
      : this.getShowLastWatchedGuid.bind(this);

    return sortedShowSlugs
      .map(getEpisodeGuidFn)
      .filter(value => !!value) as string[];
  }

  /**
   * Gets a sorted array of videos containing either the next or last watched episodes
   * @param fetchNextEpisode Get next episodes if true. Otherwise, get last watched episode
   * @param start Watch history start index. Default is 0
   * @param end Watch history end index. Default is length of history
   * @returns Sorted array of videos corresponding, sliced from `start` to `end`
   */
  protected async getSortedWatchHistoryVideos(
    fetchNextEpisode: boolean,
    start?: number,
    end?: number,
  ): Promise<Video[]> {
    const episodeGuids =
      this.getSortedWatchHistoryEpisodeGuids(fetchNextEpisode);

    const episodeGuidsLength = episodeGuids.length;
    const startIndex = start === undefined ? 0 : start;
    const endIndex = end === undefined ? episodeGuidsLength : end;

    const videos = await getVideos(episodeGuids.slice(startIndex, endIndex));
    return videos.map(this.appendWatchHistoryToVideo.bind(this));
  }

  protected convertVideoArrayToLaneItem(videos: Video[]) {
    return videos.map((video: Video): VideoLaneItem => {
      return {
        ...video,
        contextType: 'video',
      };
    });
  }

  protected getVideoLastModifiedTime(showSlug: string, guid: string) {
    this.lazyInit();

    const showHistory = this.history![showSlug];
    if (!showHistory) return 0;

    const videoHistoryEntry = Object.entries(showHistory.videos).find(
      ([entryGuid, entryVideoHistory]) => {
        const nextEpisodeGuid = entryVideoHistory.nextEpisode?.guid;
        return entryGuid === guid || nextEpisodeGuid === guid;
      },
    );
    if (!videoHistoryEntry) return 0;

    const [, videoHistory] = videoHistoryEntry;
    return videoHistory.lastModified;
  }

  protected getVideoExpirationTime(showSlug: string, guid: string) {
    this.lazyInit();

    const showHistory = this.history![showSlug];
    if (!showHistory) return 0;

    const videoHistoryEntry = Object.entries(showHistory.videos).find(
      ([entryGuid, entryVideoHistory]) => {
        const nextEpisodeGuid = entryVideoHistory.nextEpisode?.guid;
        return entryGuid === guid || nextEpisodeGuid === guid;
      },
    );
    if (!videoHistoryEntry) return 0;

    const [, videoHistory] = videoHistoryEntry;
    const { expireTime, lastModified } = videoHistory;

    const daysToKeepInMs = daysToMilliseconds(this.DAYS_TO_KEEP_HISTORY);
    const expireDate = new Date(expireTime);
    const lastModifiedDate = new Date(lastModified);

    return Math.min(
      expireDate.getTime(),
      lastModifiedDate.getTime() + daysToKeepInMs,
    );
  }

  protected isVideoExpired(showSlug: string, guid: string) {
    const currentTime = new Date().getTime();
    const expirationTime = this.getVideoExpirationTime(showSlug, guid);

    return currentTime >= expirationTime;
  }

  abstract shouldUpdateHistoryOnBoot(): boolean;

  abstract updateHistoryOnBoot(): Promise<void>;

  add(video: Video, timestamp: number, isComplete: boolean) {
    this.lazyInit();
    const currentDate = new Date().getTime();

    const videoClone = { ...video };
    videoClone.lastModified = currentDate;
    videoClone.isComplete = isComplete;
    videoClone.progress = isComplete ? 0 : timestamp; // reset progress is saved content is completed

    const showSlug = videoClone.showSlug;
    const show = this.history![showSlug] ?? {
      videos: {},
      lastWatched: null,
      lastWatchedDate: null,
    };

    show.videos[videoClone.guid] = {
      expireTime: videoClone.expireTime,
      isComplete: videoClone.isComplete === true,
      lastModified: videoClone.lastModified,
      progress: videoClone.progress,
      nextEpisode: videoClone.nextEpisode
        ? {
            guid: videoClone.nextEpisode.guid,
            durationSecs: videoClone.nextEpisode.durationSecs,
          }
        : null,
    };
    show.lastWatched = videoClone.guid;
    show.lastWatchedDate = currentDate;

    // If the content is complete and we have progress in the next episode,
    // clear the next episode's progress as well
    if (isComplete) {
      const nextEpisodeGuid = videoClone.nextEpisode?.guid;
      if (nextEpisodeGuid && show.videos[nextEpisodeGuid]) {
        show.videos[nextEpisodeGuid]!.progress = 0;
      }
    }

    this.history![showSlug] = show;
    this.updateHistory();
  }

  /**
   * Removes shows from history using array showSlugs
   * @param items showSlugs we want to remove from history
   */
  deleteShowHistoryWithSlugs(items: string[]): void {
    this.lazyInit();

    items.forEach(showSlug => {
      if (showSlug) this.removeShow(this.history!, showSlug);
    });

    this.cleanupHistory();
    this.updateHistory();
  }

  /**
   * Purges history of any expired content. Content can expire in two ways:
   * 1. Content expired from CW's catalogue
   * 2. Content history was saved too long ago
   */
  purge(): void {
    this.lazyInit();

    Object.entries(this.history!).forEach(([showSlug, showHistory]) => {
      const { videos } = showHistory;

      Object.keys(videos).forEach(guid => {
        if (this.isVideoExpired(showSlug, guid)) {
          this.removeVideo(showHistory, guid);
        }
      });
    });

    this.cleanupHistory();
    this.updateHistory();
  }

  isWatchHistoryEmpty(): boolean {
    this.lazyInit();

    const showSlugs = Object.keys(this.history ?? []);
    const isHistoryEmpty = showSlugs.length === 0;

    if (isHistoryEmpty) return isHistoryEmpty;

    for (let i = 0; i < showSlugs.length; i += 1) {
      const showSlug = showSlugs[i]!;

      const slug = this.getShowNextEpisodeGuid(showSlug);
      if (slug !== undefined) return false;
    }

    return true;
  }

  /**
   * Gets history for a specific episode if it exists. Note that this does not get
   * the history for next episodes
   *
   * @param showSlug The show's identifier
   * @param guid The episode's identifier
   * @returns The video history for an episode if it exists. Otherwise, undefined
   */
  getVideoHistory(showSlug: string, guid: string): VideoHistory | undefined {
    this.lazyInit();

    const showHistory = this.history![showSlug];
    return showHistory?.videos[guid];
  }

  getVideoProgress(showSlug: string, guid: string): number {
    const videoHistory = this.getVideoHistory(showSlug, guid);
    return videoHistory?.progress ?? 0;
  }

  async getShowLastWatchedVideo(showSlug: string): Promise<Video | undefined> {
    const lastWatchedGuid = this.getShowLastWatchedGuid(showSlug);
    if (!lastWatchedGuid) return undefined;

    try {
      const lastWatchedVideo = await getVideo(lastWatchedGuid);
      return lastWatchedVideo
        ? this.appendWatchHistoryToVideo(lastWatchedVideo)
        : undefined;
    } catch (e) {
      console.warn(e);
      return undefined;
    }
  }

  getShowLastWatchedGuid(showSlug: string): string | undefined {
    this.lazyInit();

    const showHistory = this.history![showSlug];
    const lastWatchedGuid = showHistory?.lastWatched;
    if (!lastWatchedGuid) return undefined;
    return lastWatchedGuid;
  }

  async getShowNextEpisode(showSlug: string): Promise<Video | undefined> {
    const nextEpisodeGuid = this.getShowNextEpisodeGuid(showSlug);
    if (!nextEpisodeGuid) return undefined;

    try {
      const nextEpisode = await getVideo(nextEpisodeGuid);
      return nextEpisode
        ? this.appendWatchHistoryToVideo(nextEpisode)
        : undefined;
    } catch (e) {
      return undefined;
    }
  }

  getShowNextEpisodeGuid(showSlug: string): string | undefined {
    const lastWatchedGuid = this.getShowLastWatchedGuid(showSlug);
    if (!lastWatchedGuid) return undefined;

    const lastWatchedVideoHistory = this.getVideoHistory(
      showSlug,
      lastWatchedGuid,
    );
    if (!lastWatchedVideoHistory) return undefined;

    const { isComplete, nextEpisode } = lastWatchedVideoHistory;
    const isNextEpisodeReleased = isEpisodeReleased(nextEpisode);

    if (isComplete && isNextEpisodeReleased) {
      return nextEpisode!.guid;
    } else if (isComplete && !isNextEpisodeReleased) {
      return undefined;
    } else {
      return lastWatchedGuid;
    }
  }

  private validateHistoryVideos(videos: Video[]): Video[] {
    const validatedVideos: Video[] = [];
    for (const video of videos) {
      if (video.title !== undefined) {
        validatedVideos.push(video);
      } else {
        // remove from history
        Object.values(this.history!).forEach(showHistory => {
          if (showHistory.videos[video.guid]) {
            this.removeVideo(showHistory, video.guid);
          }
        });
        this.cleanupHistory();
        this.updateHistory();
      }
    }
    return validatedVideos;
  }

  async getWatchHistoryItems(
    page?: number,
    pageSize?: number,
  ): Promise<VideoLaneItem[]> {
    let validatedVideos: Video[];
    if (page && pageSize) {
      const startIndex = pageSize * (page - 1);
      const endIndex = startIndex + pageSize;
      const videos = await this.getSortedWatchHistoryVideos(
        false,
        startIndex,
        endIndex,
      );

      validatedVideos = this.validateHistoryVideos(videos);
    } else {
      const videos = await this.getSortedWatchHistoryVideos(false);
      validatedVideos = this.validateHistoryVideos(videos);
    }
    return this.convertVideoArrayToLaneItem(validatedVideos);
  }

  async getContinueLaneItems(
    page: number,
    pageSize: number,
  ): Promise<VideoLaneItem[]> {
    const startIndex = pageSize * (page - 1);
    const endIndex = startIndex + pageSize;
    const videos = await this.getSortedWatchHistoryVideos(
      true,
      startIndex,
      endIndex,
    );
    const validatedVideos = this.validateHistoryVideos(videos);

    return this.convertVideoArrayToLaneItem(validatedVideos);
  }

  getSortedHistoryShowSlugs(): string[] {
    this.lazyInit();
    const history = this.history!;

    return Object.keys(history).sort((ShowSlugA: string, ShowSlugB: string) => {
      const showAWatchDate = history[ShowSlugA]!.lastWatchedDate!;
      const showBWatchDate = history[ShowSlugB]!.lastWatchedDate!;

      // Sorts shows in descending order according to each show's last watched date
      return showAWatchDate < showBWatchDate ? 1 : -1;
    });
  }
}
