import { Colors, Lightning, Router } from '@lightningjs/sdk';
import Button from 'components/common/Button';
import { getFontFaceFromStyle } from 'support/textUtils';
import ActionsList from 'components/common/ActionsList';

const TITLE_FONT_SIZE = 63;
const DEFAULT_ACTIONS_TOP_MARGIN = 90;
const MIN_BUTTON_WIDTH = 351;
const DEFAULT_BUTTON_PADDING = 18;
const DEFAULT_BUTTON_PADDINGH = 84;
const DEFAULT_OPACITY = 1;
const TITLE_WRAP_WIDTH = 1840;

const CONFIRM = '_confirm';
const CANCEL = '_cancel';

export type ModalProps = Partial<
  Pick<
    ModalTemplateSpec,
    | 'title'
    | 'confirmLabel'
    | 'cancelLabel'
    | 'onConfirm'
    | 'onCancel'
    | 'onBack'
    | 'bgAlpha'
    | 'bgColor'
  >
>;

export interface ModalTemplateSpec extends Lightning.Component.TemplateSpec {
  title: string;
  confirmLabel: string;
  cancelLabel: string;
  bgAlpha: number;
  bgColor: 'modalBackground' | 'black';
  onConfirm: (() => void) | null;
  onCancel: (() => void) | null;
  onBack: (() => void) | null;
  Title: typeof Text;
  Actions: typeof ActionsList;
}

export default class Modal
  extends Lightning.Component<ModalTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<ModalTemplateSpec>
{
  protected _confirmLabel = '';
  protected _cancelLabel = '';
  protected _onConfirm: ModalTemplateSpec['onConfirm'] = null;
  protected _onCancel: ModalTemplateSpec['onCancel'] = null;
  protected _onBack: ModalTemplateSpec['onBack'] = null;
  protected _bgAlpha = 1;
  protected _bgColor = 'modalBackground';
  protected _close = false;

  protected _Title = this.getByRef('Title')!;
  protected _Actions = this.getByRef('Actions')!;

  public static construct(modal: Modal, props: ModalProps) {
    const {
      title = '',
      confirmLabel = '',
      cancelLabel = '',
      bgAlpha = DEFAULT_OPACITY,
      bgColor = 'modalBackground',
      onConfirm = null,
      onCancel = null,
      onBack = null,
    } = props;

    modal.title = title;
    modal.bgAlpha = bgAlpha;
    modal.bgColor = bgColor;
    modal.confirmLabel = confirmLabel;
    modal.cancelLabel = cancelLabel;
    modal.onConfirm = onConfirm;
    modal.onCancel = onCancel;
    modal.onBack = onBack;
  }

  get title() {
    return this._Title.text?.text ?? '';
  }

  set title(title: ModalTemplateSpec['title']) {
    this._Title.patch({ text: { text: title } });
  }

  get confirmLabel() {
    return this._confirmLabel;
  }

  set confirmLabel(confirmLabel: ModalTemplateSpec['confirmLabel']) {
    this._confirmLabel = confirmLabel;
    this.updateActions();
  }

  get cancelLabel() {
    return this._cancelLabel;
  }

  set cancelLabel(cancelLabel: ModalTemplateSpec['cancelLabel']) {
    this._cancelLabel = cancelLabel;
    this.updateActions();
  }

  get onConfirm() {
    return this._onConfirm;
  }

  set onConfirm(onConfirm: ModalTemplateSpec['onConfirm']) {
    this._onConfirm = onConfirm;
  }

  get onCancel() {
    return this._onCancel;
  }

  set onCancel(onCancel: ModalTemplateSpec['onCancel']) {
    this._onCancel = onCancel;
  }

  get onBack() {
    return this._onBack;
  }

  set onBack(onBack: ModalTemplateSpec['onBack']) {
    this._onBack = onBack;
  }

  set bgAlpha(value: ModalTemplateSpec['bgAlpha']) {
    this._bgAlpha = value;
    this.setBgColor();
  }

  set bgColor(value: ModalTemplateSpec['bgColor']) {
    this._bgColor = value;
    this.setBgColor();
  }

  static override _template(): Lightning.Component.Template<ModalTemplateSpec> {
    return {
      w: 1920,
      h: 1080,
      rect: true,
      color: Colors('modalBackground').get(),
      flex: {
        direction: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
      Title: {
        text: {
          fontSize: TITLE_FONT_SIZE,
          fontFace: getFontFaceFromStyle('bold'),
          textAlign: 'center',
          wordWrapWidth: TITLE_WRAP_WIDTH,
        },
      },
      Actions: {
        type: ActionsList,
        flexItem: { marginTop: DEFAULT_ACTIONS_TOP_MARGIN },
        signals: { [CONFIRM]: true, [CANCEL]: true },
      },
      alpha: 0,
      zIndex: 2,
    };
  }

  override _getFocused() {
    return this._Actions;
  }

  override _focus() {
    this.alpha = 1;
    this._close = false;
  }

  override _unfocus() {
    if (!this._close) {
      Router.focusWidget(`${this.ref}` as keyof Router.CustomWidgets);
    }
  }

  override _handleBack() {
    this._onBack?.();
    this.close();
  }

  private updateActions() {
    this._Actions.clearActions();

    if (this.confirmLabel) {
      this._Actions.addAction(this.buildButton(this.confirmLabel, CONFIRM));
    }

    if (this.cancelLabel) {
      this._Actions.addAction(this.buildButton(this.cancelLabel, CANCEL));
    }

    if (this.active) {
      this._Actions._refocus();
    }
  }

  protected close() {
    this._close = true;
    this.alpha = 0;
    Router.focusPage();
  }

  private _confirm() {
    this.onConfirm?.();
    this.close();
  }

  private _cancel() {
    this.onCancel?.();
    this.close();
  }

  private buildButton(label: string, action: string) {
    return {
      type: Button,
      label,
      action,
      minWidth: MIN_BUTTON_WIDTH,
      padding: DEFAULT_BUTTON_PADDING,
      paddingH: DEFAULT_BUTTON_PADDINGH,
      passSignals: { [action]: true },
      signals: {
        $onHover: '$onHover',
      },
      zIndex: 2,
    };
  }

  private setBgColor() {
    this.color = Colors(this._bgColor).alpha(this._bgAlpha).get();
  }
}
