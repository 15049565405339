import { Lightning, Colors, Router, AppData } from '@lightningjs/sdk';

import { getImageTextureObj } from 'support/generalUtils';
import NavBarTab, { NavBarTabTemplateSpec } from 'components/widgets/NavBarTab';
import { PageId } from 'types/pageId';
import { translate } from 'support/translate';
import { ListWithSpeech } from 'components/common/CollectionWrappersWithSpeech';
import { constants } from 'aliases';
import { FromRoute } from 'config/routes';
import {
  getRoutePathFromPageId,
  navigateToContentHub,
} from 'support/routerUtils';
import Page from 'components/Page';
import ContentHubPage from 'components/pages/content-hub/ContentHubPage';
import { ContentHubSlugs } from 'types/contentHubs';
import { reportNavBarNavigation } from 'services/analytics/reportingServicePage';
import { HoverableComponent } from 'components/common/HoverableComponent';

export interface NavBarTemplateSpec extends Lightning.Component.TemplateSpec {
  Logo: Lightning.Texture;
  Menu: typeof ListWithSpeech;
}

const NAVBAR_COLLAPSED_WIDTH = constants.sizing.navbar.collapsedWidth;
const NAVBAR_EXPANDED_WIDTH = constants.sizing.navbar.expandedWidth;

const ICON_BASE_PATH = 'static/images/navigation/';
const ACTIVE_ICON_EXTENSION = '-icon-active.svg';
const INACTIVE_ICON_EXTENSION = '-icon-inactive.svg';

const LOGO_WIDTH = 80;
const LOGO_HEIGHT = 33;
const LOGO_Y = 60;

const SETTINGS_SLUG = 'settings';
const SETTINGS_TITLE = 'Settings';
const SETTINGS_TAB_Y_POSITION = 936;

const TAB_SPACING = 10;
const TAB_HEIGHT = 70;

const LOGO_TRANSITION = {
  x: {
    duration: 0.3,
    timingFunction: 'ease-out',
  },
} as const;

export default class NavBar
  extends HoverableComponent<NavBarTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<NavBarTemplateSpec>
{
  private _Logo = this.getByRef('Logo')!;
  private _Menu = this.getByRef('Menu')!;

  private static _shouldUpdateTabs = false;

  get announce() {
    return translate('ttsPrompts.navbar');
  }

  static updateTabs() {
    NavBar._shouldUpdateTabs = true;
  }

  static override _template(): Lightning.Component.Template<NavBarTemplateSpec> {
    const logoTexture = getImageTextureObj(
      'static/images/cw-icon.svg',
      LOGO_WIDTH,
      LOGO_HEIGHT,
    );

    return {
      rect: true,
      zIndex: 1,
      w: NAVBAR_COLLAPSED_WIDTH,
      h: 1080,
      color: Colors('background').get(),
      Logo: {
        ...logoTexture,
        transitions: LOGO_TRANSITION,
        mountX: 0.5,
        x: NAVBAR_COLLAPSED_WIDTH / 2,
        y: LOGO_Y,
      },
      Menu: {
        type: ListWithSpeech,
        itemType: NavBarTab,
        direction: 'column',
        spacing: TAB_SPACING,
      },
    };
  }

  override _onActivated(page: Router.PageInstance) {
    if (NavBar._shouldUpdateTabs) this.updateNavBarTabs();
    this.updateSelectedTabFromPage(page as Page);
  }

  override _getFocused() {
    return this._Menu;
  }

  override _focus() {
    this.handleFocusUpdate(true, NAVBAR_EXPANDED_WIDTH);
  }

  override _unfocus() {
    this.handleFocusUpdate(false, NAVBAR_COLLAPSED_WIDTH);
  }

  override _handleBack() {
    AppData?.device.beforeAppClose(showPopUp => {
      if (showPopUp) Router.focusWidget('ExitModal');
      else AppData?.device.closeApp();
    });
  }

  override _handleKey(e: KeyboardEvent) {
    switch (e.key) {
      case 'ArrowRight':
      case 'Escape':
        return false;
    }

    // When the handle key event propagates up, it causes the navbar to close. However,
    // we only want the navbar to close to specific key presses defined above. Otherwise,
    // we will stay focused on the navbar
    return true;
  }

  override _handleEnter() {
    this.handleItemSelected();
    return true;
  }

  override _handleHover(target: any) {
    if (this.alpha < 1 || AppData?.device.hoverDisabled()) return true;
    Router.focusWidget('NavBar');
    return false;
  }

  override _handleUnhover() {
    if (this.alpha < 1 || AppData?.device.hoverDisabled()) return true;
    Router.focusPage();
    return false;
  }

  override _handleClick() {
    if (this.alpha < 1) return;
    this.handleItemSelected(true);
  }

  private updateNavBarTabs() {
    const tabs = this.buildNavBarTabsUsingTabData();

    const menuHeight =
      tabs.length * TAB_HEIGHT + (tabs.length - 1) * TAB_SPACING;
    const menuY = 540 - menuHeight / 2;

    // Note that the settings tab will be the only tab that will need to be hard coded,
    // and will remain at the same position on page regardless of menu height
    const settingsTabData = this.getSettingsTabData();
    const settingsTab = this.buildNavBarTab(settingsTabData);

    settingsTab.y =
      SETTINGS_TAB_Y_POSITION - (menuY + menuHeight + TAB_SPACING);
    tabs.push(settingsTab);

    this._Menu.patch({ items: tabs, y: menuY });
    NavBar._shouldUpdateTabs = false;
  }

  private buildNavBarTabsUsingTabData() {
    return AppData!
      .navigationItems!.map(item => {
        const { slug } = item;
        const navbarData = this.getTabDataFromSlug(slug);
        if (navbarData) return this.buildNavBarTab(navbarData);
      })
      .filter(
        tab => !!tab,
      ) as Lightning.Element.PatchTemplate<NavBarTabTemplateSpec>[];
  }

  private buildNavBarTab(props: {
    icons: { active: string; inactive: string };
    slug: string;
    title: string;
  }): Lightning.Element.PatchTemplate<NavBarTabTemplateSpec> {
    return {
      w: NAVBAR_COLLAPSED_WIDTH,
      h: TAB_HEIGHT,
      icons: props.icons,
      slug: props.slug,
      title: props.title,
    };
  }

  private updateSelectedTabFromSlug(slug: string) {
    this._Menu.items.forEach((tab: NavBarTab, index: number) => {
      const selected = slug === tab.slug;

      tab.selected = selected;
      if (selected) this._Menu.index = index;
    });
  }

  private updateSelectedTabFromPage(page: Page) {
    const pageId = page.pageId;

    this._Menu.items.forEach((tab: NavBarTab, index: number) => {
      const { slug } = tab;
      const { pageId: tabPageId } = this.getTabDataFromSlug(slug)!;

      let selected = false;
      if (pageId === PageId.CONTENT_HUB) {
        selected = (page as ContentHubPage).hubSlug === slug;
      } else {
        selected = pageId === tabPageId;
      }

      tab.selected = selected;
      if (selected) this._Menu.index = index;
    });
  }

  private handleFocusUpdate(showTitle: boolean, width: number) {
    requestAnimationFrame(() => {
      this._Menu.items.forEach((tab: NavBarTab, index: number) => {
        tab.showTitle = showTitle;
        tab.setSmooth('w', width);

        if (index + 1 === this._Menu.items.length) {
          this._Logo.setSmooth('x', width / 2);
          this.setSmooth('w', width);
        }
      });
    });
  }

  private handleItemSelected(click = false) {
    if (click && !this._Menu.currentItem.hovered) return;
    const { slug } = this._Menu.currentItem as NavBarTab;
    const { pageId, route, title } = this.getTabDataFromSlug(slug)!;
    const from = FromRoute.NAVBAR;
    this.updateSelectedTabFromSlug(slug);
    reportNavBarNavigation(title);

    if (pageId === PageId.CONTENT_HUB) {
      navigateToContentHub(slug, { from });
    } else {
      Router.navigate(route, { from });
    }
    Router.focusPage();
  }

  private getTabDataFromSlug(slug: string) {
    if (slug === SETTINGS_SLUG) return this.getSettingsTabData();

    const tabData = AppData!.navigationItems!.find(item => item.slug === slug);
    if (!tabData) return null;

    const pageId = this.getPageIdFromNavbarItemType(tabData.type);
    if (!pageId) return null;

    const title = tabData.title;
    const route = getRoutePathFromPageId(pageId, slug);
    const defaultIcons = this.getDefaultIconsFromNavbarSlug(slug);
    const icons = {
      active: tabData.icons.svg_active ?? defaultIcons.active,
      inactive: tabData.icons.svg_inactive ?? defaultIcons.inactive,
    };
    return { icons, pageId, route, slug, title };
  }

  private getSettingsTabData() {
    return {
      icons: this.buildDefaultIconPaths(SETTINGS_SLUG),
      pageId: PageId.SETTINGS,
      route: getRoutePathFromPageId(PageId.SETTINGS),
      slug: SETTINGS_SLUG,
      title: SETTINGS_TITLE,
    };
  }

  private getPageIdFromNavbarItemType(type: string): PageId | null {
    switch (type) {
      case 'channels':
        return PageId.EPG;
      case 'content-hub':
        return PageId.CONTENT_HUB;
      case 'live':
        return PageId.LIVE;
      case 'search':
        return PageId.SEARCH;
      default:
        return null;
    }
  }

  private getDefaultIconsFromNavbarSlug(slug: string) {
    switch (slug) {
      case 'channels':
        return this.buildDefaultIconPaths('channels');
      case 'Live':
        return this.buildDefaultIconPaths('live');
      case 'search':
        return this.buildDefaultIconPaths('search');
      case ContentHubSlugs.HOME:
        return this.buildDefaultIconPaths('home');
      case ContentHubSlugs.MOVIES:
        return this.buildDefaultIconPaths('movies');
      case ContentHubSlugs.SERIES:
        return this.buildDefaultIconPaths('shows');
      case ContentHubSlugs.SPORTS:
        return this.buildDefaultIconPaths('sports');
      default:
        return this.buildDefaultIconPaths('special-event');
    }
  }

  private buildDefaultIconPaths(slug: string) {
    return {
      active: `${ICON_BASE_PATH}${slug}${ACTIVE_ICON_EXTENSION}`,
      inactive: `${ICON_BASE_PATH}${slug}${INACTIVE_ICON_EXTENSION}`,
    };
  }
}
