import { Colors, Lightning, Registry } from '@lightningjs/sdk';
import { translate } from 'support/translate';
import LoadingSpinner from 'components/common/LoadingSpinner';
import Scrollable from 'components/common/Scrollable';
import { getFontFaceFromStyle } from 'support/textUtils';
import { SETTING_SECTIONS } from 'components/pages/settings/SettingsSection';
import { LongTextAnnouncerService } from 'services/LongTextAnnouncerService';

// We use Extract here for type safety
type TextSections = Extract<
  (typeof SETTING_SECTIONS)[number],
  'TermsOfUse' | 'PrivacyPolicy' | 'NielsenMeasurement'
>;

export interface TextSectionTemplateSpec
  extends Lightning.Component.TemplateSpec {
  sectionId: TextSections | null;
  fetchTextData(): Promise<string>;
  SectionTitle: Lightning.Element;
  TextContent: typeof Scrollable;
  LoadingSpinner: typeof LoadingSpinner;
}

const FETCH_DELAY = 1000;

const TITLE_X_PADDING = 20;
const TITLE_Y_PADDING = 16;
const TEXT_CONTENT_Y_OFFSET = 71;
const TEXT_CONTENT_WIDTH = 808;
const TEXT_CONTENT_HEIGHT = 849;
const TEXT_CONTENT_WORD_WRAP = 768;

export default class TextSection
  extends Lightning.Component<TextSectionTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<TextSectionTemplateSpec>
{
  sectionId: TextSectionTemplateSpec['sectionId'] = null;
  fetchTextData: TextSectionTemplateSpec['fetchTextData'] = async () => '';

  private _SectionTitle = this.getByRef('SectionTitle')!;
  private _TextContent = this.getByRef('TextContent')!;
  private _LoadingSpinner = this.getByRef('LoadingSpinner')!;

  private textData: string | null = null;
  private _fetchTimeout: number | null = null;

  private textAnnouncer: LongTextAnnouncerService | null = null;

  get announce() {
    if (this.textAnnouncer) {
      this.textAnnouncer.startReading();
      return;
    }

    const title = translate(`settings.${this.sectionId}.title`);
    return [title, translate('ttsPrompts.contentLoading')];
  }

  static override _template(): Lightning.Component.Template<TextSectionTemplateSpec> {
    return {
      SectionTitle: {
        x: TITLE_X_PADDING,
        y: TITLE_Y_PADDING,
        text: { fontSize: 32, fontFace: getFontFaceFromStyle('regular') },
      },
      TextContent: {
        y: TEXT_CONTENT_Y_OFFSET,
        type: Scrollable,
        w: TEXT_CONTENT_WIDTH,
        h: TEXT_CONTENT_HEIGHT,
        backgroundAlpha: { focused: 0.15 },
      },
      LoadingSpinner: {
        type: LoadingSpinner,
        mount: 0.5,
        x: TEXT_CONTENT_WIDTH / 2,
        y: TEXT_CONTENT_HEIGHT / 2 + TEXT_CONTENT_Y_OFFSET,
        show: false,
      },
    };
  }

  override _setup() {
    const titleText = translate(`settings.${this.sectionId}.title`);

    this._SectionTitle.patch({
      text: {
        text: titleText,
      },
    });
    this.renderTextContent();
  }

  override _active() {
    this.handleTextData();
  }

  override _disable() {
    if (this._fetchTimeout !== null) {
      Registry.clearTimeout(this._fetchTimeout);
      this._fetchTimeout = null;
    }
  }

  override _getFocused() {
    return this._TextContent;
  }

  private renderTextContent() {
    if (this.textData) {
      /*
      we split the text into an array with \n being the delimiter
      if multiple \n are together, we will make the array element the \n's after the first
      for example the string "foo\nbar\n\n\nfizz\n\nbuzz" would become the array:
      ["foo", "bar", "\n\n", "fizz", "\n", "buzz"]
      */
      const textArray = this.textData
        .split(/(\n+)/)
        .map(text => {
          if (text.startsWith('\n')) {
            return text.slice(1); // remove the first \n
          } else {
            return text;
          }
        })
        .filter(text => !!text);

      const content: any = {
        flex: { direction: 'column' },
      };

      textArray.forEach((text: string, i: number) => {
        content[`T${i}`] = {
          text: {
            // we slice \n's again to account for the two line breaks the component inserts above and below each text component
            text: text.startsWith('\n') ? text.slice(1) : text,
            wordWrap: true,
            wordWrapWidth: TEXT_CONTENT_WORD_WRAP,
            fontSize: 20,
            fontFace: getFontFaceFromStyle('regular'),
            textColor: Colors('text').get(),
          },
        };
      });

      this._TextContent.content = content;
    }
  }

  private setIsLoading(isLoading: boolean) {
    this._LoadingSpinner.patch({ show: isLoading });
  }

  override _unfocus() {
    this.textAnnouncer?.stopReading();
  }

  private handleTextData() {
    if (this.textData !== null) {
      this.textAnnouncer?.startReading();
      return;
    }

    if (this._fetchTimeout !== null) {
      Registry.clearTimeout(this._fetchTimeout);
    }

    this.setIsLoading(true);

    this._fetchTimeout = Registry.setTimeout(async () => {
      if (this.active) {
        this.textData = await this.fetchTextData();
        this.renderTextContent();
        this.setIsLoading(false);
        const title = translate(`settings.${this.sectionId}.title`);

        this.textAnnouncer = new LongTextAnnouncerService(
          this,
          this.textData,
          title,
        );
        if (this.hasFocus()) {
          this.textAnnouncer.startReading();
        }
      }

      this._fetchTimeout = null;
    }, FETCH_DELAY);
  }
}
