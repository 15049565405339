import { Colors, Lightning, Registry } from '@lightningjs/sdk';
import ChannelBadge from 'components/common/ChannelBadge';
import OnDemandBadge from 'components/common/OnDemandBadge';
import RatingBadge from 'components/common/RatingBadge';
import { getCurrentEpgProgram, isProgramPlaying } from 'support/contentUtils';
import {
  MILLISECONDS_IN_SECOND,
  millisecondsToContentDuration,
  timeTo12HourClockHoursMinutes,
} from 'support/dateUtils';
import { getFontFaceFromStyle } from 'support/textUtils';
import { translate } from 'support/translate';
import { EpgChannel, EpgProgram } from 'types/api/media';
import { constants } from 'aliases';

export interface EpgHeroTemplateSpec extends Lightning.Component.TemplateSpec {
  channel?: EpgChannel;
  program?: EpgProgram;
  miniplayerHoleShader: Lightning.types.Shader.SettingsLoose;

  Background: Lightning.textures.ImageTexture;
  FullscreenIndicator: Lightning.textures.TextTexture;
  Content: {
    ChannelBadge: typeof ChannelBadge;
    TitleContainer: {
      Logo: Lightning.textures.ImageTexture;
      Title: Lightning.textures.TextTexture;
    };
    Subtitle: Lightning.textures.TextTexture;
    Metadata: {
      Duration: Lightning.textures.TextTexture;
      RatingBadge: typeof RatingBadge;
      OnDemand: typeof OnDemandBadge;
    };
    Description: Lightning.textures.TextTexture;
  };
}

interface EpgHeroSignalMap extends Lightning.Component.SignalMap {
  expandChannelVideo(channel: EpgChannel): void;
}

interface EpgHeroTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: EpgHeroSignalMap;
}

const EPG_HERO_WIDTH = 1758;
const EPG_HERO_HEIGHT = 478;

const CONTENT_X = 71;
const CONTENT_Y = 60;

const LOGO_X = 8;
const LOGO_WIDTH = 349;
const LOGO_HEIGHT = 142;

const TEXT_MAX_WIDTH = 760;
const DESCRIPTION_MAX_WIDTH = 600;
const DEFAULT_FONT_SIZE = 18;

const TOTAL_FULLSCREEN_TIMEOUT_SECONDS = 10;

export default class EpgHero
  extends Lightning.Component<EpgHeroTemplateSpec, EpgHeroTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<EpgHeroTemplateSpec>
{
  private _channel: EpgHeroTemplateSpec['channel'];
  private _program: EpgHeroTemplateSpec['program'];

  // Internal properties
  private _fullscreenIntervalId?: number;
  private _fullscreenTimeoutSeconds = TOTAL_FULLSCREEN_TIMEOUT_SECONDS;

  private _Background = this.getByRef('Background')!;
  private _FullscreenIndicator = this.getByRef('FullscreenIndicator')!;
  private _Content = this.getByRef('Content')!;

  private _ChannelBadge = this._Content.getByRef('ChannelBadge')!;
  private _TitleContainer = this._Content.getByRef('TitleContainer')!;
  private _Subtitle = this._Content.getByRef('Subtitle')!;
  private _Description = this._Content.getByRef('Description')!;
  private _Metadata = this._Content.getByRef('Metadata')!;

  private _Logo = this._TitleContainer.getByRef('Logo')!;
  private _Title = this._TitleContainer.getByRef('Title')!;

  private _Duration = this._Metadata.getByRef('Duration')!;
  private _RatingBadge = this._Metadata.getByRef('RatingBadge')!;
  private _OnDemand = this._Metadata.getByRef('OnDemand')!;

  get title() {
    const { title, subtitle, description, ratings } = this.program ?? {};
    const duration = this._Duration.text?.text;
    const onDemand = this._OnDemand.visible ? translate('global.onDemand') : '';

    return [title, subtitle, duration, ratings, onDemand, description];
  }

  get channel() {
    return this._channel;
  }

  set channel(channel: EpgHeroTemplateSpec['channel']) {
    this._channel = channel;
    this._program = channel ? getCurrentEpgProgram(channel) : undefined;
    this.updateChannel();
  }

  get program() {
    return this._program;
  }

  set program(program: EpgHeroTemplateSpec['program']) {
    this._program = program;
    this.updateProgram();
  }

  set miniplayerHoleShader(
    shader: EpgHeroTemplateSpec['miniplayerHoleShader'],
  ) {
    const { x, y, w } = shader;

    this._Background.shader = shader;
    this._FullscreenIndicator.patch({
      x: x + w,
      y: y - 48,
    });
  }

  static override _template(): Lightning.Component.Template<EpgHeroTemplateSpec> {
    return {
      Background: {
        w: EPG_HERO_WIDTH,
        h: EPG_HERO_HEIGHT,
      },
      FullscreenIndicator: {
        mountX: 1,
        text: { fontSize: DEFAULT_FONT_SIZE },
      },
      Content: {
        flex: { direction: 'column' },
        x: CONTENT_X,
        y: CONTENT_Y,
        ChannelBadge: {
          flexItem: { marginBottom: 75 },
          type: ChannelBadge,
          backgroundAlpha: 0.65,
          w: 129,
          h: 91,
          logoSizing: { width: 75, height: 75 },
        },
        TitleContainer: {
          flexItem: { marginBottom: 11 },
          flex: { alignItems: 'center' },
          Title: {
            visible: false,
            w: TEXT_MAX_WIDTH,
            text: {
              fontFace: getFontFaceFromStyle('black'),
              fontSize: 42,
              maxLines: 2,
              maxLinesSuffix: translate('global.defaultMaxLineSuffix'),
              verticalAlign: 'middle',
            },
          },
          Logo: {
            visible: false,
            x: LOGO_X - CONTENT_X,
            w: LOGO_WIDTH,
            h: LOGO_HEIGHT,
          },
        },
        Subtitle: {
          flexItem: { marginBottom: 11 },
          w: TEXT_MAX_WIDTH,
          text: {
            fontSize: 23,
            fontFace: getFontFaceFromStyle('bold'),
          },
        },
        Metadata: {
          flexItem: { marginBottom: 11 },
          flex: {
            direction: 'row',
            alignItems: 'center',
          },
          Duration: {
            flexItem: { marginRight: 7 },
            alpha: 0.6,
            text: {
              fontSize: DEFAULT_FONT_SIZE,
              textColor: Colors('text').alpha(0.6).get(),
            },
          },
          RatingBadge: {
            flexItem: { marginRight: 7 },
            type: RatingBadge,
            size: 'extraSmall',
          },
          OnDemand: {
            type: OnDemandBadge,
            visible: false,
            w: 84,
            h: 22,
          },
        },
        Description: {
          w: DESCRIPTION_MAX_WIDTH,
          text: {
            fontSize: DEFAULT_FONT_SIZE,
            maxLines: 3,
            maxLinesSuffix: translate('global.defaultMaxLineSuffix'),
          },
        },
      },
    };
  }

  override _active() {
    if (this.channel) this.updateChannel();
  }

  override _inactive() {
    this._program = undefined;

    this.clearFullscreenInterval();
    this.resetChannelUI();
    this.resetProgramUI();
  }

  private updateChannel() {
    this.updateChannelUI();
    this.updateProgram();
  }

  private updateProgram() {
    this.updateProgramUI();
    this.updateTime(new Date());
  }

  updateTime(currentTime: Date) {
    const program = this.program;
    if (!program || !isProgramPlaying(program, currentTime)) return;

    const programEndTime = new Date(program?.endTime ?? '');
    const durationLeft = programEndTime.getTime() - currentTime.getTime();

    const durationString = millisecondsToContentDuration(durationLeft);
    const leftoverDurationString = translate(
      'epg.hero.leftoverDuration',
      durationString,
    );

    this._Duration.patch({ text: leftoverDurationString });
  }

  // Public functions to control full screen countdown
  startFullscreenInterval() {
    this.clearFullscreenInterval();
    this.updateFullScreenCountdown();

    this._fullscreenIntervalId = Registry.setInterval(() => {
      this._fullscreenTimeoutSeconds -= 1;
      this.updateFullScreenCountdown();

      if (this._fullscreenTimeoutSeconds === 0) {
        this.signal('expandChannelVideo', this.channel!);
        this.clearFullscreenInterval(false);
      }
    }, MILLISECONDS_IN_SECOND);
  }

  clearFullscreenInterval(resetIndicatorText = true) {
    if (this._fullscreenIntervalId)
      Registry.clearInterval(this._fullscreenIntervalId);
    this._fullscreenIntervalId = undefined;
    this._fullscreenTimeoutSeconds = TOTAL_FULLSCREEN_TIMEOUT_SECONDS;

    if (resetIndicatorText) this._FullscreenIndicator.patch({ text: '' });
  }

  private updateFullScreenCountdown() {
    const timeoutSecs = this._fullscreenTimeoutSeconds;
    const fullscreenTimerStr = translate(
      'epg.hero.fullscreenTimer',
      timeoutSecs,
    );

    if (timeoutSecs <= constants.epg.fullScreenTimeoutShow)
      this._FullscreenIndicator.patch({ text: fullscreenTimerStr });
  }

  private updateChannelUI() {
    this.resetChannelUI();

    const { logoFocused, logoUnfocused, showcaseBackground } =
      this.channel?.images ?? {};

    this._Background.patch({ src: showcaseBackground ?? '' });
    this._ChannelBadge.patch({
      logo: { focused: logoFocused ?? '', unfocused: logoUnfocused ?? '' },
    });
  }

  private updateProgramUI() {
    this.resetProgramUI();

    const { title, subtitle, description, ratings, isOnDemand, startTime } =
      this.program ?? {};
    const { iconUrl } = this.program?.images ?? {};

    const programStartTime = new Date(startTime ?? '');
    const startTimeText = timeTo12HourClockHoursMinutes(programStartTime);

    // If there is no icon URL, hide the program logo
    this._Logo.patch({ visible: iconUrl ? true : false, src: iconUrl ?? '' });

    // If there is no icon URL, show the program title (fallback)
    this._Title.patch({ visible: iconUrl ? false : true, text: title ?? '' });
    this._Subtitle.patch({ text: subtitle ?? '' });
    this._Description.patch({ text: description ?? '' });

    this._Duration.patch({ text: startTimeText ?? '' });
    this._RatingBadge.patch({ rating: ratings ?? '' });
    this._OnDemand.patch({ visible: isOnDemand ?? false });
  }

  private resetChannelUI() {
    this._Background.patch({ src: '' });
    this._ChannelBadge.patch({ logo: { focused: '', unfocused: '' } });
  }

  private resetProgramUI() {
    this._FullscreenIndicator.patch({ text: '' });
    this._Logo.patch({ visible: false, src: '' });

    this._Title.patch({ visible: false, text: '' });
    this._Subtitle.patch({ text: '' });
    this._Description.patch({ text: '' });

    this._Duration.patch({ text: '' });
    this._RatingBadge.patch({ rating: '' });
    this._OnDemand.patch({ visible: false });
  }
}
