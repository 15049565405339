import { Lightning, Router } from '@lightningjs/sdk';
import Page from 'components/Page';
import { PageId } from 'types/pageId';
import { translate } from 'support/translate';
import { IndexData, LightningBuilder } from 'types/lightning';
import { ListWithSpeech } from 'components/common/CollectionWrappersWithSpeech';
import ListSelection, {
  ListSelectionTemplateSpec,
} from 'components/pages/settings/ListSelection';
import { constants } from 'aliases';
import { debounce } from 'support/generalUtils';
import SettingsSection from 'components/pages/settings/SettingsSection';

export interface SettingsPageTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Content: {
    SectionsList: typeof ListWithSpeech;
    Section: typeof SettingsSection;
  };
}

const LIST_OFFSET_X = 62 + constants.sizing.navbar.collapsedWidth;
const SECTION_OFFSET_X = 856 + constants.sizing.navbar.collapsedWidth;
const OFFSET_Y = 100;

export default class SettingsPage
  extends Page<SettingsPageTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<SettingsPageTemplateSpec>
{
  protected override _pageId = PageId.SETTINGS;

  private _Content = this.getByRef('Content')!;
  private _SectionsList = this._Content.getByRef('SectionsList')!;
  private _Section = this._Content.getByRef('Section')!;

  private updateSelectionDelayed = debounce(
    this.updateSelection.bind(this),
    constants.timers.settingsMenuDebounce,
  );

  get announce() {
    return translate('ttsPrompts.settings');
  }

  static override _template(): Lightning.Component.Template<SettingsPageTemplateSpec> {
    const settingsSections = SettingsSection.getSettingsSections();
    const items = settingsSections.map(section =>
      this.buildListSelection(section),
    );

    return {
      ...super._template(),
      Content: {
        y: OFFSET_Y,
        SectionsList: {
          x: LIST_OFFSET_X,
          type: ListWithSpeech,
          itemType: ListSelection,
          direction: 'column',
          spacing: 30,
          items,
          signals: { onIndexChanged: true },
        },
        Section: {
          x: SECTION_OFFSET_X,
          type: SettingsSection,
          signals: {
            $onHover: '$onHover',
            $onUnhover: '$onUnhover',
          },
        },
      },
    };
  }

  override _enable() {
    this._setState('SectionList');

    // sets the initial selection
    const initialIndex = 0;

    this._SectionsList.index = initialIndex;
    this._SectionsList.items[initialIndex].isSelected = true;
    this.updateSelection(initialIndex);
  }

  override _disable() {
    this._SectionsList.items[this._SectionsList.index].isSelected = false;
  }

  override _handleBack() {
    Router.focusWidget('NavBar');
  }

  private updateList(newIndex: number, oldIndex?: number) {
    if (oldIndex !== undefined) {
      this._SectionsList.items[oldIndex].isSelected = false;
    }
    this._SectionsList.items[newIndex].isSelected = true;
  }

  private updateSelection(newIndex: number) {
    this._Section.selection = newIndex;
  }

  private static buildListSelection(
    sectionId: string,
  ): LightningBuilder<typeof ListSelection, ListSelectionTemplateSpec> {
    return {
      label: translate(`settings.${sectionId}.listTitle`),
    };
  }

  private onIndexChanged(indexData: IndexData) {
    const settingsSections = SettingsSection.getSettingsSections();
    if (indexData.dataLength !== settingsSections.length) return;

    this.updateList(indexData.index, indexData.previousIndex);
    this.updateSelectionDelayed(indexData.index);
  }

  $onHover(target: unknown) {
    this._setState('Section');
  }

  $onUnhover(target: unknown) {
    this._setState('SectionList');
  }

  static override _states() {
    return [
      class SectionList extends this {
        override _getFocused() {
          return this._SectionsList as Lightning.Component;
        }

        override _handleRight() {
          this._setState('Section');
        }
      },
      class Section extends this {
        override _getFocused() {
          return this._Section;
        }

        override _handleLeft() {
          this._setState('SectionList');
        }
      },
    ];
  }
}
