import { generateAbbrevConfig } from '@lightningjs/ui-components';
import { translate } from 'support/translate';

const abbreviations = [
  {
    pattern: /(S)+(\d+)+/,
    replacer: (_match: string, _p1: string, p2: string) =>
      `${translate('ttsPrompts.season')} ${p2}`,
  },
  {
    pattern: /(E)+(\d+)+/,
    replacer: (_match: string, _p1: string, p2: string) =>
      `${translate('ttsPrompts.episode')} ${p2}`,
  },
  {
    pattern: /(TV-Y7|TV-Y|TV-G|TV-MA|TV-PG|TV-14|PG-13|PG|R|G)/,
    replacer: (_match: string, p1: string) => {
      return translate('ttsPrompts.rated', p1.replace('-', ' '));
    },
  },
  {
    pattern: /(\d)+h+/,
    replacer: (_match: string, p1: string, _p2: string) =>
      `${p1} ${
        parseInt(p1) === 1
          ? translate('ttsPrompts.hour')
          : translate('ttsPrompts.hours')
      }`,
  },
];

export default generateAbbrevConfig(abbreviations, undefined);
