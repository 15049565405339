import { Lightning } from '@lightningjs/sdk';
import { StaticViewContexts } from 'types/analytics';
import { LiveEventChannel, Media } from 'types/api/media';
import { PageId } from 'types/pageId';
import Page from 'components/Page';
import LiveEventShowcase from 'components/pages/live-event-details/LiveEventShowcase';
import { getFocusDepth, getImageTextureObj } from 'support/generalUtils';
import { navigateToPlay } from 'support/routerUtils';
import LiveEventRecommendedSection from 'components/pages/live-event-details/LiveEventRecommendedSection';
import { ListItemContext, SelectItemContext } from 'types/events';
import {
  reportItemSelected,
  reportShowPageEngagement,
} from 'services/analytics/reportingServicePage';
import { getLiveEventStatus } from 'support/contentUtils';

export interface LiveEventDetailsPageSpec
  extends Lightning.Component.TemplateSpec {
  pageData: {
    liveEvent: LiveEventChannel;
    played: boolean;
    recommendedLiveEvents: LiveEventChannel[];
    selectItemContext: SelectItemContext | undefined;
  };

  Content: {
    Sections: {
      ShowcaseSection: typeof LiveEventShowcase;
      RecommendedSection: typeof LiveEventRecommendedSection;
    };
    Logo: Lightning.textures.ImageTexture;
  };
}

const CONTENT_H = 1080;

const RECOMMENDED_SECTION_OFFSET =
  LiveEventShowcase.h + LiveEventRecommendedSection.h - CONTENT_H;

const LOGO_X_SHOWCASE = 96;
const LOGO_Y_SHOWCASE = 85;
const LOGO_W_SHOWCASE = 565;
const LOGO_H_SHOWCASE = 230;

export default class LiveEventDetailsPage
  extends Page<LiveEventDetailsPageSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<LiveEventDetailsPageSpec>
{
  protected override _pageId = PageId.LIVE_EVENT_DETAIL;
  protected override _viewContext = StaticViewContexts.LIVE_EVENT_DETAIL_PAGE;
  private listItemContext: ListItemContext | undefined;

  private _liveEvent: LiveEventChannel | null = null;
  private _recommendedLiveEvents: LiveEventChannel[] = [];

  private _Content = this.getByRef('Content')!;
  private _Sections = this._Content.getByRef('Sections')!;
  private _ShowcaseSection = this._Sections.getByRef('ShowcaseSection')!;
  private _RecommendedSection = this._Sections.getByRef('RecommendedSection')!;
  private _Logo = this._Content.getByRef('Logo')!;

  static override _template(): Lightning.Component.Template<LiveEventDetailsPageSpec> {
    return {
      ...super._template(),
      Content: {
        h: CONTENT_H,
        Sections: {
          flex: { direction: 'column' },
          ShowcaseSection: {
            type: LiveEventShowcase,
            signals: {
              $playContent: '$playContent',
              $onHover: '$onHover',
            },
          },
          RecommendedSection: {
            type: LiveEventRecommendedSection,
            signals: {
              $onHover: '$onHover',
            },
          },
        },
        Logo: {
          x: LOGO_X_SHOWCASE,
          y: LOGO_Y_SHOWCASE,
        },
      },
    };
  }

  override _setup() {
    super._setup();
    this._setState('ShowcaseSection');
  }

  override _onDataProvided() {
    this.loadingEvent(true);

    const { liveEvent, recommendedLiveEvents } = this.pageData;
    this._liveEvent = liveEvent;
    this._recommendedLiveEvents = recommendedLiveEvents;
    this.updateListItemContext();

    this._ShowcaseSection.patch({ media: liveEvent });
    this._RecommendedSection.patch({ liveEvents: recommendedLiveEvents });
    this.updateLogo();
  }

  override _onChanged() {
    const liveEvent = this._liveEvent;
    const { played, selectItemContext } = this.pageData;

    if (!played && liveEvent) {
      const { eventStatus } = getLiveEventStatus(liveEvent);
      if (eventStatus === 'live') {
        const slug = liveEvent.slug;
        const context = selectItemContext ?? {};

        navigateToPlay(slug, context, 'live');
        return;
      }
    }

    this.loadingEvent(false);
    this._setState('ShowcaseSection');

    // @ts-ignore
    this.fireAncestors(
      '$announcerRefresh',
      getFocusDepth(this.application, this as LiveEventDetailsPage),
    );
  }

  override _inactive() {
    this._Logo.removeAllListeners('txError');

    this._setState('ShowcaseSection');
  }

  override _handleUp() {
    this._setState('ShowcaseSection');
  }

  override _handleDown() {
    if (this._recommendedLiveEvents.length) {
      this._setState('RecommendedSection');
    }
  }

  private loadingEvent(loading: boolean) {
    this._Content.visible = !loading;
    this.loaded = !loading;
  }

  private updateListItemContext() {
    this.listItemContext = undefined;

    const liveEvent = this._liveEvent;
    if (!liveEvent) return;

    const { selectItemContext } = this.pageData;
    if (selectItemContext?.listItemContext) {
      this.listItemContext = selectItemContext.listItemContext;
    } else {
      this.listItemContext = {
        imageUrl: liveEvent.images.liveStreamBackground ?? '',
        rowTitle: liveEvent.slug,
        // Analytics adds 1 to indexes, making -1 to ensure value will be 0
        itemIndex: -1,
        rowIndex: -1,
      };
    }
  }

  private updateLogo() {
    this._Logo.removeAllListeners('txError');
    this._Logo.on('txError', () => {
      this._Logo.patch({ texture: undefined });
    });

    const src = this._liveEvent!.images.eventLockup ?? '';
    const logoTexture = getImageTextureObj(
      src,
      LOGO_W_SHOWCASE,
      LOGO_H_SHOWCASE,
    );

    this._Logo.patch(logoTexture);
  }

  private getDefaultSelectItemContext() {
    return {
      pageId: this._pageId,
      listItemContext: this.listItemContext,
      viewContext: this.viewContext,
    };
  }

  $onHover(target: Lightning.Component) {
    switch (target) {
      case this._RecommendedSection:
        this._setState('RecommendedSection');
        break;
      case this._ShowcaseSection:
        this._setState('ShowcaseSection');
        break;
    }
  }

  $playContent() {
    if (!this._liveEvent) return;
    const liveEvent = this._liveEvent;
    const slug = liveEvent.slug;
    const imageSrc = liveEvent.images.liveStreamBackground ?? '';

    // Analytics adds 1 to indexes, making -1 to ensure value will be 0
    reportShowPageEngagement(liveEvent, slug, imageSrc, -1, -1);

    const selectItemContext = this.getDefaultSelectItemContext();
    navigateToPlay(slug, selectItemContext, 'live');
  }

  override $onTileSelected(data?: Media, context?: SelectItemContext) {
    const crossIndex = this._RecommendedSection.index;
    const mainIndex = 0; // Only one recommended row
    const rowTitle = this._RecommendedSection.label;
    const imageUrl = context?.imageUrl ?? '';

    reportItemSelected({
      imageUrl,
      crossIndex,
      mainIndex,
      data,
      rowTitle,
      isTivoTileSource: false, // Live event details recommendations will not come from Tivo
    });

    if (context) {
      context.listItemContext = {
        imageUrl,
        itemIndex: crossIndex,
        rowIndex: mainIndex,
        rowTitle,
      };
    }
    super.$onTileSelected(data, context);
  }

  static override _states() {
    return [
      class ShowcaseSection extends this {
        override $enter() {
          this._Content.setSmooth('y', 0);
        }

        override _getFocused() {
          return this._ShowcaseSection;
        }
      },
      class RecommendedSection extends this {
        override $enter() {
          this._Content.setSmooth('y', -RECOMMENDED_SECTION_OFFSET);
        }

        override _getFocused() {
          return this._RecommendedSection;
        }

        override _handleBack() {
          this._setState('ShowcaseSection');
        }
      },
    ];
  }
}
