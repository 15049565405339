import { Colors, Lightning } from '@lightningjs/sdk';
import ActionsList from 'components/common/ActionsList';
import CheckmarkButton from 'components/common/CheckmarkButton';
import { getFontFaceFromStyle } from 'support/textUtils';
import { translate } from 'support/translate';
import { getFocusDepth } from 'support/generalUtils';
import {
  HoverableComponent,
  HoverableComponentSignalMap,
  HoverableComponentTypeConfig,
} from 'components/common/HoverableComponent';

const FONT_SIZE = 39;
const LABEL_SPACING = 25;
const ITEM_SPACING = 95;
const HIGHLIGHT_OFFSET = 15;
const HIGHLIGHT_HEIGHT = 2;

export interface RadioRowGroupTemplateSpec
  extends Lightning.Component.TemplateSpec {
  label: string;
  options: string[];
  selectedIndex: number;

  Label: Lightning.textures.TextTexture;
  Options: typeof ActionsList;
}

interface RadioRowGroupSignalMap extends HoverableComponentSignalMap {
  onChange(index: number): void;
}

interface RadioRowGroupTypeConfig extends HoverableComponentTypeConfig {
  SignalMapType: RadioRowGroupSignalMap;
}

export default class RadioRowGroup
  extends HoverableComponent<RadioRowGroupTemplateSpec, RadioRowGroupTypeConfig>
  implements
    Lightning.Component.ImplementTemplateSpec<RadioRowGroupTemplateSpec>
{
  private _label = '';
  private _options: RadioRowGroupTemplateSpec['options'] = [];
  private _selectedIndex = -1;

  private _Label = this.getByRef('Label')!;
  private _Options = this.getByRef('Options')!;

  get title() {
    return translate('ttsPrompts.radioRowGroup', this.label);
  }

  get label() {
    return this._label;
  }

  set label(label: RadioRowGroupTemplateSpec['label']) {
    this._label = label;
    this._Label.patch({ text: { text: label } });
  }

  get options() {
    return this._options;
  }

  set options(options: RadioRowGroupTemplateSpec['options']) {
    this._options = options;
    this._Options.clearActions();
    options.forEach(this.addOption.bind(this));
  }

  get selectedIndex() {
    return this._selectedIndex;
  }

  set selectedIndex(selectedIndex: RadioRowGroupTemplateSpec['selectedIndex']) {
    this._selectedIndex = selectedIndex;
    this._Options.children.forEach((child, i) => {
      const isSelected = i === selectedIndex;
      (child as CheckmarkButton).isSelected = isSelected;
      if (isSelected) this._Options.index = selectedIndex;
    });
  }

  static override _template(): Lightning.Component.Template<RadioRowGroupTemplateSpec> {
    return {
      flex: { direction: 'column' },
      Label: {
        text: {
          fontSize: FONT_SIZE,
          fontFace: getFontFaceFromStyle('bold'),
          highlightColor: Colors('highlight').get(),
          highlight: true,
          highlightOffset: FONT_SIZE + HIGHLIGHT_OFFSET,
          highlightHeight: HIGHLIGHT_HEIGHT,
        },
      },
      Options: {
        type: ActionsList,
        spacing: ITEM_SPACING,
        flexItem: { marginTop: LABEL_SPACING },
        signals: { setSelected: '_setSelected' },
      },
    };
  }

  override _getFocused() {
    return this._Options;
  }

  private addOption(label: string, index: number) {
    this._Options.addAction({
      type: CheckmarkButton,
      label,
      fontSize: FONT_SIZE,
      isSelected: false,
      action: 'setSelected',
      actionArgs: index,
      passSignals: { setSelected: true },
      signals: {
        $onHover: '$onHover',
      },
    });
  }

  private _setSelected(index: number) {
    this.selectedIndex = index;
    let selectedOption;
    this._Options.children.forEach((child, i) => {
      if (i === index) {
        selectedOption = child;
      }
      (child as CheckmarkButton).isSelected = i === index;
    });

    this.signal('onChange', index);
    if (selectedOption) {
      this.fireAncestors(
        '$announcerRefresh',
        getFocusDepth(this.application, selectedOption),
      );
    }
  }
}
