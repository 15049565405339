import { translate } from 'support/translate';

type TextStyles = 'light' | 'regular' | 'medium' | 'bold' | 'black';

export const getFontFaceFromStyle = (style: TextStyles) => {
  switch (style) {
    case 'light':
      return 'Roboto-Light';
    case 'regular':
      return 'Roboto';
    case 'medium':
      return 'Roboto-Medium';
    case 'bold':
      return 'Roboto-Bold';
    case 'black':
      return 'Roboto-Black';
  }
};

export const withSeparator = (...text: string[]) => {
  const separator = translate('global.metadataSeparator');
  return text.filter(str => !!str).join(separator);
};
