import { AppData } from '@lightningjs/sdk';
import { shouldUseDevAccessHeader } from 'support/appUtils';
import { constants } from 'aliases';

const CONTENT_SEGMENT_HOSTNAME = 'stream-hls.cwtv.com' as const;

export default abstract class AbstractPlayerInstance<SubtitleData = unknown> {
  protected videoElement: HTMLMediaElement;
  protected handleTracksUpdate: ((textTracks: string[]) => void) | undefined;
  protected handleManualTextTrackChange:
    | ((textTrack: TextTrack) => void)
    | undefined;
  protected subtitles: string[] = [];

  currentBitrate: number | null = null;

  constructor(videoElement: HTMLMediaElement) {
    this.videoElement = videoElement;
  }

  abstract destroy(): Promise<void>;

  abstract load(url: string, startTime: number | undefined): Promise<void>;

  abstract onAdaption(handleAdaption: (bitrate: number) => void): void;

  abstract onError(
    handleError: (isFatal: boolean, errorMessage: string) => void,
  ): void;

  abstract getAudioOptions(): string[];

  abstract setAudioTrack(lang: string): void;

  abstract enableCc(lang: string): void;

  abstract disableCc(): void;

  /**
   * Translates the data into an array of subtitle language codes
   *
   * @param data - data containing subtitle tracks
   * @returns a string array that contains subtitle language codes
   */
  protected abstract extractSubtitleOptions(data?: SubtitleData): string[];

  protected abstract getPlayerMedia(): HTMLMediaElement | null;

  protected augmentXhrRequest(xhr: XMLHttpRequest, url?: string) {
    if (!url || !shouldUseDevAccessHeader()) return;

    const parsedUrl = new URL(url);
    if (parsedUrl.hostname !== CONTENT_SEGMENT_HOSTNAME) return;

    const { header, key } = AppData!.devAccessHeader;
    xhr.setRequestHeader(header, key);
  }

  protected updateSubtitles(data?: SubtitleData) {
    this.subtitles = this.extractSubtitleOptions(data);
    const subtitle = AppData!.storageService.subtitle.get();
    if (subtitle) {
      this.enableCc(subtitle);
    } else if (AppData?.device.getCcSettings().enabled) {
      this.enableCc(this.subtitles[0] ?? '');
    } else {
      this.disableCc();
    }

    this.handleTracksUpdate?.(this.subtitles);
  }

  getSubtitles() {
    return this.subtitles;
  }

  onTracksUpdate(handleTrackUpdate: (textTracks: string[]) => void) {
    this.handleTracksUpdate = handleTrackUpdate;
  }

  onManualTextTrackChange(
    handleManualTextTrackChange: (textTrack: TextTrack) => void,
  ) {
    this.handleManualTextTrackChange = handleManualTextTrackChange;
  }

  /**
   *
   * @returns true if text track is found or cannot be handled
   */
  handleManualTextTrack(): boolean {
    return true;
  }

  updateTtsPlaybackVolume(isSpeaking: boolean): void {
    const media = this.getPlayerMedia();
    if (media) {
      media.volume = isSpeaking
        ? constants.player.ttsVolume
        : constants.player.normalVolume;
    }
  }
}
