import Modal from 'components/widgets/Modal';
import { closeApp } from 'support/appUtils';
import { translate } from 'support/translate';
import { Router } from '@lightningjs/sdk';

export default class ExitModal extends Modal {
  private static instance: ExitModal | null = null;

  public static onClose: (() => void) | null = null;

  override _construct() {
    ExitModal.instance = this;
  }

  override _setup() {
    ExitModal.instance!.title = translate('global.appExitMessage');
    ExitModal.instance!.confirmLabel = translate('global.appExitConfirmButton');
    ExitModal.instance!.cancelLabel = translate('global.appExitDismissButton');
    ExitModal.instance!.onCancel = closeApp;
  }

  override close() {
    this._close = true;
    this.alpha = 0;

    if (ExitModal.onClose) {
      ExitModal.onClose();
    } else {
      Router.focusPage();
    }
    ExitModal.onClose = null;
  }
}
