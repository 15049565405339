import MIGRATION_2_4_3 from 'services/migrations/2.4.3';

export type VersionMigration = {
  version: string;
  migrationFn: () => Promise<void>;
};

// Insert ascending migration functions into array
const migrations: readonly VersionMigration[] = [MIGRATION_2_4_3];

const getMigrationFunctions = (previousVersion: string) => {
  const migrationsStartIndex = migrations.findIndex(
    migration => migration.version === previousVersion,
  );
  // Unable to migrate if version can't be found in migration scripts
  if (migrationsStartIndex === -1) throw new Error();

  const missingMigrations = migrations.slice(migrationsStartIndex + 1);
  return missingMigrations.map(migration => migration.migrationFn);
};

export default getMigrationFunctions;
