import { Lightning, Colors } from '@lightningjs/sdk';
import { getFontFaceFromStyle } from 'support/textUtils';
import { translate } from 'support/translate';
import { HoverableComponent } from 'components/common/HoverableComponent';

export interface MoreInfoTemplateSpec extends Lightning.Component.TemplateSpec {
  description: string;
  action: string;
  isLive: boolean;
  Background: Lightning.Element;
  Content: {
    Description: Lightning.Element;
    MoreInfoTitle: Lightning.Element;
  };
}

const MORE_INFO_WIDTH = 715;
const MORE_INFO_HEIGHT = 180;
const BACKGROUND_RADIUS = 5;

const CONTENT_W_PADDING = 20;
const CONTENT_H_PADDING = 18;

const DESCRIPTION_WIDTH = MORE_INFO_WIDTH - CONTENT_W_PADDING * 2;
const DESCRIPTION_HEIGHT = 110;
const DESCRIPTION_FONT_SIZE = 29;
const DESCRIPTION_MAX_LINES = 3;

const MORE_INFO_TITLE_FONT_SIZE = 25;

export default class MoreInfo
  extends HoverableComponent<MoreInfoTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<MoreInfoTemplateSpec>
{
  private _action = '';
  private _isLive = false;
  private _Background = this.getByRef('Background')!;
  private _Content = this.getByRef('Content')!;
  private _Description = this._Content.getByRef('Description')!;
  private _MoreInfoTitle = this._Content.getByRef('MoreInfoTitle')!;

  get title() {
    return [
      this._Description.text?.text ?? '',
      translate('ttsPrompts.moreInfo'),
    ];
  }

  set action(value: string) {
    this._action = value;
  }

  set isLive(value: boolean) {
    this._isLive = value;
    this.updateHeight();
  }

  set description(description: string) {
    this._Description.patch({ text: { text: description } });
  }

  get description() {
    return this._Description.text?.text ?? '';
  }

  static override _template(): Lightning.Component.Template<MoreInfoTemplateSpec> {
    return {
      w: MORE_INFO_WIDTH,
      h: MORE_INFO_HEIGHT,
      Background: {
        alpha: 0,
        rect: true,
        w: (w: number) => w,
        h: (h: number) => h,
        color: Colors('white').get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: BACKGROUND_RADIUS,
        },
      },
      Content: {
        flex: {
          direction: 'column',
          paddingLeft: CONTENT_W_PADDING,
          paddingRight: CONTENT_W_PADDING,
          paddingTop: CONTENT_H_PADDING,
          paddingBottom: CONTENT_H_PADDING,
        },
        Description: {
          w: DESCRIPTION_WIDTH,
          h: DESCRIPTION_HEIGHT,
          text: {
            textColor: Colors('text').get(),
            fontSize: DESCRIPTION_FONT_SIZE,
            lineHeight: DESCRIPTION_FONT_SIZE + 5,
            maxLines: DESCRIPTION_MAX_LINES,
            maxLinesSuffix: '...',
          },
        },
        MoreInfoTitle: {
          text: {
            text: translate('global.moreInfo'),
            fontSize: MORE_INFO_TITLE_FONT_SIZE,
            fontFace: getFontFaceFromStyle('light'),
          },
        },
      },
    };
  }

  override _getFocused() {
    return this;
  }

  override _focus() {
    this.updateBackground();
  }

  override _unfocus() {
    this.updateBackground();
  }

  private updateBackground() {
    const alpha = this.hasFocus() ? 0.15 : 0;
    this._Background.setSmooth('alpha', alpha);
  }

  private updateHeight() {
    if (this._isLive) {
      this.h = MORE_INFO_HEIGHT + 33;
      this._MoreInfoTitle.patch({
        flexItem: {
          marginTop: 33,
        },
      });
    } else {
      this.h = MORE_INFO_HEIGHT;
      this._MoreInfoTitle.patch({
        flexItem: {
          marginTop: 0,
        },
      });
    }
  }

  override _handleEnter() {
    this._action && this.signal(this._action);
  }
}
