import { Colors, Lightning } from '@lightningjs/sdk';
import { HoverableComponent } from 'components/common/HoverableComponent';
import { SECTION_PADDING } from 'components/pages/settings/sections/toggleSection/ToggleSettingSection';
import { getFontFaceFromStyle } from 'support/textUtils';
import ToggleSlider from 'components/common/ToggleSlider';

interface ToggleComponentTemplateSpec extends Lightning.Component.TemplateSpec {
  Wrapper: {
    Label: Lightning.textures.TextTexture;
    Button: typeof ToggleSlider;
  };
}

const TOGGLE_LABEL_BUTTON_SPACING = 30;

export default class ToggleComponent extends HoverableComponent<ToggleComponentTemplateSpec> {
  static override _template(): Lightning.Component.Template<ToggleComponentTemplateSpec> {
    return {
      h: 60,
      rect: true,
      color: Colors('transparent').get(),
      Wrapper: {
        h: h => h,
        flex: {
          direction: 'row',
          alignItems: 'center',
          paddingLeft: SECTION_PADDING,
          paddingRight: SECTION_PADDING,
        },
        Label: {
          y: 2,
          text: {
            fontFace: getFontFaceFromStyle('regular'),
            fontSize: 32,
            textColor: Colors('text').get(),
          },
        },
        Button: {
          type: ToggleSlider,
          flexItem: { marginLeft: TOGGLE_LABEL_BUTTON_SPACING },
          signals: {
            $onCursorClick: '$onCursorClick',
          },
        },
      },
    };
  }
}
