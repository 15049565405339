import { AbstractDeviceIntegration } from 'config/platforms/AbstractDeviceIntegration';
import StorageService from 'services/StorageService';
import { Platform } from 'models/platforms/platform';

export type ParentalPinData = {
  pin: string | null;
  enabled: boolean;
};

export default class ParentalPinService {
  private readonly device: AbstractDeviceIntegration;
  private readonly storageService: StorageService;
  private hasValidated = false;

  constructor(args: {
    device: AbstractDeviceIntegration;
    storageService: StorageService;
  }) {
    this.device = args.device;
    this.storageService = args.storageService;
  }

  private getPinData(): ParentalPinData | null {
    return this.storageService.parentalPin.get();
  }

  isPinRequired(/* TV rating would be passed here */) {
    // pin only required for LG
    if (
      this.device.getPlatform() !== Platform.LG &&
      this.device.getPlatform() !== Platform.UNKNOWN
    ) {
      return false;
    }

    const pinData = this.getPinData();

    if (!pinData || pinData.pin === null) {
      return false;
    }

    return pinData.enabled && !this.hasValidated;
  }

  togglePin(enabled: boolean) {
    const pinData = this.getPinData();

    const newPinData: ParentalPinData = pinData
      ? { ...pinData, enabled }
      : { pin: null, enabled };

    this.storageService.parentalPin.set(newPinData);
  }

  setNewPin(pin: string, setAsValidated = false) {
    const pinData = this.getPinData();
    const newPinData: ParentalPinData = pinData
      ? { ...pinData, pin }
      : { pin, enabled: true }; // default to true if we haven't set the pin data before (note this case shouldn't occur)

    this.storageService.parentalPin.set(newPinData);

    if (setAsValidated) {
      this.hasValidated = true;
    }
  }

  hasPinBeenSet(): boolean {
    const pinData = this.getPinData();

    return !!pinData?.pin;
  }

  validatePin(pin: string): boolean {
    const pinData = this.getPinData();

    // we automatically pass if there's no pin
    if (!pinData?.pin) return true;

    const isValid = pin === pinData?.pin;

    if (isValid) {
      this.hasValidated = true;
    }
    return isValid;
  }

  isEnabled(): boolean {
    const pinData = this.getPinData();

    return pinData ? pinData.enabled : false;
  }
}
