import Tile, { TileTemplateSpec } from 'components/common/tiles/Tile';
import { ContentHub } from 'services/cwData';

export interface VerticalContentHubTileTemplateSpec extends TileTemplateSpec {
  data: ContentHub;
}

const TILE_WIDTH = 250;
const TILE_HEIGHT = 375;

export default class VerticalContentHubTile extends Tile<VerticalContentHubTileTemplateSpec> {
  static override get width() {
    return TILE_WIDTH;
  }

  static override get height() {
    return TILE_HEIGHT;
  }

  static override get highlightWidth() {
    return TILE_WIDTH;
  }

  static override get highlightHeight() {
    return TILE_HEIGHT;
  }
}
