import { Lightning, Colors, AppData, Router } from '@lightningjs/sdk';
import { getImageTextureObj } from 'support/generalUtils';
import { PageId } from 'types/pageId';
import { getHomeHistoryEntry } from 'support/routerUtils';

import Page from 'components/Page';
import { ErrorCause } from 'components/widgets/ErrorModal';
import { closeApp } from 'support/appUtils';
import { constants } from 'aliases';

export interface SplashPageTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Content: {
    Logo: object;
    ProgressWrap: {
      ProgressBorder: object;
      Progress: object;
    };
  };
}

const LOGO_WIDTH = 340.5;
const LOGO_HEIGHT = 136.88;
const LOGO_MARGIN = 100;

const PROGRESS_WRAP_WIDTH = 642;
const PROGRESS_WRAP_HEIGHT = 34;

const PROGRESS_BORDER_STROKE = 3;

const PROGRESS_HEIGHT = 16;
const PROGRESS_MAX_WIDTH = 621;

const PROGRESS_FADE_IN_DELAY = 1;
const PROGRESS_FADE_IN_DURATION = 0.5;

const PAGE_FADE_OUT_DELAY = 1;

export default class SplashPage
  extends Page<SplashPageTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<SplashPageTemplateSpec>
{
  protected override _pageId = PageId.SPLASH;

  private _Content = this.getByRef('Content')!;
  private _Logo = this._Content.getByRef('Logo')!;
  private _ProgressWrap = this._Content.getByRef('ProgressWrap')!;
  private _ProgressBorder = this._ProgressWrap.getByRef('ProgressBorder')!;
  private _Progress = this._ProgressWrap.getByRef('Progress')!;

  private _progressPercentage = 0;

  static requestFunctions: Array<() => Promise<unknown>> = [];

  static override _template(): Lightning.Component.Template<SplashPageTemplateSpec> {
    const logoTexture = getImageTextureObj(
      'static/images/cw-icon.svg',
      LOGO_WIDTH,
      LOGO_HEIGHT,
    );

    return {
      ...super._template(),
      Content: {
        flex: {
          direction: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        },
        w: 1920,
        h: 1080,
        transitions: {
          alpha: { delay: PAGE_FADE_OUT_DELAY },
        },
        Logo: {
          flexItem: { marginBottom: LOGO_MARGIN },
          ...logoTexture,
        },
        ProgressWrap: {
          alpha: 0,
          w: PROGRESS_WRAP_WIDTH,
          h: PROGRESS_WRAP_HEIGHT,
          transitions: {
            alpha: {
              delay: PROGRESS_FADE_IN_DELAY,
              duration: PROGRESS_FADE_IN_DURATION,
              timingFunction: 'ease-in-out',
            },
          },
          ProgressBorder: {
            rect: true,
            w: (w: number) => w,
            h: (h: number) => h,
            color: Colors('transparent').get(),
            shader: {
              type: Lightning.shaders.RoundedRectangle,
              radius: 18,
              stroke: PROGRESS_BORDER_STROKE,
              strokeColor: Colors('white').get(),
            },
          },
          Progress: {
            rect: true,
            w: 0,
            h: PROGRESS_HEIGHT,
            x: (w: number) => (w - PROGRESS_MAX_WIDTH) / 2,
            y: (h: number) => (h - PROGRESS_HEIGHT) / 2,
            color: Colors('white').get(),
            shader: {
              type: Lightning.shaders.RoundedRectangle,
              radius: 10,
            },
            transitions: {
              w: { duration: 1, timingFunction: 'ease-in-out' },
            },
          },
        },
      },
    };
  }

  override async _onDataProvided() {
    this.fadeInProgress();
    const requestFunctions = SplashPage.requestFunctions;

    for (let i = 0; i < requestFunctions.length; i++) {
      try {
        await requestFunctions[i]!();
        const progress = (i + 1) / requestFunctions.length;
        this.updateProgress(progress);
      } catch (e) {
        console.error(e);
        return this.showError();
      }
    }
  }

  override _setup() {
    super._setup();
    this.backgroundColor = 'splashScreen';

    this.setupPrivacyDefault();
    this.createListeners();
    this.setPerformanceMode();
  }

  override _active() {
    super._active();

    const preSplashBackground = document.getElementById(
      constants.elements.preSplashBackgroundId,
    );

    if (preSplashBackground) {
      preSplashBackground.remove();
    }
  }

  override _inactive() {
    super._inactive();
    this.clearListeners();
  }

  override _handleBack() {
    closeApp();
  }

  private setupPrivacyDefault() {
    AppData?.privacyService.setDefault();
  }

  private createListeners() {
    this._ProgressWrap.transition('alpha').on('finish', () => {
      const progress = this._progressPercentage;
      this.updateProgress(progress);
    });

    this._Progress.transition('w').on('finish', () => {
      this.completeProgress();
    });

    this._Content.transition('alpha').on('finish', () => {
      this.resumeNavigation();
    });
  }

  private clearListeners() {
    this._ProgressWrap.transition('alpha').removeAllListeners('finish');
    this._Progress.transition('w').removeAllListeners('finish');
    this._Content.transition('alpha').removeAllListeners('finish');
  }

  private fadeInProgress() {
    this._ProgressWrap.setSmooth('alpha', 1);
  }

  private updateProgress(progress: number) {
    this._progressPercentage = progress;

    // Wait until progress is visible to update the UI
    if (this._ProgressWrap.alpha !== 1) return;
    this._Progress.setSmooth('w', PROGRESS_MAX_WIDTH * progress);
  }

  private completeProgress() {
    if (this._progressPercentage === 1) this.fadeOutPage();
  }

  private fadeOutPage() {
    this._Content.setSmooth('alpha', 0);
  }

  private showError() {
    this.widgets.errormodal.update({ cause: ErrorCause.SPLASH });
    Router.focusWidget('ErrorModal');
  }

  private resumeNavigation() {
    if (AppData?.device.handleDeepLink()) {
      AppData.isDeepLinkEntry = true;
    } else {
      Router.setHistory([getHomeHistoryEntry()]); // allows navigation back to homepage for deep links
      Router.resume();
    }
  }

  private setPerformanceMode() {
    // @ts-ignore
    this.stage._options.readPixelsAfterDraw =
      AppData?.device.getPerformanceMode();
  }
}
