import { Colors, Lightning } from '@lightningjs/sdk';
import Tile, { TileTemplateSpec } from 'components/common/tiles/Tile';
import Badge from 'components/common/Badge';
import { translate } from 'support/translate';
import { Video } from 'types/api/media';
import {
  getExpiringDateString,
  getSeasonEpisodeFormatted,
  isMediaExpiring,
} from 'support/contentUtils';
import { getFontFaceFromStyle } from 'support/textUtils';
import { SelectItemContext } from 'types/events';
import { getImageTextureObj } from 'support/generalUtils';

export interface VideoTileTemplateSpec extends TileTemplateSpec {
  data: Video;
  tileTitle: string;
  tileDescription: string;

  Gradient: Lightning.Component;
  Badge: typeof Badge;
  Title: Lightning.Component;
  Description: Lightning.Component;
  PlayButton: Lightning.Texture;
}

const TILE_WIDTH = 400;
const TILE_HEIGHT = 225;

const BADGE_PADDING = 5;
const TEXT_PADDING_X = 10;
const TITLE_OFFSET_Y = 39;
const TITLE_MAX_LINES = 1;
const TITLE_FONT_SIZE = 26;
const DESCRIPTION_OFFSET_Y = 10;
const DESCRIPTION_FONT_SIZE = 22;
const DESCRIPTION_MAX_LINES = 1;

const PLAY_BUTTON_WIDTH = 34;
const PLAY_BUTTON_HEIGHT = 43;

export default class VideoTile extends Tile<VideoTileTemplateSpec> {
  private _tileTitle: string | undefined;
  private _tileDescription: string | undefined;

  private _Badge = this.getByRef('Badge')!;
  private _Title = this.getByRef('Title')!;
  private _Description = this.getByRef('Description')!;
  private _PlayButton = this.getByRef('PlayButton')!;

  isNew = false;
  isExpiring = false;

  override get title() {
    const title = this._Title.text?.text ?? '';
    const description = this._Description.text?.text ?? '';
    const badge = this._Badge.text?.text ?? '';

    return [title, description, badge];
  }

  override set data(data: Video) {
    super.data = data;

    const isNew = data.isNew;
    const expiring = isMediaExpiring(data);

    if (isNew) {
      this._Badge.label = translate('episode.new');
    } else if (expiring) {
      this._Badge.label = getExpiringDateString(data);
    }

    // Set title if it hasn't already been set
    if (!this._tileTitle) {
      const title = data.seriesName;
      this._Title.patch({ text: title });
    }

    // Set description if it hasn't already been set
    if (!this._tileDescription) {
      const description = getSeasonEpisodeFormatted(data);
      this._Description.patch({ text: description });
    }
  }

  set tileTitle(title: string) {
    this._tileTitle = title;
    this._Title.patch({ text: title });
  }

  set tileDescription(description: string) {
    this._tileDescription = description;
    this._Description.patch({ text: description });
  }

  override get action(): SelectItemContext['action'] {
    return 'play';
  }

  // static getters required for collection wrapper (List, Carousel, etc.)
  static override get width() {
    return TILE_WIDTH;
  }

  static override get height() {
    return TILE_HEIGHT;
  }

  static override get highlightWidth() {
    return TILE_WIDTH;
  }

  static override get highlightHeight() {
    return TILE_HEIGHT;
  }

  static override _template(): Lightning.Component.Template<VideoTileTemplateSpec> {
    const playButtonTexture = getImageTextureObj(
      'static/images/playback/player-play-icon.svg',
      PLAY_BUTTON_WIDTH,
      PLAY_BUTTON_HEIGHT,
    );

    return {
      ...super._template(),
      Gradient: {
        rect: true,
        zIndex: 3,
        w: w => w,
        h: h => h,
        colorTop: Colors('transparent').get(),
        colorBottom: Colors('background').get(),
      },
      Badge: {
        zIndex: 4,
        type: Badge,
        mountX: 1,
        x: w => w - BADGE_PADDING,
        y: BADGE_PADDING,
      },
      Title: {
        zIndex: 4,
        mountY: 1,
        x: TEXT_PADDING_X,
        y: h => h - TITLE_OFFSET_Y,
        w: w => w - TEXT_PADDING_X * 2,
        text: {
          fontSize: TITLE_FONT_SIZE,
          fontFace: getFontFaceFromStyle('medium'),
          maxLines: TITLE_MAX_LINES,
        },
      },
      Description: {
        zIndex: 4,
        mountY: 1,
        x: TEXT_PADDING_X,
        y: h => h - DESCRIPTION_OFFSET_Y,
        w: w => w - TEXT_PADDING_X * 2,
        text: {
          fontSize: DESCRIPTION_FONT_SIZE,
          maxLines: DESCRIPTION_MAX_LINES,
        },
      },
      PlayButton: {
        alpha: 0,
        mount: 0.5,
        x: (w: number) => w / 2,
        y: (h: number) => h / 2,
        ...playButtonTexture,
      },
    };
  }

  override _focus() {
    this.updatePlayButton();
  }

  override _unfocus() {
    this.updatePlayButton();
  }

  private updatePlayButton() {
    const playButtonAlpha = this.hasFocus() ? 0.6 : 0;
    this._PlayButton.setSmooth('alpha', playButtonAlpha);
  }
}
