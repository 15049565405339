import Tile, { TileTemplateSpec } from 'components/common/tiles/Tile';
import { ContentHub } from 'services/cwData';

export interface SquareContentHubTileTemplateSpec extends TileTemplateSpec {
  data: ContentHub;
}

const TILE_WIDTH = 300;
const TILE_HEIGHT = 300;

export default class SquareContentHubTile extends Tile<SquareContentHubTileTemplateSpec> {
  static override get width() {
    return TILE_WIDTH;
  }

  static override get height() {
    return TILE_HEIGHT;
  }

  static override get highlightWidth() {
    return TILE_WIDTH;
  }

  static override get highlightHeight() {
    return TILE_HEIGHT;
  }
}
