import axios, { AxiosError, Method } from 'axios';
import ApiServiceError from 'support/ApiServiceError';
import { HttpOptions } from 'services/CwApiClient';

export default class TivoApiClient {
  async request<TResponse = unknown>(
    method: Method,
    url: string,
    options?: HttpOptions,
  ) {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...options?.headers,
    };

    try {
      return await axios.request<TResponse>({
        url,
        method,
        headers,
        data: options?.data,
        params: options?.params,
        responseType: 'json',
      });
    } catch (e) {
      throw new ApiServiceError(e as AxiosError, url);
    }
  }
}
