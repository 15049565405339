import { Colors, Lightning } from '@lightningjs/sdk';
import Scrollable from 'components/common/Scrollable';
import { translate } from 'support/translate';
import { attributions } from 'aliases';
import { getFontFaceFromStyle } from 'support/textUtils';
import { LongTextAnnouncerService } from 'services/LongTextAnnouncerService';

type Attribution = {
  title: string;
  message: string;
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AttributionsSectionTemplateSpec
  extends Lightning.Component.TemplateSpec {
  SectionTitle: Lightning.Element;
  TextContent: typeof Scrollable;
}

const TITLE_X_PADDING = 20;
const TITLE_Y_PADDING = 16;
const TEXT_CONTENT_Y_OFFSET = 71;
const TEXT_CONTENT_WIDTH = 808;
const TEXT_CONTENT_HEIGHT = 849;
const TEXT_CONTENT_WORD_WRAP = 768;

export default class AttributionsSection
  extends Lightning.Component<AttributionsSectionTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<AttributionsSectionTemplateSpec>
{
  private _SectionTitle = this.getByRef('SectionTitle')!;
  private _TextContent = this.getByRef('TextContent')!;

  private textAnnouncer: LongTextAnnouncerService | null = null;

  get announce() {
    if (this.textAnnouncer) {
      this.textAnnouncer.startReading();
      return;
    }
    const title = translate('settings.Attributions.listTitle');
    return [title, translate('ttsPrompts.contentLoading')];
  }

  static override _template(): Lightning.Component.Template<AttributionsSectionTemplateSpec> {
    return {
      SectionTitle: {
        x: TITLE_X_PADDING,
        y: TITLE_Y_PADDING,
        text: { fontSize: 32, fontFace: getFontFaceFromStyle('regular') },
      },
      TextContent: {
        y: TEXT_CONTENT_Y_OFFSET,
        type: Scrollable,
        w: TEXT_CONTENT_WIDTH,
        h: TEXT_CONTENT_HEIGHT,
        backgroundAlpha: { focused: 0.15 },
      },
    };
  }

  override _setup() {
    const titleText = translate('settings.Attributions.listTitle');
    this._SectionTitle.patch({
      text: {
        text: titleText,
      },
    });

    const content: any = {
      flex: { direction: 'column' },
    };

    attributions.content.forEach((item: Attribution, index: number) => {
      content[`Attr-${index}-title`] = {
        text: {
          text: item.title,
          wordWrap: true,
          wordWrapWidth: TEXT_CONTENT_WORD_WRAP,
          fontSize: 26,
          fontFace: getFontFaceFromStyle('regular'),
          textColor: Colors('text').get(),
        },
      };
      const textArray = item.message
        .split(/(\n+)/)
        .map(text => {
          if (text.startsWith('\n')) {
            return text.slice(1); // remove the first \n
          } else {
            return text;
          }
        })
        .filter(text => !!text);
      textArray.forEach((text: string, j: number) => {
        content[`Attr-${index}-${j}-text`] = {
          text: {
            text: text.startsWith('\n') ? text.slice(1) : text,
            wordWrap: true,
            wordWrapWidth: TEXT_CONTENT_WORD_WRAP,
            fontSize: 20,
            fontFace: getFontFaceFromStyle('regular'),
            textColor: Colors('text').get(),
          },
        };
      });
    });

    this._TextContent.content = content;
  }

  override _getFocused() {
    return this._TextContent;
  }

  override _active() {
    this.handleTextData();
  }

  override _unfocus() {
    this.textAnnouncer?.stopReading();
  }

  private handleTextData() {
    const title = translate('settings.Attributions.listTitle');
    if (this.textAnnouncer === null) {
      let textData = '';
      attributions.content.forEach((item: Attribution) => {
        // adding period as titles don't have closing periods.
        textData = [textData, item.title, item.message]
          .filter(str => str !== '')
          .join('. ');
      });

      this.textAnnouncer = new LongTextAnnouncerService(this, textData, title);

      if (this.hasFocus()) {
        this.textAnnouncer.startReading();
      }
    } else {
      this.textAnnouncer?.startReading();
    }
  }
}
