import { Colors, Lightning } from '@lightningjs/sdk';
import { SpeechType } from '@lightningjs/ui-components';
import { getImageTextureObj } from 'support/generalUtils';
import { getFontFaceFromStyle } from 'support/textUtils';
import { STANDARD_BUTTON_SETTING } from 'support/buttonUtils';
import { HoverableListItem } from 'components/common/HoverableListItem';

const DEFAULT_REGULAR_HEIGHT = STANDARD_BUTTON_SETTING.height;
const DEFAULT_FONT_SIZE = STANDARD_BUTTON_SETTING.fontSize;
const DEFAULT_LINE_HEIGHT = STANDARD_BUTTON_SETTING.lineHeight;
const DEFAULT_PADDING = STANDARD_BUTTON_SETTING.padding;
const DEFAULT_PADDING_H = STANDARD_BUTTON_SETTING.paddingH;
const DEFAULT_MARGIN = STANDARD_BUTTON_SETTING.margin;
const DEFAULT_RADIUS = STANDARD_BUTTON_SETTING.radius;
const DEFAULT_FONT_COLOR = STANDARD_BUTTON_SETTING.fontColor;
const DEFAULT_BACKGROUND_COLOR = STANDARD_BUTTON_SETTING.backgroundColor;
const DEFAULT_FONT_BASELINE_RATIO = STANDARD_BUTTON_SETTING.fontBaselineRatio;

export interface FeaturedTileGoToButtonTemplateSpec
  extends Lightning.Component.TemplateSpec {
  height: number;
  title: SpeechType;
  label: string;
  action: string;
  actionArgs: unknown;
  backgroundColor: { focused?: number; unfocused?: number };
  fontColor: { focused?: number; unfocused?: number };
  fontSize: number;
  lineHeight: number;
  padding: number;
  paddingH: number;
  margin: number;
  minWidth: number;
  radius: number;
  startIcon: {
    focused?: string | Lightning.Element.PatchTemplate;
    unfocused?: string | Lightning.Element.PatchTemplate;
  };
  Container: {
    StartIcon: object;
    Label: object;
  };
}

/**
 * StartIconButton
 *
 * Low Performance Button that does not use flexbox for arranging button contents due to consideration of
 * Higher CPU utilization when using flexbox. This button assumes there is an Icon so it only adjust width
 * of button (presumably) once after Icon image texture has finished loading.
 */
export default class FeaturedTileGoToButton
  extends HoverableListItem<FeaturedTileGoToButtonTemplateSpec>
  implements
    Lightning.Component
      .ImplementTemplateSpec<FeaturedTileGoToButtonTemplateSpec>
{
  private _title: SpeechType | null = null;
  private _label = '';
  private _action = '';
  private _actionArgs: FeaturedTileGoToButtonTemplateSpec['actionArgs'];
  private _backgroundColor = {
    focused: Colors(DEFAULT_BACKGROUND_COLOR.focused).get(),
    unfocused: Colors(DEFAULT_BACKGROUND_COLOR.unfocused).get(),
  };

  private _fontColor = {
    focused: Colors(DEFAULT_FONT_COLOR.focused).get(),
    unfocused: Colors(DEFAULT_FONT_COLOR.unfocused).get(),
  };
  private _fontSize = DEFAULT_FONT_SIZE;
  private _lineHeight = DEFAULT_LINE_HEIGHT;
  private _padding = DEFAULT_PADDING;
  private _paddingH = DEFAULT_PADDING_H;

  /**
   * set this variable to override button height
   */
  private _height = 0;

  private _margin = DEFAULT_MARGIN;
  private _minWidth = 0;
  private _radius = DEFAULT_RADIUS;
  private _startIcon: FeaturedTileGoToButtonTemplateSpec['startIcon'] = {};

  private _Container = this.getByRef('Container')!;
  protected _StartIcon = this._Container.getByRef('StartIcon')!;
  protected _Label = this._Container.getByRef('Label')!;

  get height() {
    return this._height;
  }

  set height(height: number) {
    this._height = height;
    this.h = height;
  }

  get title() {
    return this._title ?? this.label;
  }

  set title(title: SpeechType) {
    this._title = title;
  }

  get label() {
    return this._label;
  }

  set label(value: string) {
    this._label = value;
    this._Label.patch({ text: { text: value } });
    this._Label.once('txLoaded', () => {
      this.updateButtonWidth();
    });
  }

  set action(value: string) {
    this._action = value;
  }

  get actionArgs() {
    return this._actionArgs;
  }

  set actionArgs(actionArgs: FeaturedTileGoToButtonTemplateSpec['actionArgs']) {
    this._actionArgs = actionArgs;
  }

  set position(value: { x?: number; y?: number; mount?: number }) {
    this.patch({ x: value.x, y: value.y, mount: value.mount });
  }

  set backgroundColor(value: { focused?: number; unfocused?: number }) {
    this._backgroundColor = {
      focused: value.focused ?? this._backgroundColor.focused,
      unfocused: value.unfocused ?? this._backgroundColor.unfocused,
    };

    if (this.hasFocus()) {
      this.patch({ color: this._backgroundColor.focused });
    } else {
      this.patch({ color: this._backgroundColor.unfocused });
    }
  }

  set fontColor(value: { focused?: number; unfocused?: number }) {
    this._fontColor = {
      focused: value.focused ?? this._fontColor.focused,
      unfocused: value.unfocused ?? this._fontColor.unfocused,
    };

    if (this.hasFocus()) {
      this._Label.patch({ text: { textColor: this._fontColor.focused } });
    } else {
      this._Label.patch({ text: { textColor: this._fontColor.unfocused } });
    }
  }

  set fontSize(value: number) {
    this._fontSize = value;
    this._Label.patch({
      text: {
        fontSize: value,
      },
    });

    this.updateButtonHeight();
  }

  set lineHeight(value: number) {
    this._lineHeight = value;
    this.updateButtonHeight();
  }

  /**
   * Padding Vertical
   *
   * get padding value applied to the area top and bottom of the icon and button.
   * @returns {number} padding in px
   */
  get padding(): number {
    return this._padding;
  }

  /**
   * Padding Vertical
   *
   * set padding value applied to the area top and bottom of the icon and button. Always Centered.
   * @param number padding in px
   */
  set padding(value: number) {
    this._padding = value;
    this.updateButtonHeight();
  }
  /**
   * Padding Horizontal
   *
   * get padding value applied to the area left and right of the icon and button.
   * @returns {number} paddingH in px
   */
  get paddingH(): number {
    return this._paddingH;
  }

  /**
   * Padding Horizontal
   * set padding value applied to the area left and right of the icon and button. Always Centered.
   * @param number paddingH in px
   */
  set paddingH(value: number) {
    this._paddingH = value;
    this.updateButtonWidth();
  }

  get margin() {
    return this._margin;
  }

  /**
   * margin between startIcon and text
   *
   * @param number - in px
   */
  set margin(value: number) {
    this._margin = value;
    this.updateButtonWidth();
  }

  get minWidth() {
    return this._minWidth;
  }

  set minWidth(minWidth: FeaturedTileGoToButtonTemplateSpec['minWidth']) {
    this._minWidth = minWidth;
    this.w = Math.max(this.w, minWidth);
  }

  set radius(value: number) {
    this._radius = value;
    this.updateButtonRadius();
  }

  get startIcon() {
    return this._startIcon;
  }

  set startIcon(startIcon: FeaturedTileGoToButtonTemplateSpec['startIcon']) {
    this._startIcon = startIcon;

    if (this.hasFocus()) {
      if (startIcon.focused) {
        this.setStartIcon(startIcon.focused);
      }
    } else {
      if (startIcon.unfocused) {
        this.setStartIcon(startIcon.unfocused);
      }
    }
  }

  static override _template(): Lightning.Component.Template<FeaturedTileGoToButtonTemplateSpec> {
    return {
      h: DEFAULT_REGULAR_HEIGHT,
      rect: true,
      color: Colors(DEFAULT_BACKGROUND_COLOR.unfocused).get(),
      shader: {
        type: Lightning.shaders.RoundedRectangle,
        radius: DEFAULT_RADIUS,
      },
      rtt: true,
      Container: {
        h: h => h,
        StartIcon: {
          mountY: 0.5,
          x: 0,
          y: h => h / 2,
        },
        Label: {
          mountY: 0.5,
          x: DEFAULT_FONT_SIZE + DEFAULT_MARGIN,
          y: h => h / 2,
          text: {
            fontSize: DEFAULT_FONT_SIZE,
            verticalAlign: 'middle',
            lineHeight: DEFAULT_LINE_HEIGHT,
            advancedRenderer: true,
            fontBaselineRatio: DEFAULT_FONT_BASELINE_RATIO,
            textColor: Colors(DEFAULT_FONT_COLOR.unfocused).get(),
            fontFace: getFontFaceFromStyle('bold'),
          },
        },
      },
    };
  }

  override _getFocused() {
    return this;
  }

  override _focus() {
    this.patch({
      color: this._backgroundColor.focused,
    });

    this._Label.patch({
      text: { textColor: this._fontColor.focused },
    });

    if (this.startIcon.focused) {
      this.setStartIcon(this.startIcon.focused);
    }
  }

  override _unfocus() {
    this.patch({
      color: this._backgroundColor.unfocused,
    });

    this._Label.patch({
      text: { textColor: this._fontColor.unfocused },
    });

    if (this.startIcon.unfocused) {
      this.setStartIcon(this.startIcon.unfocused);
    }
  }

  override _handleEnter() {
    this._action && this.signal(this._action, this.actionArgs);
  }

  private updateButtonHeight() {
    if (this.height) return;
    this.patch({
      h: this._lineHeight + 2 * this._padding,
    });
    this._Container.patch({
      h: this._lineHeight + 2 * this._padding,
    });
  }

  protected updateButtonWidth() {
    const hasStartIcon = !!this._StartIcon.texture?._source?.lookupId;
    if (!hasStartIcon) return;
    const newWidth =
      2 * this._paddingH +
      this._StartIcon.renderWidth +
      this._margin +
      this._Label.renderWidth;

    const width = Math.max(this.minWidth, newWidth);
    this.patch({ w: width });
    this._StartIcon.patch({ x: this._paddingH });
    this._Label.patch({
      x: this._paddingH + this._StartIcon.renderWidth + this._margin,
    });
  }

  private updateButtonRadius() {
    this.patch({
      shader: { radius: this._radius },
    });
  }

  private setIconHelper(
    ref: Lightning.Element,
    value: string | Lightning.Element.PatchTemplate,
  ) {
    if (typeof value === 'string') {
      if (value) {
        ref.patch(
          getImageTextureObj(value, this._lineHeight, this._lineHeight),
        );
      } else {
        ref.patch({ texture: undefined });
      }
    } else {
      ref.patch({ shader: undefined });
      ref.patch(value);
    }

    ref.once('txLoaded', () => {
      this.updateButtonWidth();
      ref.removeAllListeners('txUnloaded');
    });

    ref.once('txUnloaded', () => {
      ref.removeAllListeners('txLoaded');
    });
  }

  setStartIcon(value: string | Lightning.Element.PatchTemplate) {
    this.setIconHelper(this._StartIcon, value);
  }
}
