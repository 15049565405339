import { Colors, Lightning } from '@lightningjs/sdk';
import { Keyboard as BaseKeyboard, InputField } from '@lightningjs/ui';
import KeyboardConfiguration, {
  DEFAULT_KEYBOARD_LAYOUT,
} from 'components/common/input/KeyboardConfiguration';
import { translate } from 'support/translate';
import { constants } from 'aliases';
import {
  HoverableComponent,
  HoverableComponentSignalMap,
  HoverableComponentTypeConfig,
} from 'components/common/HoverableComponent';
import { KeyboardLayouts } from 'components/common/input/Keys';
import { getFocusDepth } from 'support/generalUtils';

export type InputChangedEvent = {
  previousInput: string;
  input: string;
};
export type InputCompleteEvent = Pick<InputChangedEvent, 'input'>;

interface KeyboardSignalMap extends HoverableComponentSignalMap {
  hideKeyboard: () => void;
  keyPressed: () => void;
  inputChanged: (event: InputChangedEvent) => void;
  inputComplete: (event: InputCompleteEvent) => void;
}

interface KeyboardTypeConfig extends HoverableComponentTypeConfig {
  SignalMapType: KeyboardSignalMap;
}

export interface KeyboardTemplateSpec extends Lightning.Component.TemplateSpec {
  inputField?: InputField;
  zIndexLocal: number;
  currentLayout: KeyboardLayouts;
  maxCharacters: number;

  HoverBlocker: typeof Lightning.Texture;
  Background: typeof Lightning.Texture;
  Keyboard: typeof BaseKeyboard;
}

const DEFAULT_WIDTH = 1920;
const DEFAULT_HEIGHT = 420;

const FOCUSED_Y = 660;
const UNFOCUSED_Y = 1080;

const KEYBOARD_WIDTH = 660;
const PIN_KEYBOARD_WIDTH = 191;
const KEYBOARD_HEIGHT = 292;

export default class Keyboard
  extends HoverableComponent<KeyboardTemplateSpec, KeyboardTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<KeyboardTemplateSpec>
{
  private _inputField: KeyboardTemplateSpec['inputField'];

  private _HoverBlocker = this.getByRef('HoverBlocker')!;
  private _Background = this.getByRef('Background')!;
  private _Keyboard = this.getByRef('Keyboard')!;

  // Internal properties
  private shouldAnnounceContext = false;

  get title() {
    // Prevent context from announcing when refreshing announcer
    if (!this.shouldAnnounceContext) return;
    return translate('keyboard.description');
  }

  set inputField(inputField: KeyboardTemplateSpec['inputField']) {
    this._inputField = inputField;
    this._Keyboard.inputField(inputField);
  }

  get inputField() {
    return this._inputField;
  }

  set zIndexLocal(zIndex: number) {
    this.patch({ zIndex });
    this._HoverBlocker.patch({ zIndex });
    this._Background.patch({ zIndex });
    this._Keyboard.patch({ zIndex });
  }

  set currentLayout(currentLayout: KeyboardLayouts) {
    let w = KEYBOARD_WIDTH;
    if (currentLayout === KeyboardLayouts.PIN) {
      w = PIN_KEYBOARD_WIDTH;
    }
    this._Keyboard.patch({ currentLayout, w });
  }

  set maxCharacters(maxCharacters: number) {
    this._Keyboard.patch({ maxCharacters });
  }

  static override _template(): Lightning.Component.Template<KeyboardTemplateSpec> {
    return {
      w: DEFAULT_WIDTH,
      y: UNFOCUSED_Y,
      HoverBlocker: {
        rect: true,
        w: (w: number) => w,
        h: 1080 - DEFAULT_HEIGHT,
        y: -FOCUSED_Y,
        alpha: constants.ui.invisible,
        visible: false,
        collision: true,
        zIndex: 1,
      },
      Background: {
        rect: true,
        color: Colors('background').alpha(0.85).get(),
        w: (w: number) => w,
        h: DEFAULT_HEIGHT,
        collision: true,
        zIndex: 1,
      },
      Keyboard: {
        type: BaseKeyboard,
        config: KeyboardConfiguration,
        currentLayout: DEFAULT_KEYBOARD_LAYOUT,
        x: (w: number) => w / 2,
        y: DEFAULT_HEIGHT / 2,
        w: KEYBOARD_WIDTH,
        h: KEYBOARD_HEIGHT,
        mount: 0.5,
        signals: {
          onInputChanged: true,
          onLeft: true,
          onRight: true,
          onInputComplete: true,
          onPinComplete: true,
        },
      },
    };
  }

  override _setup() {
    super._setup();
    this._Keyboard.navigationWrapAround = true;

    // Overwrite removeAt and addAt so we can use the input field's cursor index
    this._Keyboard.removeAt = this.removeAt.bind(this);
    this._Keyboard.addAt = this.addAt.bind(this);

    // Overwrite layout so focus position does not reset
    this._Keyboard.layout = this.layout.bind(this);
  }

  override _getFocused() {
    return this._Keyboard;
  }

  override _focus() {
    this.shouldAnnounceContext = true;
    this._HoverBlocker.visible = true;
    this.setSmooth('y', FOCUSED_Y);
  }

  override _unfocus() {
    this._HoverBlocker.visible = false;
    this.setSmooth('y', UNFOCUSED_Y);
  }

  override _handleBack() {
    this.signal('hideKeyboard');
  }

  override _handleExit() {
    this.signal('hideKeyboard');
  }

  override _handleClick(target: Lightning.Component) {
    if (target === this._HoverBlocker) {
      this.signal('hideKeyboard');
    }
  }

  private addAt(str: string, index: number) {
    const input = this._Keyboard._input;

    // Always use the input field's cursor index as the correct index if available
    const inputFieldIndex = this.inputField && this.inputField.cursorIndex;
    const finalIndex = inputFieldIndex ?? index;

    if (finalIndex > input.length - 1) {
      // @ts-ignore
      this._Keyboard.add(str);
    } else if (finalIndex > -1) {
      this._Keyboard._changeInput(
        input.substring(0, finalIndex) +
          str +
          input.substring(finalIndex, input.length),
      );
    }
  }

  private removeAt(index: number) {
    const input = this._Keyboard._input;

    // Always use the input field's cursor index as the correct index if available
    const inputFieldIndex = this.inputField && this.inputField.cursorIndex;
    const finalIndex = inputFieldIndex ?? index;

    if (finalIndex > input.length - 1) {
      this._Keyboard.remove();
    } else if (finalIndex > -1) {
      this._Keyboard._changeInput(
        input.substring(0, finalIndex - 1) +
          input.substring(finalIndex, input.length),
      );
    }
  }

  private layout(key: string) {
    // Do nothing if new layout is the same as current layout
    if (key === this._Keyboard._layout) return;

    this._Keyboard._layout = key;
    if (this._Keyboard.attached) this._Keyboard._update();
  }

  clearInput() {
    this._Keyboard.clear();
  }

  onInputChanged(event: InputChangedEvent) {
    this.signal('inputChanged', event);
    this.announceCurrentKey();
  }

  onLeft() {
    // @ts-ignore
    this.inputField && this.inputField!._handleLeft();
    this.announceCurrentKey();
  }

  onRight() {
    // @ts-ignore
    this.inputField && this.inputField!._handleRight();
    this.announceCurrentKey();
  }

  onInputComplete(event: InputCompleteEvent) {
    this.signal('inputComplete', event);
  }

  onPinComplete(event: InputCompleteEvent) {
    this.onInputComplete(event);
  }

  private announceCurrentKey() {
    this.shouldAnnounceContext = false;
    // @ts-ignore
    this.fireAncestors(
      '$announcerRefresh',
      getFocusDepth(this.application, this as Keyboard),
    );
  }
}
