import { AppData } from '@lightningjs/sdk';
import { loadPermutiveScript } from 'aliases';
import { Platform } from 'models/platforms/platform';
import { EpgChannel, LiveEventChannel, Video } from 'types/api/media';
import {
  getCurrentEpgProgram,
  getEpisode,
  isLiveEvent,
  isLiveProgram,
} from 'support/contentUtils';
import { Ad } from 'types/player';
import { DeviceInfo } from 'models/platforms/deviceInfo';
import { AbstractDeviceIntegration } from 'config/platforms/AbstractDeviceIntegration';

enum PermutiveContentType {
  VOD = 'VOD',
  LIVE = 'LIVE',
  FAST = 'FAST',
}

type ConstructorArgs = {
  deviceIntegration: AbstractDeviceIntegration;
};

export default class PermutiveAnalytics {
  private readonly permutiveInstance: any;
  private isInitialized = false;
  private domain: string;
  private device: string;

  private contentProps: {
    description?: string;
    duration?: number;
    video_id: string;
    name: string;
    created_at?: string;
    published_at?: string;
  } | null = null;

  static async AsyncConstruct({ deviceIntegration }: ConstructorArgs) {
    if (AppData === undefined) {
      throw new Error('AppData not set');
    }

    const { organizationId, workspaceId, apiKey } = AppData.permutive;

    const isTizen = deviceIntegration.getPlatform() === Platform.TIZEN;
    const permutiveInstance = await loadPermutiveScript(
      organizationId,
      workspaceId,
      apiKey,
      isTizen,
    );
    const deviceInfo = await deviceIntegration.getDeviceInfo();

    return new PermutiveAnalytics(permutiveInstance, isTizen, deviceInfo);
  }

  private constructor(
    permutiveInstance: any,
    isTizen: boolean,
    deviceInfo: DeviceInfo,
  ) {
    if (AppData === undefined) {
      throw new Error('AppData not set');
    }

    this.permutiveInstance = permutiveInstance;
    this.domain = AppData.permutive.domain;
    this.device = AppData.permutive.device;

    if (isTizen) {
      this.permutiveInstance.identify([
        {
          id: deviceInfo.adId,
          tag: 'tifa',
        },
      ]);
    }

    window.permutive.ready(() => {
      if (!AppData?.isProduction) {
        console.log('[Permutive]', 'Permutive is initialized');
      }

      this.isInitialized = true;
    }, 'initialised');

    if (!AppData?.isProduction) {
      window.permutive.ready(() => {
        console.log('[Permutive]', 'Permutive is realtime');
      }, 'realtime');

      this.permutiveInstance.on('Pageview', (event: any, error: any) => {
        if (error) {
          console.log('[Permutive]', 'Pageview error', error);
        } else {
          console.log('[Permutive]', 'Pageview was fired!', event);
        }
      });

      this.permutiveInstance.on('Videoview', (event: any, error: any) => {
        if (error) {
          console.log('[Permutive]', 'Videoview error', error);
        } else {
          console.log('[Permutive]', 'Videoview was fired!', event);
        }
      });
    }
  }

  private reportVod(
    video: Video,
    audioLanguage: string | null,
    consecutiveEpisodes: number,
  ) {
    this.contentProps = {
      description: video.description,
      duration: Number(video.durationSecs),
      video_id: video.guid,
      name: video.title,
      created_at: video.startTime,
      published_at: video.airDate,
    };

    this.permutiveInstance.addon('ctv', {
      videoProperties: {
        title: video.seriesName,
        genre: [video.genre],
        content_type: [PermutiveContentType.VOD],
        country: 'US',
        runtime: Number(video.durationSecs),
        age_rating: video.rating,
        original_language: 'en_US',
        season_number: video.season ? Number(video.season) : undefined,
        episode_number: video.episode ? Number(getEpisode(video)) : undefined,
        audio_language: audioLanguage ?? '',
        consecutive_episodes: consecutiveEpisodes,
        iab_categories: [], // TODO: TBD
      },
      client: this.getGenericClientProperties(),
      duration: Number(video.durationSecs) * 1000,
    });
  }

  private reportLive(live: LiveEventChannel, audioLanguage: string | null) {
    const genre = live.analytics.comscoreGenre;

    this.contentProps = {
      description: live.description,
      duration: Number(live.durationSecs),
      video_id: live.analytics.guid ?? '',
      name: live.title,
    };

    this.permutiveInstance.addon('ctv', {
      videoProperties: {
        title: live.title,
        genre: genre ? [genre] : undefined,
        content_type: [PermutiveContentType.LIVE],
        country: 'US',
        runtime: Number(live.durationSecs),
        original_language: 'en_US',
        audio_language: audioLanguage ?? '',
        iab_categories: [], // TODO: TBD
      },
      client: this.getGenericClientProperties(),
      duration: Number(live.durationSecs) * 1000,
    });
  }

  private reportEpgPlayback(channel: EpgChannel, audioLanguage: string | null) {
    const program = getCurrentEpgProgram(channel);

    const durationSecs = program?.durationSecs
      ? Number(program.durationSecs)
      : undefined;

    this.contentProps = {
      description: program?.description,
      video_id: program?.assetId ?? '',
      name: program?.title ?? '',
    };

    if (durationSecs) {
      this.contentProps!.duration = durationSecs;
    }

    this.permutiveInstance.addon('ctv', {
      videoProperties: {
        title: program?.title ?? channel.title,
        content_type: [PermutiveContentType.FAST],
        country: 'US',
        runtime: durationSecs,
        age_rating: program?.ratings,
        original_language: 'en_US',
        audio_language: audioLanguage ?? '',
        iab_categories: [], // TODO: TBD
      },
      client: this.getGenericClientProperties(),
      duration: durationSecs ? durationSecs * 1000 : durationSecs,
    });
  }

  reportPageChange(title: string) {
    this.permutiveInstance.addon('web', {
      page: {
        client: {
          title,
          ...this.getGenericClientProperties(),
        },
      },
    });
  }

  reportVideoView(
    content: Video | LiveEventChannel | EpgChannel,
    audioLanguage: string | null,
    consecutiveEpisodes: number,
  ) {
    if (isLiveProgram(content)) {
      this.reportEpgPlayback(content as EpgChannel, audioLanguage);
    } else if (isLiveEvent(content)) {
      this.reportLive(content as LiveEventChannel, audioLanguage);
    } else {
      this.reportVod(content as Video, audioLanguage, consecutiveEpisodes);
    }
  }

  reportAdPlay(ad: Ad) {
    const options = !AppData?.isProduction
      ? {
          success: function (event: any) {
            console.log('[Permutive]', 'VideoAdPlay as fired!', event);
          },
          error: function (event: any) {
            console.log('[Permutive]', 'VideoAdPlay error', event);
          },
        }
      : {};

    const adProps = {
      ad_id: ad.getAdId(),
      ad_system: ad.getAdSystem?.(),
      advertiser_name: ad.getAdvertiserName?.(),
      creative_id: ad.getCreativeId?.(),
      description: ad.getDescription?.(),
      duration: ad.getDuration?.(),
      deal_id: ad.getDealId?.(),
      title: ad.getTitle(),
    };

    const videoProps = {
      ...this.contentProps,
    };

    this.permutiveInstance.track(
      'VideoAdPlay',
      {
        ad: adProps,
        video: videoProps,
        client: this.getGenericClientProperties(),
      },
      options,
    );
  }

  private getGenericClientProperties() {
    return { domain: this.domain, type: this.device };
  }

  async getCohorts() {
    return new Promise<number[]>(resolve => {
      // handle permutive initialization issues
      if (!this.isInitialized) {
        resolve([]);
      }

      this.permutiveInstance.segments((segments: number[]) =>
        resolve(segments),
      );
    });
  }
}
