import { AppData } from '@lightningjs/sdk';
import { AbstractDeviceIntegration } from 'config/platforms/AbstractDeviceIntegration';
import StorageService from 'services/StorageService';
import ComscoreAnalytics from 'services/analytics/Comscore';
import MParticleAnalytics from 'services/analytics/MParticle';
import NielsenAnalytics from 'services/analytics/Nielsen';

export type Privacy = {
  privacyViewed: boolean; // viewed privacy setting
  optOut: boolean; // opt out to not selling
};

export type PrivacyConsent = 'opt-in' | 'opt-out';

type Ccpa = `1${'-' | 'Y' | 'N'}${'-' | 'Y' | 'N'}${'-' | 'Y' | 'N'}`;

type ConstructorArgs = {
  deviceIntegration: AbstractDeviceIntegration;
  storageService: StorageService;
};

type InitPrivacyStatusArgs = {
  comscore: ComscoreAnalytics;
  mParticle: MParticleAnalytics;
  nielsen: NielsenAnalytics;
};

enum CcpaStatus {
  OFF = '1YNN', // has not viewed (privacyViewed false)
  OFF_VIEWED = '1YNN', // navigating to the screen with the toggle does not provide sufficient notice per our legal counsel, so the string is not any different from OFF
  ON = '1YYN', // Viewed and Not Sell Data (privacyViewed true, optOut true)
}

enum rdpStatus {
  OFF = 0, // has not viewed (privacyViewed false)
  OFF_VIEWED = 0, // Viewed and Sell Data (privacyViewed true, optOut false/null)
  ON = 1, // Viewed and Not Sell Data (privacyViewed true, optOut true)
}
const PRIVACY_DEFAULT = {
  privacyViewed: true, // defaults to true
  optOut: false,
};

export default class PrivacyService {
  private readonly deviceIntegration: AbstractDeviceIntegration;
  private readonly storageService;
  private comscore: ComscoreAnalytics | null = null;
  private mParticle: MParticleAnalytics | null = null;
  private nielsen: NielsenAnalytics | null = null;

  private privacySetting: Privacy | undefined;

  constructor({ deviceIntegration, storageService }: ConstructorArgs) {
    this.deviceIntegration = deviceIntegration;
    this.storageService = storageService;
  }

  private lazyInit() {
    if (this.privacySetting) return;

    this.setDefault();
  }

  private updatePrivacySettings() {
    if (this.privacySetting !== undefined) {
      this.storageService.privacy.set(this.privacySetting);
    }
  }

  private updateAnalyticsPrivacyStatus(privacyConsent: PrivacyConsent) {
    if (!AppData?.isProduction) {
      console.log(
        `[Privacy][Privacy Service] updateAnalyticsPrivacyStatus "consent":${privacyConsent}`,
      );
    }
    this.comscore?.updatePrivacyStatus(privacyConsent);
    this.mParticle?.updatePrivacyStatus(privacyConsent);
    this.nielsen?.updatePrivacyStatus(privacyConsent);
  }

  setDefault() {
    const privacySetting = this.storageService.privacy.get();
    this.privacySetting = privacySetting ?? PRIVACY_DEFAULT;
  }

  /** Updates analytic's privacy status on app start */
  initPrivacyStatus({ comscore, mParticle, nielsen }: InitPrivacyStatusArgs) {
    this.lazyInit();

    this.comscore = comscore;
    this.mParticle = mParticle;
    this.nielsen = nielsen;

    const optOut = this.privacySetting!.optOut;
    this.updateAnalyticsPrivacyStatus(optOut ? 'opt-out' : 'opt-in');
  }

  setDoNotSell(optOut: boolean) {
    this.lazyInit();

    this.privacySetting!.optOut = optOut;

    this.updateAnalyticsPrivacyStatus(optOut ? 'opt-out' : 'opt-in');
    this.updatePrivacySettings();
  }

  getDoNotSell(): boolean {
    this.lazyInit();

    return !!this.deviceIntegration.getIsLat() || !!this.privacySetting!.optOut;
  }

  // The DNS settings in the app, it's independent of the device opt-out setting
  getAppDoNotSell(): boolean {
    this.lazyInit();

    return !!this.privacySetting!.optOut;
  }

  // GoogleIMA: Restricted Data Processing
  // https://developers.google.com/interactive-media-ads/docs/sdks/html5/dai/ccpa
  getRdpTag(): 0 | 1 {
    this.lazyInit();

    return !!this.deviceIntegration.getIsLat() || !!this.privacySetting!.optOut
      ? rdpStatus.ON
      : rdpStatus.OFF_VIEWED;
  }

  // GoogleIMA: IAB Signal
  // https://developers.google.com/interactive-media-ads/docs/sdks/html5/dai/ccpa
  getIabUsPrivacy(): Ccpa {
    this.lazyInit();

    return this.privacySetting!.optOut ? CcpaStatus.ON : CcpaStatus.OFF_VIEWED;
  }
}
