import { Platform } from 'models/platforms/platform';
import { AbstractDeviceIntegration } from 'config/platforms/AbstractDeviceIntegration';
import { Manufacturer } from 'models/platforms/manufacturer';
import { LGKeyMapping } from 'models/platforms/LGKeyMappings';
import { Registry } from '@lightningjs/sdk';
import { webOS } from 'aliases';
import { DeviceInfo } from 'models/platforms/deviceInfo';
import { debounce } from 'support/generalUtils';

const SCROLLING_TIMEOUT = 500;

export class LGDeviceIntegration extends AbstractDeviceIntegration {
  readonly platform = Platform.LG;

  private canScroll = true;
  private canHover = true;

  private debounceScrolling = debounce(
    this.handleScrollingStopped.bind(this),
    SCROLLING_TIMEOUT,
  );

  constructor() {
    super();
    this.deviceInfo.manufacturer = Manufacturer.LG;
  }

  override async getDeviceInfo(): Promise<DeviceInfo> {
    let adId = this.deviceInfo.adId;

    // we don't have access to the LG ad id, so we will use the device ID
    if (!adId) {
      adId = this.deviceId;
    }

    return { ...this.deviceInfo, adId };
  }

  override getDeviceId(): string {
    return this.deviceId;
  }

  override getPerformanceMode(): boolean {
    return false;
  }

  override getAppId(): string {
    return webOS.fetchAppId();
  }

  override async load() {
    const fetchDeviceInfo = new Promise<void>(resolve => {
      webOS.deviceInfo(async (device: any) => {
        this.deviceInfo.model = device.modelName;
        this.deviceInfo.osVersion = device.sdkVersion;
        this.deviceInfo.ip = await this.getDeviceIp();
        resolve();
      });
    });

    this.deviceId = await this.fetchDeviceId();
    this._ccSettings.enabled = await this.getCcOnOffSettings();
    await fetchDeviceInfo;

    // Listen for deep links
    Registry.addEventListener(
      document,
      'webOSRelaunch',
      this.handleDeepLink.bind(this),
    );

    this.setupScrollWheelHandling();
  }

  override beforeAppClose(callback: (showPopUp: boolean) => void): void {
    // for webOS TV 5 or lower, exit the app without displaying confirmation popup.
    // otherwise, display the popup
    const osVersion = parseInt(this.deviceInfo.osVersion[0]!);
    callback(osVersion >= 6);
  }

  override closeApp(): void {
    window.close();
  }

  override handleDeepLink(): boolean {
    const osVersion = parseInt(this.deviceInfo.osVersion[0]!);
    // use webOSSystem for webOS TV 5.0 or higher, else use PalmSystem
    const launchParams =
      osVersion >= 5
        ? (window as any).webOSSystem.launchParams
        : (window as any).PalmSystem.launchParams;
    if (launchParams) {
      const params = JSON.parse(launchParams);
      if (params.contentTarget) {
        // Assume URL will be in the form `[RoutePath]/[...params]`
        this.handleDeepLinkURL(params.contentTarget);
        return true;
      } else {
        // app is just relaunched nothing more, ignore this
      }
    }
    return false;
  }
  override handleNetworkChange(callback: (arg: boolean) => void): void {
    return;
  }
  override setScreenSaver(state: boolean): void {
    return;
  }
  override getAnnouncerEnabled(): boolean {
    return false;
  }

  override handleAnnouncerChange(callback: (arg: boolean) => void): void {
    webOS.service.request('luna://com.webos.settingsservice', {
      method: 'getSystemSettings',
      parameters: {
        category: 'option',
        keys: ['audioGuidance'],
      },
      onSuccess: (inResponse: any) => {
        callback(inResponse?.settings?.audioGuidance === 'on');
      },
      onFailure: (inError: any) => {
        console.log("Failed to get settings' value");
        console.log('[' + inError.errorCode + ']: ' + inError.errorText);
        callback(false);
      },
    });
  }

  override getKeyMapping(): Record<number, string> {
    return LGKeyMapping;
  }

  override hoverDisabled(): boolean {
    return !this.canHover;
  }

  private fetchDeviceId(): Promise<string> {
    return new Promise((resolve, reject) => {
      webOS.service.request('luna://com.webos.service.sm', {
        method: 'deviceid/getIDs',
        parameters: {
          idType: ['LGUDID'],
        },
        onSuccess: (inResponse: any) => {
          resolve(inResponse.idList[0].idValue);
        },
        onFailure: (inError: any) => {
          console.log('Failed to get system ID information');
          console.log('[' + inError.errorCode + ']: ' + inError.errorText);
          reject(inError);
        },
      });
    });
  }

  private getCcOnOffSettings(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      webOS.service.request('luna://com.webos.settingsservice', {
        method: 'getSystemSettings',
        parameters: {
          category: 'caption',
          keys: ['captionEnable'],
        },
        onSuccess: (inResponse: any) => {
          resolve(inResponse.settings.captionEnable === 'on');
        },
        onFailure: function (inError: any) {
          console.log(
            `[LG] Failed to get CC settings value [${inError.errorCode}]: ${inError.errorText}`,
          );
          reject(inError);
        },
      });
    });
  }

  private getDeviceIp(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      webOS.service.request('luna://com.palm.connectionmanager', {
        method: 'getStatus',
        onSuccess: (inResponse: any) => {
          resolve(
            inResponse.wifi.ipAddress || inResponse.wired.ipAddress || '',
          );
        },
        onFailure: (inError: any) => {
          console.log('Failed to get network state');
          console.log('[' + inError.errorCode + ']: ' + inError.errorText);
          reject(inError);
        },
      });
    });
  }

  private handleScrollingStopped() {
    this.canHover = true;
  }

  private setupScrollWheelHandling() {
    Registry.addEventListener(document, 'wheel', (event: WheelEvent) => {
      if (!this.canScroll) return;
      this.canScroll = true;

      // if we're scrolling we should disable hovering so we don't hover items while scrolling
      this.canHover = false;
      this.debounceScrolling();

      const { deltaY } = event;

      const keyName = deltaY > 0 ? 'Down' : 'Up';

      const keyCode = Number(
        Object.keys(LGKeyMapping).find(
          key => LGKeyMapping[Number(key)] === keyName,
        ),
      );

      const keyEvent = new KeyboardEvent('keydown', {
        key: keyName,
        code: keyName,
        keyCode: keyCode,
        which: keyCode,
        bubbles: true,
        cancelable: true,
      });

      document.dispatchEvent(keyEvent);
    });
  }
}
