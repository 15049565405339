import axios, {
  AxiosError,
  AxiosRequestHeaders,
  AxiosResponse,
  Method,
  RawAxiosRequestHeaders,
} from 'axios';
import ApiServiceError from 'support/ApiServiceError';
import { AppData } from '@lightningjs/sdk';
import { shouldUseDevAccessHeader } from 'support/appUtils';

export enum Endpoints {
  CACHES = 'images/c/headers/',
  JWT = 'video/bc-token/?pro=widevine',
  CONFIG = 'feed/app-2/config/',
  SWIMLANES = 'feed/app-2/landing/swimlanes/',
  EPG = 'feed/app-2/landing/epg/',
  LIVE_EVENT = 'feed/app-2/live-event/',
  SHOWS = 'feed/app-2/shows/',
  VIDEOS = 'feed/app-2/videos/',
  VIDEO_METADATA = 'feed/app-2/video-meta/',
  VIDEOS_METADATA = 'feed/app-2/videos-meta/',
  TERMS_OF_USE = 'feed/app-2/content/terms-of-use/',
  PRIVACY_POLICY = 'feed/app-2/content/privacy-policy/',
  NIELSEN_MEASUREMENT = 'feed/app-2/content/nielsen-measurement/',
}

export type HttpOptions = {
  data?: unknown;
  params?: unknown;
  headers?: AxiosRequestHeaders;
};

export default class CwApiClient {
  private basePath: string | null = null;
  private auth: string | null = null;

  async request<TResponse = unknown>(
    method: Method,
    endpoint: string,
    options?: HttpOptions,
  ) {
    this.lazyInit();
    const optionsHeaders = options?.headers;
    let headers: RawAxiosRequestHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...optionsHeaders,
    };

    const authHeader = this.getAuthHeader();
    if (authHeader) {
      headers = { ...headers, ...authHeader };
    }

    const devAccessHeader = this.getDevAccessHeader();
    if (devAccessHeader) {
      headers = { ...headers, ...devAccessHeader };
    }

    try {
      return await axios.request<any, AxiosResponse<TResponse, any>>({
        baseURL: this.basePath!,
        url: endpoint,
        method,
        headers,
        data: options?.data,
        params: options?.params,
        responseType: 'json',
      });
    } catch (e) {
      throw new ApiServiceError(e as AxiosError, endpoint);
    }
  }

  // We initialize lazily to wait for AppData to be constructed
  private lazyInit() {
    if (this.basePath !== null || this.auth !== null) return;
    this.basePath = AppData?.api.baseUrl ?? '';

    const { username, password } = AppData?.api ?? {};
    if (!username && !password) return;

    const authToken = btoa(`${username}:${password}`);
    this.auth = `Basic ${authToken}`;
  }

  private getAuthHeader() {
    const auth = this.auth;
    return auth ? { Authorization: auth } : null;
  }

  private getDevAccessHeader = () => {
    if (!shouldUseDevAccessHeader()) return null;

    const { header, key } = AppData!.devAccessHeader;
    return { [header]: key };
  };
}
