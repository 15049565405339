import {
  KeyboardLayouts,
  Key,
  IconKey,
  LayoutKey,
} from 'components/common/input/Keys';

export const DEFAULT_KEYBOARD_LAYOUT = KeyboardLayouts.LOWERCASE_ABC;
const DEFAULT_SPACING = 10;

const SPACE_BUTTON_WIDTH = 190;
const SEARCH_BUTTON_WIDTH = 124;

const BACKSPACE_KEY_SIZING = {
  width: 29,
  height: 22,
};

const ARROW_KEY_SIZING = {
  width: 9,
  height: 14,
};

const ENTER_KEY_SIZING = {
  width: 26,
  height: 26,
};

export default {
  // Ignoring linter for layout so content can all be on one line
  // prettier-ignore
  layouts: {
    [KeyboardLayouts.LOWERCASE_ABC]: [
      ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
      ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', ','],
      [`Layout:${KeyboardLayouts.UPPERCASE_ABC}`, 'z', 'x', 'c', 'v', 'b', 'n', 'm', '.', 'Backspace'],
      [`Layout:${KeyboardLayouts.NUMERIC}`, 'Left', 'Right', 'Space:space', '-', '_', 'InputComplete:Enter'],
    ],
    [KeyboardLayouts.UPPERCASE_ABC]: [
      ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
      ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', ','],
      [`Layout:${KeyboardLayouts.LOWERCASE_ABC}`, 'Z', 'X', 'C', 'V', 'B', 'N', 'M', '.', 'Backspace'],
      [`Layout:${KeyboardLayouts.NUMERIC}`, 'Left', 'Right', 'Space:space', '-', '_', 'InputComplete:Enter'],
    ],
    [KeyboardLayouts.NUMERIC]: [
      ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
      ['@', '#', '$', '_', '&', '-', '+', '(', ')', '/'],
      [`Layout:${KeyboardLayouts.SYMBOLS}`, '*', '"', '\'', ':', ';', '!', '?', '%', 'Backspace'],
      [`Layout:${KeyboardLayouts.LOWERCASE_ABC}`, 'Left', 'Right', 'Space:space', '.', ',', 'InputComplete:Enter'],
    ],
    [KeyboardLayouts.SYMBOLS]: [
      ['~', '`', '|', '•', '√', 'π', '÷', 'x', '¶', '∆'],
      ['£', '¢', '€', '¥', '^', '˚', '=', '{', '}', ''],
      [`Layout:${KeyboardLayouts.NUMERIC}`, '%', '©', '®', '✔', '[', ']', '<', '>', 'Backspace'],
      [`Layout:${KeyboardLayouts.LOWERCASE_ABC}`, 'Left', 'Right', 'Space:space', '.', ',', 'InputComplete:Enter'],
    ],
    [KeyboardLayouts.PIN]: [
      ['1','2','3'],
      ['4','5','6'],
      ['7','8','9'],
      ['0','Backspace','PinComplete:Enter'],
    ],
  },
  styling: {
    horizontalSpacing: DEFAULT_SPACING,
    verticalSpacing: DEFAULT_SPACING,
  },
  buttonTypes: {
    default: {
      type: Key,
    },
    Backspace: {
      type: IconKey,
      icon: 'static/images/keyboard/keyboard-backspace.svg',
      iconSizing: BACKSPACE_KEY_SIZING,
    },
    Layout: {
      type: LayoutKey,
    },
    Left: {
      type: IconKey,
      icon: 'static/images/keyboard/keyboard-left.svg',
      iconSizing: ARROW_KEY_SIZING,
    },
    Right: {
      type: IconKey,
      icon: 'static/images/keyboard/keyboard-right.svg',
      iconSizing: ARROW_KEY_SIZING,
    },
    InputComplete: {
      type: IconKey,
      w: SEARCH_BUTTON_WIDTH,
      icon: 'static/images/keyboard/keyboard-enter.svg',
      iconSizing: ENTER_KEY_SIZING,
    },
    PinComplete: {
      type: IconKey,
      icon: 'static/images/keyboard/keyboard-pin-enter.svg',
      iconSizing: ENTER_KEY_SIZING,
    },
    Space: {
      type: Key,
      w: SPACE_BUTTON_WIDTH,
    },
  },
};
