import { AppData } from '@lightningjs/sdk';
import jwtDecode from 'jwt-decode';
import { constants } from 'aliases';
import CwApiClient, { Endpoints } from 'services/CwApiClient';
import { secondsToMilliseconds } from 'support/dateUtils';

const client = new CwApiClient();

type DecodedJWTToken = {
  accid: string;
  aud: string;
  exp: number;
  iat: number;
  pro: string;
};

export default class BrightcoveService {
  private jwtToken: string | undefined;

  private shouldUpdateJwtToken() {
    const jwtToken = this.jwtToken;
    if (!jwtToken) return true;

    try {
      const currentDate = new Date();
      const parsedJwtToken = jwtDecode(jwtToken) as DecodedJWTToken;
      // JWT expiry date is in seconds since epoch
      const tokenExpiryMs = secondsToMilliseconds(parsedJwtToken.exp);
      const expiryDate = new Date(tokenExpiryMs);

      return currentDate >= expiryDate;
    } catch (e) {
      return true;
    }
  }

  private async fetchJwtToken(): Promise<string | undefined> {
    try {
      const response = await client.request<Record<string, string>>(
        'GET',
        Endpoints.JWT,
      );
      return response.data?.value;
    } catch (e) {
      console.error(e);
    }
  }

  private async lazyUpdateJwtToken() {
    const shouldUpdateJwtToken = this.shouldUpdateJwtToken();
    if (!shouldUpdateJwtToken) return;

    this.jwtToken = await this.fetchJwtToken();
  }

  private getDRMLicensePath(contentId: string) {
    const { basePath } = constants.brightcove.playback;
    const accountId = AppData?.brightcove.accountId;
    return `${basePath}/accounts/${accountId}/videos/${contentId}/licenses/cenc/widevine`;
  }

  private async getDRMLicenseParams() {
    await this.lazyUpdateJwtToken();

    const jwtToken = this.jwtToken;
    if (!jwtToken) return undefined;

    const params = { bcov_auth: jwtToken };
    return new URLSearchParams(params).toString();
  }

  async getDRMLicenseURL(contentId: string) {
    const basePath = this.getDRMLicensePath(contentId);
    const params = await this.getDRMLicenseParams();
    if (!params) return undefined;

    return `${basePath}?${params}`;
  }
}
