import { AppData } from '@lightningjs/sdk';
import { EpgChannel, LiveEventChannel, Video } from 'types/api/media';
import { isLiveEvent, isLiveProgram } from 'support/contentUtils';
import { AbstractDeviceIntegration } from 'config/platforms/AbstractDeviceIntegration';

type ConstructorArgs = {
  deviceIntegration: AbstractDeviceIntegration;
};

// https://apis.support.brightcove.com/data-collection/getting-started/overview-data-collection-api-v2.html#video-cloud-events
export enum BrightcoveVideoEvents {
  VIDEO_VIEW = 'video_view',
}

export default class BrightcoveAnalytics {
  private sessionId = '';

  private readonly deviceIntegration: AbstractDeviceIntegration;

  private isLive = false;
  private contentMetaData:
    | {
        id: string;
        title: string;
        duration?: string;
      }
    | undefined;
  private videoViewReported = false;

  constructor({ deviceIntegration }: ConstructorArgs) {
    if (AppData === undefined) {
      throw new Error('AppData not set');
    }

    this.deviceIntegration = deviceIntegration;
  }

  private lazyUpdateSessionId() {
    if (!this.sessionId) {
      const now = new Date().toISOString();
      const rand = Math.random() * 1000000;
      this.sessionId = Math.floor(rand) + '_' + now;
    }
  }

  private async createRequest(event: BrightcoveVideoEvents, payload: any) {
    if (!AppData) return;

    this.lazyUpdateSessionId();

    const params = new URLSearchParams({
      account: AppData.brightcove.accountId,
      session: this.sessionId,
      domain: 'videocloud',
      event,
      ...payload,
    });

    const request = `${AppData.brightcove.analyticsUrl}?${params.toString()}`;

    // To avoid CORS issues, we should set the request URL as the src value
    // for a new image element rather than XMLHttpRequest
    const img = new Image();
    img.src = request;
  }

  setContentMetadata(content: Video | LiveEventChannel | EpgChannel) {
    if (isLiveProgram(content)) {
      this.setEpgProgramMetadata(content as EpgChannel);
    } else if (isLiveEvent(content)) {
      this.setLiveMetadata(content as LiveEventChannel);
    } else {
      this.setVideoMetadata(content as Video);
    }
  }

  private setVideoMetadata(content: Video) {
    this.isLive = false;
    this.contentMetaData = {
      id: content.id,
      title: content.title,
      duration: content.durationSecs,
    };
  }

  private setLiveMetadata(content: LiveEventChannel) {
    this.isLive = true;
    this.contentMetaData = {
      id: content.internalSlug,
      title: content.title,
    };
  }

  private setEpgProgramMetadata(content: EpgChannel) {
    this.isLive = true;
    this.contentMetaData = {
      id: content.id,
      title: content.title,
    };
  }

  reportVideoView(startTime: number, eventType: 'START' | 'RESUME') {
    // The Brightcove `video_view` event should only be fired once, and its timing depends on the presence of pre-roll ads:
    //
    // - If a pre-roll ad exists:
    //   - Fire the `video_view` event only after the ad finishes, i.e., when the first `RESUME` event occurs.
    //
    // - If there is no pre-roll ad or the video’s start time > 0:
    //   - Fire the `video_view` event immediately when playback begins.

    if (this.videoViewReported) return;

    if (
      this.isLive ||
      (eventType === 'START' && startTime > 0) ||
      eventType === 'RESUME'
    ) {
      const payload = {
        video: this.contentMetaData?.id,
        video_name: this.contentMetaData?.title,
        ...(!this.isLive && {
          // VOD only:
          start_time_ms: startTime,
          video_duration: this.contentMetaData?.duration,
        }),
      };
      this.createRequest(BrightcoveVideoEvents.VIDEO_VIEW, payload);
      this.videoViewReported = true;
    }
  }

  reportEnd() {
    this.contentMetaData = undefined;
    this.videoViewReported = false;
    this.isLive = false;
    this.sessionId = '';
  }
}
