import { Colors, Lightning } from '@lightningjs/sdk';
import { HoverableComponent } from 'components/common/HoverableComponent';

export interface ToggleButtonTemplateSpec
  extends Lightning.Component.TemplateSpec {
  isActive: boolean;
  OuterCircle: Lightning.Component;
  InnerCircle: Lightning.Component;
}

const OUTER_CIRCLE_DIAMETER = 34;
const OUTER_CIRCLE_WIDTH = 4;
const INNER_CIRCLE_DIAMETER = 18;

export default class ToggleButton
  extends HoverableComponent<ToggleButtonTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<ToggleButtonTemplateSpec>
{
  private _InnerCircle = this.getByRef('InnerCircle')!;

  private _isActive = false;

  static get w() {
    return OUTER_CIRCLE_DIAMETER;
  }

  set isActive(isActive: boolean) {
    this._isActive = isActive;
    this._InnerCircle.patch({ visible: isActive });
  }

  get isActive() {
    return this._isActive;
  }

  static override _template(): Lightning.Component.Template<ToggleButtonTemplateSpec> {
    return {
      w: OUTER_CIRCLE_DIAMETER - 2 * OUTER_CIRCLE_WIDTH,
      h: OUTER_CIRCLE_DIAMETER - 2 * OUTER_CIRCLE_WIDTH,
      OuterCircle: {
        w: w => w + 2 * OUTER_CIRCLE_WIDTH,
        h: h => h + 2 * OUTER_CIRCLE_WIDTH,
        x: -OUTER_CIRCLE_WIDTH,
        y: -OUTER_CIRCLE_WIDTH,
        rect: true,
        color: Colors('transparent').get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: OUTER_CIRCLE_DIAMETER / 2,
          stroke: OUTER_CIRCLE_WIDTH,
          strokeColor: Colors('white').get(),
        },
      },
      InnerCircle: {
        visible: false,
        mount: 0.5,
        x: w => w / 2,
        y: h => h / 2,
        w: INNER_CIRCLE_DIAMETER,
        h: INNER_CIRCLE_DIAMETER,
        rect: true,
        color: Colors('white').get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: INNER_CIRCLE_DIAMETER / 2,
        },
      },
    };
  }

  override _setup() {
    super._setup();
    this._InnerCircle.patch({ visible: this._isActive });
  }
}
