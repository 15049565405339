import { Lightning } from '@lightningjs/sdk';
import { HoverableComponent } from 'components/common/HoverableComponent';

const SPACING = 37;

export interface ActionsListTemplateSpec
  extends Lightning.Component.TemplateSpec {
  index: number;
  length: number;
  spacing: number;
  items: Lightning.Element.PatchTemplate[];
}

export default class ActionsList
  extends HoverableComponent<ActionsListTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<ActionsListTemplateSpec>
{
  private _index = -1;
  private _spacing = SPACING;

  get index() {
    return this._index;
  }

  set index(index: ActionsListTemplateSpec['index']) {
    this._index = index;
  }

  get length() {
    return this.children.length;
  }

  get spacing() {
    return this._spacing;
  }

  set spacing(spacing: ActionsListTemplateSpec['spacing']) {
    this._spacing = spacing;
  }

  get items() {
    return this.children;
  }

  set items(items: ActionsListTemplateSpec['items']) {
    this.childList.clear();
    items.forEach(item => this.addAction(item));
  }

  static override _template(): Lightning.Component.Template<ActionsListTemplateSpec> {
    return {
      flex: { direction: 'row' },
    };
  }

  override _inactive() {
    this.index = this.children.length ? 0 : -1;
  }

  override _getFocused(): Lightning.Component {
    if (this.index < 0) {
      return this;
    } else {
      return this.children[this.index] as Lightning.Component;
    }
  }

  override _handleLeft() {
    if (this.index > 0) {
      this.index -= 1;
    } else {
      return false;
    }
  }

  override _handleRight() {
    if (this.index < this.children.length - 1) {
      this.index += 1;
    } else {
      return false;
    }
  }

  addAction(action: Lightning.Element.PatchTemplate) {
    this.add({
      ...action,
      flexItem: { marginLeft: this.children.length ? this.spacing : 0 },
    });
    this.updateIndex();
  }

  getAction(index: number) {
    return this.children[index];
  }

  removeAction(index: number) {
    this.children.splice(index, 1);
    this.updateIndex();
  }

  clearActions() {
    this.children = [];
    this.index = -1;
  }

  updateIndex() {
    if (this.children.length) {
      this.index = this.index === -1 ? 0 : this.index;
    } else {
      this.index = -1;
    }
  }

  $onHover(target: unknown) {
    if (!(target instanceof Lightning.Element)) return;

    /** Because ActionsList isn't an actual CollectionWrapper and children array do not have numbered index
     *  for each individual items for HoverableListItem to leverage off CollectionWrapperWithSpeech's setItemHelper,
     *  we need to find the exact index within the children's array the current target is in, then call refocus() to
     *  let ActionsList::_getFocused() to handle the focus logic
     */
    const index = this.children.findIndex(c => c && c.id === target.id);
    if (index !== -1) {
      this.index = index;
      this._refocus();
    }
  }
}
