import { Colors, Lightning, Registry } from '@lightningjs/sdk';
import Tile, { TileTemplateSpec } from 'components/common/tiles/Tile';
import { LiveEvent } from 'types/api/media';
import { getFontFaceFromStyle } from 'support/textUtils';
import { constants } from 'aliases';
import LiveEventOverlay from 'components/common/LiveEventOverlay';
import { getLiveEventStatus } from 'support/contentUtils';
import { getFocusDepth } from 'support/generalUtils';

export interface LiveEventTileTemplateSpec extends TileTemplateSpec {
  data: LiveEvent;

  Overlay: typeof LiveEventOverlay;
  TextWrapper: {
    Title: Lightning.textures.TextTexture;
    SubTitle: Lightning.textures.TextTexture;
  };
}

const TILE_WIDTH = 400;
const TILE_HEIGHT = 297;

const TILE_HIGHLIGHT_WIDTH = 400;
const TILE_HIGHLIGHT_HEIGHT = 225;

const TEXT_WRAPPER_HEIGHT = 57;
const TEXT_WRAPPER_Y = 240;

const EVENT_TEXT_FONT_SIZE = 23;

const OVERLAY_SPACING = 4;

const REFRESH_TIME_OFFSET = 1000;
const MAX_TIMEOUT = constants.timers.timeoutMaximum;

export default class LiveEventTile extends Tile<LiveEventTileTemplateSpec> {
  private refreshTimeout: number | null = null;

  protected _TextWrapper = this.getByRef('TextWrapper')!;
  protected _Title = this._TextWrapper.getByRef('Title')!;
  protected _SubTitle = this._TextWrapper.getByRef('SubTitle')!;

  protected _Overlay = this.getByRef('Overlay')!;
  protected _LiveIcon = this._Overlay.getByRef('LiveIcon')!;

  override get title() {
    const overlayStatus = this._Overlay.title;
    const title = this._Title.text?.text ?? '';
    const subtitle = this._SubTitle.text?.text ?? '';

    return [overlayStatus, title, subtitle];
  }

  override set data(data: LiveEvent) {
    super.data = data;

    this._Title.patch({ text: { text: data.title } });
    this._SubTitle.patch({ text: { text: data.tuneIn } });

    this.updateEventStatus();
    this.updateOverlay();
  }

  static override get width() {
    return TILE_WIDTH;
  }

  static override get height() {
    // Value will need to be larger to account for text underneath
    return TILE_HEIGHT;
  }

  static override get highlightWidth() {
    return TILE_HIGHLIGHT_WIDTH;
  }

  static override get highlightHeight() {
    return TILE_HIGHLIGHT_HEIGHT;
  }

  static override _template() {
    return {
      ...super._template(),
      Overlay: {
        type: LiveEventOverlay,
        mountX: 1,
        x: (w: number) => w - OVERLAY_SPACING,
        y: OVERLAY_SPACING,
      },
      TextWrapper: {
        y: TEXT_WRAPPER_Y,
        w: TILE_WIDTH,
        h: TEXT_WRAPPER_HEIGHT,
        Title: {
          text: {
            fontSize: EVENT_TEXT_FONT_SIZE,
            maxLines: 1,
            textColor: Colors('text').get(),
            fontFace: getFontFaceFromStyle('bold'),
            wordWrapWidth: TILE_WIDTH,
          },
        },
        SubTitle: {
          y: 30,
          text: {
            fontSize: EVENT_TEXT_FONT_SIZE,
            maxLines: 1,
            textColor: Colors('text').get(),
            fontFace: getFontFaceFromStyle('light'),
            wordWrapWidth: TILE_WIDTH,
          },
        },
      },
    };
  }

  override _active() {
    super._active();

    this.updateEventStatus();
  }

  override _disable() {
    super._disable();

    this.clearRefreshTimeout();
  }

  private clearRefreshTimeout() {
    if (this.refreshTimeout !== null) {
      Registry.clearTimeout(this.refreshTimeout);
      this.refreshTimeout = null;
    }
  }

  private updateEventStatus() {
    const { eventStatus, refreshDate } = getLiveEventStatus(this._data);

    this.clearRefreshTimeout();

    if (refreshDate) {
      const refreshTime =
        refreshDate.getTime() - new Date().getTime() + REFRESH_TIME_OFFSET; // add offset to ensure event status change

      if (refreshTime <= MAX_TIMEOUT) {
        this.refreshTimeout = Registry.setTimeout(() => {
          this.updateEventStatus();
          this.fireAncestors(
            '$announcerRefresh',
            getFocusDepth(
              // @ts-ignore
              this.application,
              this as LiveEventTile,
            ),
          );
        }, refreshTime);
      }
    }

    this._Overlay.patch({ eventStatus });
  }

  private updateOverlay() {
    const overlays = this._data.overlays;
    this._Overlay.patch({ overlays });
  }
}
