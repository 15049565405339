import { Lightning, Colors } from '@lightningjs/sdk';
import InputField from 'components/common/input/InputField';
import { translate } from 'support/translate';
import { getImageTextureObj } from 'support/generalUtils';
import { HoverableComponent } from 'components/common/HoverableComponent';

export interface SearchInputFieldTemplateSpec
  extends Lightning.Component.TemplateSpec {
  inputField: InputField;
  toggleCursor?: boolean;

  Background: typeof Lightning.Texture;
  Input: {
    SearchIcon: typeof Lightning.Texture;
    InputField: typeof InputField;
  };
}

const DEFAULT_WIDTH = 1564;
const DEFAULT_HEIGHT = 73;

const BACKGROUND_RADIUS = 8;
const BACKGROUND_STROKE = 4;

const SEARCH_ICON_SRC = 'static/images/navigation/search-icon-inactive.svg';
const SEARCH_ICON_WIDTH = 31;
const SEARCH_ICON_HEIGHT = 31;
const SEARCH_ICON_MARGIN_RIGHT = 23;

const INPUT_PADDING_LEFT = 20;
const INPUT_FONT_SIZE = 32;

const INPUT_FIELD_WIDTH = 1400;
const INPUT_FIELD_HEIGHT = 51;
const INPUT_FIELD_MARGIN_TOP = 4;

const CURSOR_WIDTH = 3;
const CURSOR_HEIGHT = 51;
const CURSOR_Y = -4;

export default class SearchInputField
  extends HoverableComponent<SearchInputFieldTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<SearchInputFieldTemplateSpec>
{
  // Keep an internal version of cursor toggled so we don't have to rely on InputField's
  // value since the cursor can blink while the input is unfocused
  private _cursorToggled: SearchInputFieldTemplateSpec['toggleCursor'];

  private _Background = this.getByRef('Background')!;
  private _Input = this.getByRef('Input')!;
  private _SearchIcon = this._Input.getByRef('SearchIcon')!;
  private _InputField = this._Input.getByRef('InputField')!;

  get title() {
    return translate('ttsPrompts.textInput');
  }

  get inputField() {
    return this._InputField;
  }

  set toggleCursor(toggle: SearchInputFieldTemplateSpec['toggleCursor']) {
    if (this._cursorToggled === toggle) return;
    this._cursorToggled = toggle;
    this._InputField.toggleCursor(toggle);
  }

  static override _template(): Lightning.Component.Template<SearchInputFieldTemplateSpec> {
    const searchIconTexture = getImageTextureObj(
      SEARCH_ICON_SRC,
      SEARCH_ICON_WIDTH,
      SEARCH_ICON_HEIGHT,
    );

    return {
      w: DEFAULT_WIDTH,
      h: DEFAULT_HEIGHT,
      Background: {
        rect: true,
        w: (w: number) => w,
        h: (h: number) => h,
        color: Colors('transparent').get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: BACKGROUND_RADIUS,
          stroke: BACKGROUND_STROKE,
          strokeColor: Colors('highlight').get(),
        },
      },
      Input: {
        flex: {
          direction: 'row',
          alignItems: 'center',
          paddingLeft: INPUT_PADDING_LEFT,
        },
        w: (w: number) => w,
        h: (h: number) => h,
        SearchIcon: {
          flexItem: { marginRight: SEARCH_ICON_MARGIN_RIGHT },
          w: SEARCH_ICON_WIDTH,
          ...searchIconTexture,
        },
        InputField: {
          flexItem: { marginTop: INPUT_FIELD_MARGIN_TOP },
          type: InputField,
          w: INPUT_FIELD_WIDTH,
          h: INPUT_FIELD_HEIGHT,
          description: translate('search.searchPrompt'),
          inputText: {
            fontSize: INPUT_FONT_SIZE,
            textColor: Colors('text').get(),
          },
          cursor: {
            w: CURSOR_WIDTH,
            h: CURSOR_HEIGHT,
            y: CURSOR_Y,
          },
        },
      },
    };
  }

  override _setup() {
    super._setup();
    this.updateAlpha();
  }

  override _getFocused() {
    return this._InputField;
  }

  override _focus() {
    this.updateAlpha();
  }

  override _unfocus() {
    this.updateAlpha();
  }

  private updateAlpha() {
    const cursorVisible = this._cursorToggled;
    const alpha = this.hasFocus() || cursorVisible ? 1 : 0.6;

    this.patch({ alpha });
  }

  override _handleEnter() {
    return false;
  }
}
