import { Lightning, Router } from '@lightningjs/sdk';
import Page from 'components/Page';
import { PageId } from 'types/pageId';
import { Live, Media, ShowVideos } from 'types/api/media';
import LiveBillboardSection from 'components/pages/live/LiveBillboardSection';
import { getImageTextureContainObj } from 'support/generalUtils';
import { navigateToPlay } from 'support/routerUtils';
import SizedRowList from 'components/common/SizedRowList';
import VideoTile, {
  VideoTileTemplateSpec,
} from 'components/common/tiles/VideoTile';
import { constants } from 'aliases';
import { getDurationHourMinutesNoSpaceFormatted } from 'support/contentUtils';
import { translate } from 'support/translate';
import { StaticViewContexts } from 'types/analytics';
import { SelectItemContext } from 'types/events';
import {
  reportItemSelected,
  reportPromoSelected,
} from 'services/analytics/reportingServicePage';

export interface LivePageTemplateSpec extends Lightning.Component.TemplateSpec {
  pageData: {
    liveStream: Live;
    recommendations: ShowVideos | undefined;
  };

  Content: {
    Sections: {
      ShowcaseSection: typeof LiveBillboardSection;
      RecommendationSection: typeof SizedRowList;
    };
    Logo: Lightning.Texture;
  };
}

const NAVBAR_COLLAPSED_WIDTH = constants.sizing.navbar.collapsedWidth;
const RECOMMENDATION_SECTION_PADDING_LEFT = 95;
const RECOMMENDATION_SECTION_PADDING_TOP = 10;
const RECOMMENDATION_SECTION_WIDTH =
  1920 - RECOMMENDATION_SECTION_PADDING_LEFT - NAVBAR_COLLAPSED_WIDTH;

const LOGO_X_SHOWCASE = 257;
const LOGO_Y_SHOWCASE = 289;
const LOGO_W_SHOWCASE = 565;
const LOGO_H_SHOWCASE = 230;
const LOGO_MOUNT_X = 0;
const LOGO_MOUNT_Y = 1;

export default class LivePage
  extends Page<LivePageTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<LivePageTemplateSpec>
{
  protected override _pageId = PageId.LIVE;
  protected override _viewContext = StaticViewContexts.LIVE;

  private _live: Live | null = null;

  private _Content = this.getByRef('Content')!;
  private _Sections = this._Content.getByRef('Sections')!;
  private _ShowcaseSection = this._Sections.getByRef('ShowcaseSection')!;
  private _RecommendationSection = this._Sections.getByRef(
    'RecommendationSection',
  )!;
  private _Logo = this._Content.getByRef('Logo')!;

  get announce() {
    return translate('ttsPrompts.live');
  }

  static override _template(): Lightning.Component.Template<LivePageTemplateSpec> {
    return {
      ...super._template(),
      Content: {
        Sections: {
          x: NAVBAR_COLLAPSED_WIDTH,
          flex: { direction: 'column' },
          ShowcaseSection: {
            type: LiveBillboardSection,
            signals: {
              $hideNavBar: '$hideNavBar',
              $showNavBar: '$showNavBar',
              $playContent: '$playContent',
              $onBillboardHover: '$onBillboardHover',
            },
          },
          RecommendationSection: {
            type: SizedRowList,
            // @ts-ignore
            listType: VideoTile,
            itemWidth: VideoTile.width,
            visible: false,
            x: RECOMMENDATION_SECTION_PADDING_LEFT,
            y: LiveBillboardSection.h + RECOMMENDATION_SECTION_PADDING_TOP,
            w: RECOMMENDATION_SECTION_WIDTH,
            signals: { $onHover: '$onRecommendationHover' },
          },
        },
        Logo: {
          mountX: LOGO_MOUNT_X,
          mountY: LOGO_MOUNT_Y,
          x: LOGO_X_SHOWCASE,
          y: LOGO_Y_SHOWCASE,
        },
      },
    };
  }

  override _setup() {
    super._setup();

    this._setState('LiveBillboardSection');
  }

  override _onDataProvided() {
    const { liveStream, recommendations } = this.pageData;

    this._live = liveStream;
    this._ShowcaseSection.patch({ live: liveStream });
    this.updateLogo();

    if (recommendations) {
      this.updateRecommendations();
    } else {
      this._RecommendationSection.patch({ visible: false });
      this._setState('LiveBillboardSection');
    }
  }

  override _handleUp() {
    this._setState('LiveBillboardSection');
  }

  override _handleDown() {
    if (this._RecommendationSection.visible)
      this._setState('RecommendationSection');
  }

  override _handleBack() {
    Router.focusWidget('NavBar');
  }

  private updateLogo() {
    const logoSrc = this._live?.showcaseLogo ?? '';

    const logoTexture = getImageTextureContainObj(
      logoSrc,
      LOGO_W_SHOWCASE,
      LOGO_H_SHOWCASE,
    );

    this._Logo.patch(logoTexture);
  }

  private updateRecommendations() {
    const { title, items: recommendedVideos } =
      this.pageData.recommendations ?? {};
    if (!recommendedVideos?.length) return;

    const items = recommendedVideos.map(video => {
      const imageUrl = video.images.thumbnail;
      const title = video.title;
      const description = getDurationHourMinutesNoSpaceFormatted(video);

      return {
        data: video,
        imageUrl,
        tileTitle: title,
        tileDescription: description,
      } as Lightning.Element.PatchTemplate<VideoTileTemplateSpec>;
    });

    this._RecommendationSection.patch({
      visible: true,
      label: title,
      items,
    });
  }

  $playContent() {
    const live = this._live;
    if (!live) return;

    reportPromoSelected({
      // Default to showcaseThumbnail since live page has no image ID
      imageFileTitle: live.showcaseThumbnail ?? '',
      index: 0,
      promo: live,
    });

    navigateToPlay(
      live.slug,
      {
        viewContext: this.viewContext,
        pageId: this.pageId,
      },
      'live',
    );
  }

  private $onBillboardHover() {
    if (this._getState() === 'RecommendationSection') {
      this._setState('LiveBillboardSection');
    }
  }

  private $onRecommendationHover(target: any) {
    if (this._getState() === 'LiveBillboardSection') {
      this._setState('RecommendationSection');
    }
  }

  override $onTileSelected(data?: Media, context?: SelectItemContext) {
    const crossIndex = this._RecommendationSection.index;
    const mainIndex = 0; // Only one recommended row
    const rowTitle = this._RecommendationSection.label ?? '';
    const imageUrl = context?.imageUrl ?? '';

    reportItemSelected({
      imageUrl,
      crossIndex,
      mainIndex,
      data,
      rowTitle,
      isTivoTileSource: true, // LivePage recommendations will always come from Tivo
    });

    super.$onTileSelected(data, context);
  }

  $hideNavBar() {
    this.widgets.navbar.setSmooth('alpha', 0);
  }

  $showNavBar() {
    this.widgets.navbar.setSmooth('alpha', 1);
  }

  static override _states() {
    return [
      class LiveBillboardSection extends this {
        override _getFocused() {
          return this._ShowcaseSection;
        }
      },
      class RecommendationSection extends this {
        override _getFocused() {
          return this._RecommendationSection;
        }

        override _handleBack() {
          this._setState('LiveBillboardSection');
        }
      },
    ];
  }
}
