import { Lightning } from '@lightningjs/sdk';
import AbstractVideoOverlay from 'components/pages/playback/AbstractVideoOverlay';
import FastPlayerControls from 'components/pages/epg/FastPlayerControls';
import { EpgChannel } from 'types/api/media';
import FastPlaybackDetails from 'components/pages/epg/FastPlaybackDetails';
import CcAudioMenu from 'components/pages/playback/CcAudioMenu';
import { constants } from 'aliases';

interface FastPlaybackOverlayTemplateSpec
  extends Lightning.Component.TemplateSpec {
  channel: EpgChannel | null;

  Details: typeof FastPlaybackDetails;
  Controls: typeof FastPlayerControls;
  CcAudioMenu: typeof CcAudioMenu;
}

const PADDING_X = 170;

const DETAILS_Y = 110;

const CONTROLS_Y = 918;

export default class FastPlaybackOverlay extends AbstractVideoOverlay<FastPlaybackOverlayTemplateSpec> {
  private _Details = this.getByRef('Details')!;
  private _Controls = this.getByRef('Controls')!;
  private _CcAudioMenu = this.getByRef('CcAudioMenu')!;

  set channel(channel: EpgChannel | null) {
    this._Details.patch({ channel });
  }

  static override _template(): Lightning.Component.Template<FastPlaybackOverlayTemplateSpec> {
    return {
      ...super._template(),
      Details: {
        type: FastPlaybackDetails,
        x: PADDING_X,
        y: DETAILS_Y,
      },
      Controls: {
        type: FastPlayerControls,
        x: PADDING_X,
        y: CONTROLS_Y,
        passSignals: {
          ccAudio: true,
          backToGuide: true,
        },
      },
      CcAudioMenu: {
        x: constants.ui.ccAudioX,
        y: constants.ui.ccAudioY,
        mountY: 1,
        type: CcAudioMenu,
        passSignals: {
          onSubtitlesChange: true,
          onAudioChange: true,
        },
        visible: false,
      },
    };
  }

  override _setup() {
    this._setState('Controls');
  }

  updateTime(currentTime: Date) {
    this._Details.updateTime(currentTime);
  }

  updateCc(ccIndex: number, audioIndex: number, cc: string[], audio: string[]) {
    this._CcAudioMenu.cc = cc;
    this._CcAudioMenu.audio = audio;
    this._CcAudioMenu.ccIndex = ccIndex;
    this._CcAudioMenu.audioIndex = audioIndex;

    this._setState('CcAudioMenu');
  }

  isCcOverlayOpen() {
    return this._getState() === 'CcAudioMenu';
  }

  static override _states() {
    return [
      class Controls extends this {
        override $enter() {
          this._Controls.visible = true;
        }

        override $exit() {
          this._Controls.visible = false;
        }

        override _getFocused() {
          return this._Controls;
        }
      },
      class CcAudioMenu extends this {
        override $enter() {
          this._CcAudioMenu.visible = true;
        }

        override $exit() {
          this._CcAudioMenu.visible = false;
        }

        override _getFocused() {
          return this._CcAudioMenu;
        }

        override _handleBack() {
          this._setState('Controls');
          return true;
        }
      },
    ];
  }
}
