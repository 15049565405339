import { AppData, Colors, Lightning, Router } from '@lightningjs/sdk';
import Page from 'components/Page';
import { PageId } from 'types/pageId';
import { MediaIdentifier, Show, Video } from 'types/api/media';
import { translate } from 'support/translate';
import Tabs from 'components/common/Tabs/Tabs';
import Tab, { TabTemplateSpec } from 'components/common/Tabs/Tab';
import LandscapeTile from 'components/common/tiles/LandscapeTile';
import Grid from 'components/common/Grid';
import { IndexData, LightningBuilder } from 'types/lightning';
import SearchInputField from 'components/common/input/SearchInputField';
import Keyboard, { InputCompleteEvent } from 'components/common/input/Keyboard';
import { constants } from 'aliases';
import EmptySearchResults from 'components/pages/search/EmptySearchResults';
import { getMediaDataFromIdentifiers, getSearch } from 'services/tivoService';
import SearchTile from 'components/common/tiles/SearchTile';
import { isShow, isVideo } from 'support/contentUtils';
import { SelectItemContext } from 'types/events';
import { Merge } from 'types';
import { MAX_GUID_COUNT } from 'services/cwService';
import { StaticViewContexts } from 'types/analytics';

const GRID_PADDING = 10;

const SEARCH_FILTERS_POSITION = { x: 245, y: 242 };
const SUGGESTED_FILTER_SPACING = 80;
const RESULTS_FILTER_SPACING = 85;

const SUGGESTED_RESULTS_WRAPPER_POSITION = {
  x: 245 - GRID_PADDING,
  y: 329 - GRID_PADDING,
};
const SEARCH_RESULTS_WRAPPER_POSITION = {
  x: 245 - GRID_PADDING,
  y: 376 - GRID_PADDING,
};
const SEARCH_RESULTS_WRAPPER_DIMENSIONS = {
  w: 1564,
  h: 1080 - SUGGESTED_RESULTS_WRAPPER_POSITION.y,
};

const SPINNER_POSITION_PAGINATING = {
  x: 960,
  y: 850,
};
const SPINNER_POSITION_CENTER = {
  x: 960,
  y: 540,
};

const LANDSCAPE_TILE_SPACING = 30;
const SEARCH_TILE_SPACING = { x: 20, y: 64 };

const NAVBAR_COLLAPSED_WIDTH = constants.sizing.navbar.collapsedWidth;

const SEARCH_BAR_X = NAVBAR_COLLAPSED_WIDTH + 56;
const SEARCH_BAR_Y = 97;
const SEARCH_BAR_WIDTH = 1564;

const KEYBOARD_X = NAVBAR_COLLAPSED_WIDTH;
const KEYBOARD_WIDTH = 1920 - KEYBOARD_X;

const EMPTY_SEARCH_RESULTS_X = NAVBAR_COLLAPSED_WIDTH + 533;
const EMPTY_SEARCH_RESULTS_Y = 341;

const MIN_SEARCH_TERM_LEN = 2;

// Use maximum number of GUIDs for video call as pagination request amount
const REQUEST_AMOUNT = MAX_GUID_COUNT;
// Number of rows before the end before we need to fetch more data
const REQUEST_THRESHOLD = 1;

const SEARCH_RESULT_GRID_COLUMNS = 4;

export interface SearchPageTemplateSpec
  extends Lightning.Component.TemplateSpec {
  pageData: {
    trendingData: (Show | Video)[];
    reset: boolean;
  };

  Content: {
    SearchBar: typeof SearchInputField;
    SearchFilters: typeof Tabs;
    SearchResultsWrapper: {
      SearchResults: typeof Grid;
    };
    EmptySearchResults: typeof EmptySearchResults;
  };
  Keyboard: typeof Keyboard;
}

enum SearchState {
  NothingSearched = 'NothingSearched',
  NoSearchResults = 'NoSearchResults',
  SearchResultsFound = 'SearchResultsFound',
}

type SearchData = {
  identifier: MediaIdentifier;
  index: number;
  mediaData?: Video | Show;
};

export default class SearchPage
  extends Page<SearchPageTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<SearchPageTemplateSpec>
{
  private _reset = false;
  protected override _pageId = PageId.SEARCH;
  protected override _viewContext = StaticViewContexts.SEARCH_PAGE;

  private _searchData: SearchData[] = [];
  private _loadedSearchTiles = 0;

  private _Content = this.getByRef('Content')!;
  private _SearchBar = this._Content.getByRef('SearchBar')!;
  private _SearchFilters = this._Content.getByRef('SearchFilters')!;
  private _EmptySearchResults = this._Content.getByRef('EmptySearchResults')!;

  private _SearchResultsWrapper = this._Content.getByRef(
    'SearchResultsWrapper',
  )!;
  private _SearchResults =
    this._SearchResultsWrapper.getByRef('SearchResults')!;
  private _Keyboard = this.getByRef('Keyboard')!;

  private searchState: SearchState = SearchState.NothingSearched;

  get announce() {
    return translate('ttsPrompts.search');
  }

  static override _template(): Lightning.Component.Template<SearchPageTemplateSpec> {
    return {
      ...super._template(),
      Content: {
        SearchBar: {
          type: SearchInputField,
          x: SEARCH_BAR_X,
          y: SEARCH_BAR_Y,
          w: SEARCH_BAR_WIDTH,
          signals: {
            $onHover: '$onHover',
          },
        },
        SearchFilters: {
          ...SEARCH_FILTERS_POSITION,
          type: Tabs,
          labelStyles: {
            focused: { textColor: Colors('text').get() },
            unfocused: { textColor: Colors('text').alpha(0.6).get() },
            active: { highlight: false },
          },
        },
        SearchResultsWrapper: {
          ...SUGGESTED_RESULTS_WRAPPER_POSITION,
          ...SEARCH_RESULTS_WRAPPER_DIMENSIONS,
          clipping: true,
          SearchResults: {
            x: GRID_PADDING,
            y: GRID_PADDING,
            w: SEARCH_RESULTS_WRAPPER_DIMENSIONS.w - 2 * GRID_PADDING,
            h: SEARCH_RESULTS_WRAPPER_DIMENSIONS.h - 2 * GRID_PADDING,
            type: Grid,
            signals: {
              onRequestItems: '_onGridRequestItems',
              $onHover: '$onHover',
            },
            columns: SEARCH_RESULT_GRID_COLUMNS,
            gcThreshold: 16,
          },
        },
        EmptySearchResults: {
          type: EmptySearchResults,
          visible: false,
          x: EMPTY_SEARCH_RESULTS_X,
          y: EMPTY_SEARCH_RESULTS_Y,
        },
      },
      Keyboard: {
        type: Keyboard,
        x: KEYBOARD_X,
        w: KEYBOARD_WIDTH,
        signals: {
          hideKeyboard: '_onHideKeyboard',
          inputChanged: '_onSearchInputChanged',
          inputComplete: '_onSearch',
          $onHover: true,
        },
        zIndex: 1,
      },
    };
  }

  override _setup() {
    super._setup();
    this._setState('SearchBar');
  }

  override _onDataProvided() {
    const { reset } = this.pageData;
    this._reset = reset;
    if (reset) {
      this._setState('SearchBar');
      this._SearchBar.inputField._input = '';
      this.resetComponent();
      this.showFilterDefaultSuggestions();
      this.showTrendingResults();
    }
  }

  override _handleLeft() {
    Router.focusWidget('NavBar');
  }

  override _handleBack() {
    Router.focusWidget('NavBar');
  }

  private showFilterDefaultSuggestions() {
    this.searchState = SearchState.NothingSearched;
    const hasRecentSearches =
      !!AppData?.recentSearchesService.getRecentSearches()?.length;

    const recentSearchesTab = [
      this.buildTab(translate('search.tabs.recentSearches')),
    ];

    this._SearchFilters.patch({
      tabs: [
        this.buildTab(AppData?.tivoEndpoints?.trending.title),
        ...(hasRecentSearches ? recentSearchesTab : []),
      ],
      activeTab: 0,
      signals: {
        onIndexChanged: '_onFilterSuggestionsTabChanged',
        $onHover: '$onHover',
      },
      spacing: SUGGESTED_FILTER_SPACING,
    });

    this.showDefaultFiltersAndResults();
  }

  private resetComponent() {
    this._searchData = [];
    this._loadedSearchTiles = 0;
    this.spinnerPosition = SPINNER_POSITION_CENTER;
  }

  private showSearchResultFilters() {
    this.searchState = SearchState.SearchResultsFound;

    const tabs = [];
    const topResultsLabel = this.getSearchResultLabel('TopResults');
    const seriesLabel = this.getSearchResultLabel('Series');
    const episodesLabel = this.getSearchResultLabel('Episode');
    const moviesLabel = this.getSearchResultLabel('Movie');

    if (topResultsLabel) tabs.push(this.buildTab(topResultsLabel));
    if (seriesLabel) tabs.push(this.buildTab(seriesLabel));
    if (episodesLabel) tabs.push(this.buildTab(episodesLabel));
    if (moviesLabel) tabs.push(this.buildTab(moviesLabel));
    // TODO: add channels

    this._SearchFilters.patch({
      tabs,
      activeTab: tabs.length ? 0 : null,
      signals: {
        onIndexChanged: '_onSearchResultsTabChanged',
        $onHover: '$onHover',
      },
      spacing: RESULTS_FILTER_SPACING,
    });

    this.showDefaultFiltersAndResults();
    this.useSearchTiles();
  }

  private showTrendingResults() {
    const { trendingData } = this.pageData;
    const items = trendingData.map(this.buildLandscapeTile.bind(this));

    this.useLandscapeTiles();
    this._SearchResults.items = items;
  }

  private showRecentSearchResults() {
    this._SearchResults.clear();

    const recentItems = AppData?.recentSearchesService.getRecentSearches();
    if (!recentItems) return;

    this.useSearchTiles();
    this._SearchResults.items = this.buildRecentSearchTiles(recentItems);
  }

  private showEmptyResults(searchTerm: string) {
    this.searchState = SearchState.NoSearchResults;

    this._SearchResults.clear();
    const currentState = this._getState();
    if (!(currentState === 'SearchBar' || currentState === 'Keyboard')) {
      this._setState('SearchBar');
    }

    this._SearchFilters.patch({ visible: false });
    this._SearchResultsWrapper.patch({ visible: false });
    this._EmptySearchResults.patch({
      visible: true,
      search: searchTerm,
    });
    this._EmptySearchResults.fireAnnounce();
  }

  private async initializeSearchResults() {
    const searchData = await this.requestSearchData();
    const items = this.buildSearchTiles(searchData);

    this._SearchResults.patch({ items });
    if (this.shouldPaginate()) {
      this._SearchResults.usePagination(REQUEST_THRESHOLD);
      this.spinnerPosition = SPINNER_POSITION_PAGINATING;
    }
  }

  private buildTab(
    label = '',
    activeTab = false,
  ): LightningBuilder<typeof Tab, TabTemplateSpec> {
    return {
      type: Tab,
      label,
      activeTab,
    };
  }

  private buildLandscapeTile(mediaItem: Show | Video) {
    return {
      type: LandscapeTile,
      imageUrl: this.getTileImage(mediaItem),
      data: mediaItem,
    };
  }

  private buildSearchTiles(searchData: SearchData[]) {
    return searchData
      .map((data: SearchData) => {
        const mediaItem = data.mediaData;
        if (!mediaItem) return null;

        return {
          type: SearchTile,
          imageUrl: this.getTileImage(mediaItem),
          data: mediaItem,
        };
      })
      .filter(tile => !!tile) as { type: typeof SearchTile }[];
  }

  private buildRecentSearchTiles(searchData: Merge<Show, Video>[]) {
    return searchData.map(item => ({
      type: SearchTile,
      imageUrl: this.getTileImage(item),
      data: item,
    }));
  }

  private updateActiveTab() {
    const { index, tabs } = this._SearchFilters;
    if (!tabs.length) return;
    this._SearchFilters.activeTab = index;
  }

  private getSearchResultLabel(
    type: MediaIdentifier['objectType'] | 'TopResults',
  ) {
    const stringKey = `search.tabs.${type}`;
    const numberOfResults =
      type === 'TopResults'
        ? this._searchData.length
        : this.getSearchDataByType(type).length;

    return numberOfResults ? translate(stringKey, numberOfResults) : '';
  }

  private getTileImage(mediaItem: Show | Video) {
    if (isShow(mediaItem)) {
      return mediaItem.images.image_show_thumbnail ?? '';
    } else if (isVideo(mediaItem)) {
      return mediaItem.images.thumbnail ?? '';
    } else {
      return '';
    }
  }

  private useLandscapeTiles() {
    this._SearchResults.listType = LandscapeTile;
    this._SearchResults.spacing = LANDSCAPE_TILE_SPACING;
    this._SearchResults.crossSpacing = LANDSCAPE_TILE_SPACING;
    this._SearchResultsWrapper.y = SUGGESTED_RESULTS_WRAPPER_POSITION.y;
  }

  private useSearchTiles() {
    this._SearchResults.listType = SearchTile;
    this._SearchResults.spacing = SEARCH_TILE_SPACING.y;
    this._SearchResults.crossSpacing = SEARCH_TILE_SPACING.x;
    this._SearchResultsWrapper.y = SEARCH_RESULTS_WRAPPER_POSITION.y;
  }

  private async fetchSearchResults(searchTerm: string) {
    this._SearchResults.clear();
    this.resetComponent();

    if (searchTerm.length < MIN_SEARCH_TERM_LEN) {
      this.showFilterDefaultSuggestions();
      this.showTrendingResults();
      return;
    }

    this.loaded = false;
    try {
      const searchResultIdentifiers = await getSearch(searchTerm);

      // Reset loaded state and show empty results if no identifiers found
      if (!searchResultIdentifiers.length) {
        this.loaded = true;
        this.showEmptyResults(searchTerm);
        return;
      }

      // Order of operations matter
      this.initializeSearchData(searchResultIdentifiers);
      this.showSearchResultFilters();
      await this.initializeSearchResults();
    } catch (e) {
      this.showEmptyResults(searchTerm);
    }
    this.loaded = true;
  }

  private initializeSearchData(searchIdentifiers: MediaIdentifier[]) {
    this._searchData = searchIdentifiers.map((identifier, index) => {
      return {
        identifier,
        index,
      };
    });
  }

  private getSearchDataByType(type: MediaIdentifier['objectType']) {
    return this._searchData.filter(data => data.identifier.objectType === type);
  }

  private getCurrentTabSearchData() {
    const filters = this._SearchFilters;
    const tabIndex = filters.index;
    const tabLabel = filters.tabs[tabIndex]!.label;

    if (tabLabel === this.getSearchResultLabel('Episode')) {
      return this.getSearchDataByType('Episode');
    } else if (tabLabel === this.getSearchResultLabel('Series')) {
      return this.getSearchDataByType('Series');
    } else if (tabLabel === this.getSearchResultLabel('Movie')) {
      return this.getSearchDataByType('Movie');
    } else {
      return this._searchData;
    }
  }

  private async requestSearchData() {
    const searchData = this.getCurrentTabSearchData();

    const startIndex = this._loadedSearchTiles;
    const endIndex = Math.min(startIndex + REQUEST_AMOUNT, searchData.length);

    const missingSearchData = searchData
      .slice(startIndex, endIndex)
      .map(data => (data.mediaData === undefined ? data : undefined))
      .filter(value => !!value) as SearchData[];

    const mediaData = await getMediaDataFromIdentifiers(
      missingSearchData.map(data => data.identifier),
    );

    mediaData.forEach((media, index) => {
      const data = missingSearchData[index];
      if (data) {
        data.mediaData = media;

        // Update the search data using saved search data index
        this._searchData[data.index] = data;
      }
    });

    this._loadedSearchTiles = endIndex;
    return searchData.slice(startIndex, endIndex);
  }

  private hasData() {
    return !!this._SearchResults.items.length;
  }

  private showDefaultFiltersAndResults() {
    this._SearchFilters.visible = true;
    this._SearchResultsWrapper.visible = true;
    this._EmptySearchResults.visible = false;
  }

  private shouldPaginate() {
    const searchData = this.getCurrentTabSearchData();
    return searchData.length > REQUEST_AMOUNT;
  }

  private _onFilterSuggestionsTabChanged(indexData: IndexData) {
    const { index } = indexData;
    index === 0 ? this.showTrendingResults() : this.showRecentSearchResults();
    this.updateActiveTab();
  }

  private async _onSearchResultsTabChanged() {
    this.loaded = false;
    this._loadedSearchTiles = 0;
    this.spinnerPosition = SPINNER_POSITION_CENTER;
    await this.initializeSearchResults();
    this.loaded = true;

    this.updateActiveTab();
  }

  private _onHideKeyboard() {
    this._setState('SearchBar');
  }

  private async _onSearch(event: InputCompleteEvent) {
    const { input } = event;
    await this.fetchSearchResults(input);
    this._setState('SearchBar');
  }

  private async _onGridRequestItems() {
    this.loaded = false;
    const searchData = await this.requestSearchData();
    this.loaded = true;

    // Returning false turns off pagination
    return searchData.length ? this.buildSearchTiles(searchData) : false;
  }

  override $onTileSelected(
    data?: Merge<Show, Video>,
    context?: SelectItemContext,
  ) {
    if (data && this.searchState === SearchState.SearchResultsFound) {
      AppData?.recentSearchesService.add(data);
    }

    super.$onTileSelected(data, context);
  }

  $onHover(target: unknown) {
    switch (target) {
      case this._SearchBar:
        this._setState('SearchBar');
        break;
      case this._SearchFilters:
        this._setState('SearchFilters');
        break;
      case this._SearchResults:
        this._setState('SearchResults');
        break;
      case this._Keyboard:
        this._setState('Keyboard');
        break;
      // TODO: Add Remaining states
    }
  }

  static override _states() {
    return [
      class SearchBar extends this {
        override _getFocused() {
          return this._SearchBar;
        }

        override _handleEnter() {
          this._setState('Keyboard');
        }

        override _handleDown() {
          if (!this.hasData() || !this.loaded) return;
          this._setState('SearchFilters');
        }
      },
      class Keyboard extends this {
        override $enter() {
          const inputField = this._SearchBar.inputField;
          this._Keyboard.patch({ inputField });
          this._SearchBar.patch({ toggleCursor: true });
        }

        override $exit() {
          this._SearchBar.patch({ toggleCursor: false });
        }

        override _getFocused() {
          return this._Keyboard;
        }

        override _handleUp() {
          this._setState('SearchBar');
        }
      },
      class SearchFilters extends this {
        override _getFocused() {
          return this._SearchFilters;
        }

        override _handleUp() {
          this._handleBack();
        }

        override _handleDown() {
          if (!this.hasData() || !this.loaded) return;
          this._setState('SearchResults');
        }

        override _handleBack() {
          this._setState('SearchBar');
        }
      },
      class SearchResults extends this {
        override _getFocused() {
          return this._SearchResults;
        }

        override _handleUp() {
          this._handleBack();
        }

        override _handleBack() {
          this._setState('SearchFilters');
        }
      },
    ];
  }
}
