import { Lightning, Registry } from '@lightningjs/sdk';
import { getImageTextureObj } from 'support/generalUtils';
import { constants } from 'aliases';
import {
  AdTagParams,
  getPauseAdImageUrls,
  sendPauseAdImpressions,
} from 'services/adService';

const DELAY_MS = 5000;

export interface PauseAdTemplateSpec extends Lightning.Component.TemplateSpec {
  onLoad?: () => void;

  AdImage: Lightning.textures.ImageTexture;
}

export default class PauseAd
  extends Lightning.Component<PauseAdTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<PauseAdTemplateSpec>
{
  private _onLoad: PauseAdTemplateSpec['onLoad'];

  private _fetchDelay = null;
  private _pauseAdUrls: Awaited<ReturnType<typeof getPauseAdImageUrls>> | null =
    null;

  private _AdImage = this.getByRef('AdImage')!;

  get onLoad() {
    return this._onLoad;
  }

  set onLoad(onLoad: PauseAdTemplateSpec['onLoad']) {
    this._onLoad = onLoad;
  }

  static override _template(): Lightning.Component.Template<PauseAdTemplateSpec> {
    return {
      AdImage: {
        ...getImageTextureObj('', 1920, 1080),
        alpha: constants.ui.invisible,
      },
    };
  }

  override _active() {
    this._AdImage.on('txLoaded', () => {
      this.onAdImageLoad();
    });
  }

  override _inactive() {
    this.stop();
    this._pauseAdUrls = null;
    this._AdImage.patch(getImageTextureObj('', 1920, 1080));
    this._AdImage.removeAllListeners('txLoaded');
  }

  private sendImpressions() {
    const { impressionUrls, adStart } = this._pauseAdUrls ?? {};

    if (impressionUrls) sendPauseAdImpressions(impressionUrls);
    if (adStart) sendPauseAdImpressions([adStart]);
  }

  private async fetchAd(params: AdTagParams) {
    this._pauseAdUrls = await getPauseAdImageUrls(params);
    const { adImageUrl } = this._pauseAdUrls;
    const { playerWidth, playerHeight } = params;

    this._AdImage.patch(
      getImageTextureObj(adImageUrl, playerWidth, playerHeight),
    );

    if (this._AdImage.textureIsLoaded()) {
      this.onAdImageLoad();
    }
  }

  private onAdImageLoad() {
    this._onLoad?.();
    this.sendImpressions();
    this._AdImage.setSmooth('alpha', 1);
  }

  start(params: AdTagParams) {
    if (this._fetchDelay) {
      Registry.clearTimeout(this._fetchDelay);
    }

    this._fetchDelay = Registry.setTimeout(
      () => this.fetchAd(params),
      DELAY_MS,
    );
  }

  stop() {
    const { adEnd } = this._pauseAdUrls ?? {};

    if (this._fetchDelay) {
      Registry.clearTimeout(this._fetchDelay);
    } else if (adEnd) {
      sendPauseAdImpressions([adEnd]);
    }

    this._AdImage.setSmooth('alpha', constants.ui.invisible);
    this._AdImage.patch({
      ...getImageTextureObj('', 1920, 1080),
    });
    this._fetchDelay = null;
  }
}
