import { AppData, Colors, Lightning, Registry } from '@lightningjs/sdk';
import Tile, { TileTemplateSpec } from 'components/common/tiles/Tile';
import { STANDARD_EXPANSION, STANDARD_FADE } from 'support/animations';
import RatingBadge from 'components/common/RatingBadge';
import { translate } from 'support/translate';
import Button from 'components/common/Button';
import { Media, Show } from 'types/api/media';
import {
  formatPlayMedia,
  getDurationHourMinutesNoSpaceFormatted,
  isMovie,
  isShow,
} from 'support/contentUtils';
import { withSeparator } from 'support/textUtils';
import { constants } from 'aliases';
import { getImageTextureObj } from 'support/generalUtils';
import { VideoHistory } from 'services/history/AbstractHistoryService';
import FeaturedTileGoToButton from 'components/common/FeaturedTileGoToButton';
import { updateImageSize } from 'support/cwImageUtils';

export interface FeaturedTileTemplateSpec extends TileTemplateSpec {
  expandedImageUrl: string;
  data: Show;

  ExpandedImage: Lightning.Component;
  ImageLogo: Lightning.Component;
  DetailsContent: {
    InfoContainer: {
      RatingBadge: typeof RatingBadge;
      Info: Lightning.Component;
    };
    Seasons: Lightning.Component;
    Description: Lightning.Component;
    ButtonContainer: {
      PlayButton: typeof Button;
      GoToPageButton: typeof FeaturedTileGoToButton;
    };
  };
}
const HIGHLIGHT_SIZE_OFFSET = 12;

const CONTENT_TRANSITION_X = 200;

const LOGO_X = 47;

const DETAILS_X = 64;

const SEASONS_Y = 51;
const SEASONS_HEIGHT = 27;

const DESCRIPTION_Y = 81;
const DESCRIPTION_WIDTH = 426;
const DESCRIPTION_HEIGHT = 120;

const BUTTONS_Y = 213;

const EXPANSION_DELAY = 1000;

// This value is non-zero so that the "invisible" component still renders (and doesn't have to render/fetch images when visible)
const INVISIBLE = constants.ui.invisible;

const PLAY_ICON_UNFOCUSED = getImageTextureObj(
  'static/images/playback/play-icon-light.svg',
  23,
  28,
);
const PLAY_ICON_FOCUSED = getImageTextureObj(
  'static/images/playback/play-icon-dark.svg',
  23,
  28,
);
const ARROW_RIGHT_ICON_UNFOCUSED = getImageTextureObj(
  'static/images/arrow-right-light.svg',
  35.3,
  30,
);
const ARROW_RIGHT_ICON_FOCUSED = getImageTextureObj(
  'static/images/arrow-right-dark.svg',
  35.3,
  30,
);

export default class FeaturedTile extends Tile<FeaturedTileTemplateSpec> {
  private _ExpandedImage = this.getByRef('ExpandedImage')!;
  private _DetailsContent = this.getByRef('DetailsContent')!;
  private _ImageLogo = this.getByRef('ImageLogo')!;
  private _InfoContainer = this._DetailsContent.getByRef('InfoContainer')!;
  private _RatingBadge = this._InfoContainer.getByRef('RatingBadge')!;
  private _Info = this._InfoContainer.getByRef('Info')!;
  private _Seasons = this._DetailsContent.getByRef('Seasons')!;
  private _Description = this._DetailsContent.getByRef('Description')!;
  private _ButtonContainer = this._DetailsContent.getByRef('ButtonContainer')!;
  private _PlayButton = this._ButtonContainer.getByRef('PlayButton')!;
  private _GoToPageButton = this._ButtonContainer.getByRef('GoToPageButton')!;

  private expandTimeoutId: number | null = null;
  private imagesLoaded = 0;

  private _mediaType: 'series' | 'movie' | null = null;

  private _areSubActionsDisabled = true;

  override get title() {
    if (!this._data) return '';

    return [
      this._data.title,
      this._data.rating,
      this._Info.text?.text ?? '',
      this._Seasons.visible ? this._Seasons.text?.text ?? '' : '',
      this._data.description,
    ];
  }

  set expandedImageUrl(expandedImageUrl: string) {
    // Only update image if we have an expanded image URL
    if (!expandedImageUrl) return;

    const src = updateImageSize(expandedImageUrl, this._ExpandedImage.w);
    this._ExpandedImage.patch({ src });
  }

  static override get expandedWidth() {
    return 0;
  }

  static override get expandedHeight() {
    return 0;
  }

  static get contentPaddingY() {
    return 0;
  }

  static get detailsOffsetY() {
    return 0;
  }

  static get logoWidth() {
    return 0;
  }

  static get logoHeight() {
    return 0;
  }

  static get buttonY() {
    return BUTTONS_Y;
  }

  static override _template(): Lightning.Component.Template<FeaturedTileTemplateSpec> {
    return {
      ...super._template(),
      clipping: true,
      transitions: STANDARD_EXPANSION,
      ExpandedImage: {
        transitions: STANDARD_FADE,
        alpha: INVISIBLE,
        rect: true,
        w: this.expandedWidth,
        h: this.expandedHeight,
      },
      ImageLogo: {
        alpha: INVISIBLE,
        transitions: {
          x: { duration: 0.1, timingFunction: 'ease-out' },
          ...STANDARD_FADE,
        },
        x: LOGO_X - CONTENT_TRANSITION_X,
        y: this.contentPaddingY,
        w: this.logoWidth,
        h: this.logoHeight,
      },
      DetailsContent: {
        alpha: 0,
        transitions: {
          x: { duration: 0.1, timingFunction: 'ease-out' },
          ...STANDARD_FADE,
        },
        x: DETAILS_X - CONTENT_TRANSITION_X,
        y: this.detailsOffsetY + this.contentPaddingY,
        InfoContainer: {
          flex: { direction: 'row' },
          RatingBadge: {
            type: RatingBadge,
            flexItem: { marginRight: 8 },
          },
          Info: {
            text: { fontSize: 23 },
          },
        },
        Seasons: {
          y: SEASONS_Y,
          text: {
            verticalAlign: 'middle',
            lineHeight: SEASONS_HEIGHT,
            textColor: Colors('tileSeasons').get(),
            fontSize: 23,
          },
        },
        Description: {
          y: DESCRIPTION_Y,
          w: DESCRIPTION_WIDTH,
          h: DESCRIPTION_HEIGHT,
          text: {
            fontSize: 23,
            maxLines: 3,
            maxLinesSuffix: '...',
            lineHeight: 27.45,
          },
        },
        ButtonContainer: {
          y: this.buttonY,
          flex: { direction: 'row' },
          PlayButton: {
            type: Button,
            minWidth: 120,
            height: 60,
            fontSize: 23,
            padding: 0,
            action: '',
            startIcon: {
              focused: PLAY_ICON_FOCUSED,
              unfocused: PLAY_ICON_UNFOCUSED,
            },
            signals: { $play: '$play', $onHover: '$onHover' },
            flexItem: { marginRight: 26 },
          },
          GoToPageButton: {
            type: FeaturedTileGoToButton,
            height: 60,
            fontSize: 23,
            action: '$goToPage',
            label: translate('featured.detail'),
            startIcon: {
              focused: ARROW_RIGHT_ICON_FOCUSED,
              unfocused: ARROW_RIGHT_ICON_UNFOCUSED,
            },
            signals: { $goToPage: '$goToPage', $onHover: '$onHover' },
          },
        },
      },
    };
  }

  override _setup() {
    super._setup();

    const handleImageLoad = () => {
      this.imagesLoaded += 1;
      if (this.imagesLoaded === 2) {
        this._Placeholder.patch({ visible: false });

        this._hasImageLoaded = true;
        this.handleVisibilitySignal();
      }
    };
    const handleImageUnload = () => {
      this.imagesLoaded -= 1;
      this._Placeholder.patch({ visible: true });

      this._hasImageLoaded = false;
    };

    this._Image.on('txLoaded', handleImageLoad);
    this._ExpandedImage.on('txLoaded', handleImageLoad);
    this._Image.on('txUnloaded', handleImageUnload);
    this._ExpandedImage.on('txUnloaded', handleImageUnload);

    this._Image.patch({
      transitions: STANDARD_FADE,
    });
    this._Placeholder.patch({
      transitions: STANDARD_EXPANSION,
    });

    this._Placeholder.transition('w').on('finish', () => {
      this.handleDetailsTransition();
    });
    this._DetailsContent.transition('x').on('finish', () => {
      if (!this.hasFocus()) return;

      this._areSubActionsDisabled = false;
      this._PlayButton.action = '$play';
    });
  }

  override _getFocused(): Button | FeaturedTileGoToButton {
    return this._PlayButton;
  }

  override _focus() {
    this.clearExpandTimeout();
    this.parseMediaItem();

    this.expandTimeoutId = Registry.setTimeout(() => {
      const { expandedWidth, expandedHeight } = this
        .constructor as typeof FeaturedTile;
      const tileSize = { w: expandedWidth, h: expandedHeight };

      this.patch(tileSize);
      this.fireAncestors('$repositionList', this.onRepositioned.bind(this));

      this.expandTimeoutId = null;
    }, EXPANSION_DELAY);
  }

  override _unfocus() {
    this.clearExpandTimeout();
    this.handleDetailsTransition();

    const { width, height } = this.constructor as typeof FeaturedTile;
    const tileSize = { w: width, h: height };

    this._Placeholder.patch({ smooth: tileSize });
    this.patch({ smooth: tileSize });
    this.updateImages();

    const highlightTransition = {
      smooth: {
        w: width + HIGHLIGHT_SIZE_OFFSET,
        h: height + HIGHLIGHT_SIZE_OFFSET,
      },
    };

    this.fireAncestors('$expandList', highlightTransition);

    this._setState('');
    this._areSubActionsDisabled = true;
    this._PlayButton.action = '';
  }

  override _handleRight() {
    if (this._areSubActionsDisabled || this.state === 'GoToPageButton') {
      return false;
    }
    this._setState('GoToPageButton');
  }

  override _handleEnter() {
    // prevent default tile _handleEnter
  }

  protected override clearListeners() {
    super.clearListeners();
    this._ExpandedImage.removeAllListeners('txLoaded');
    this._ExpandedImage.removeAllListeners('txUnloaded');
    this._DetailsContent.transition('x').removeAllListeners('finish');
    this._Placeholder.transition('w').removeAllListeners('finish');
  }

  private onRepositioned() {
    if (!this.hasFocus()) return;
    const { expandedWidth, expandedHeight } = this
      .constructor as typeof FeaturedTile;
    const tileSize = { w: expandedWidth, h: expandedHeight };

    this._Placeholder.patch({ smooth: tileSize });
    this.updateImages();

    const highlightTransition = {
      smooth: {
        w: expandedWidth + HIGHLIGHT_SIZE_OFFSET,
        h: expandedHeight + HIGHLIGHT_SIZE_OFFSET,
      },
    };

    this.fireAncestors('$expandList', highlightTransition);
  }

  private updateImages() {
    const hasFocus = this.hasFocus();

    const imageAlpha = hasFocus ? INVISIBLE : 1;
    this._Image.patch({ smooth: { alpha: imageAlpha } });

    const expandedImage = hasFocus ? 1 : INVISIBLE;
    this._ExpandedImage.patch({ smooth: { alpha: expandedImage } });
  }

  private handleDetailsTransition() {
    const hasFocus = this.hasFocus();
    const alpha = hasFocus ? 1 : 0;

    const logoX = hasFocus ? LOGO_X : LOGO_X - CONTENT_TRANSITION_X;
    this._ImageLogo.patch({ smooth: { alpha, x: logoX } });

    const detailsX = hasFocus ? DETAILS_X : DETAILS_X - CONTENT_TRANSITION_X;
    this._DetailsContent.patch({ smooth: { alpha, x: detailsX } });
  }

  private clearExpandTimeout() {
    if (this.expandTimeoutId !== null) {
      Registry.clearTimeout(this.expandTimeoutId);
      this.expandTimeoutId = null;
    }
  }

  private updateTotalSeasons(content: Show) {
    let info = '';
    if (isMovie(content)) {
      const releaseYear = content.releaseYear;
      const duration = getDurationHourMinutesNoSpaceFormatted(content);
      info =
        releaseYear && duration
          ? `${releaseYear} | ${duration}`
          : releaseYear || duration;
    } else {
      const totalSeasons = content.totalSeasons;
      info = totalSeasons
        ? translate(
            totalSeasons === 1 ? 'home.season' : 'home.seasons',
            totalSeasons,
          )
        : '';
    }
    if (info) {
      this._Seasons.patch({ visible: true, text: info });
    } else {
      this._Seasons.patch({ visible: false });
    }
  }

  private updateInfoContainer(genres: string[], duration: string) {
    let infoValue: string;
    if (this._mediaType === 'movie') {
      infoValue = withSeparator(genres.join(', '), duration);
    } else {
      infoValue = genres.join(', ');
    }

    this._Info.patch({
      text: { text: infoValue },
    });
  }

  private getContentHistory(content: Show): VideoHistory | undefined {
    const { guid, showSlug } = content.firstEpisode ?? {};
    if (!guid || !showSlug) return;

    return AppData?.historyService.getVideoHistory(showSlug, guid);
  }

  private parseMediaItem() {
    const imageLogoUrl = updateImageSize(
      this._data.images.image_logo,
      this._ImageLogo.w,
    );
    const mediaType = this._data.type;
    const rating = this._data.rating;
    const genres = this._data.genres;
    const duration = getDurationHourMinutesNoSpaceFormatted(this._data);
    const description = this._data.description;
    const contentHistory = this.getContentHistory(this._data);
    const playButtonLabel = formatPlayMedia(this._data, {
      progress: contentHistory?.progress,
      isComplete: contentHistory?.isComplete,
      isFromTitle: true,
    });

    this._mediaType = mediaType as 'series' | 'movie';
    this._ImageLogo.patch({ src: imageLogoUrl });
    this._RatingBadge.patch({ rating });
    this._Description.patch({ text: { text: description } });
    this._PlayButton.patch({ label: playButtonLabel });
    this._GoToPageButton.patch({ label: translate('featured.detail') });

    this.updateTotalSeasons(this._data);
    this.updateInfoContainer(genres, duration);
  }

  $play() {
    let media: Media | undefined;
    if (this._data && isShow(this._data)) {
      media = (this._data as Show).firstEpisode ?? undefined;
    } else {
      media = this._data;
    }

    this.fireAncestors('$onTileSelected', media, {
      action: 'play',
      imageUrl: this._Image.src, // we use the non-expended image
    });
  }

  $goToPage() {
    this.fireAncestors('$onTileSelected', this._data, {
      action: 'details',
    });
  }

  $onHover(target: unknown) {
    switch (target) {
      case this._PlayButton:
        this._setState('');
        break;
      case this._GoToPageButton:
        this._setState('GoToPageButton');
        break;
    }
  }

  static override _states() {
    return [
      class GoToPageButton extends this {
        override _getFocused() {
          return this._GoToPageButton;
        }

        override _handleLeft() {
          this._setState('');
        }
      },
    ];
  }
}
