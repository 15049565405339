import AbstractHistoryService from 'services/history/AbstractHistoryService';

export default class DefaultHistoryService extends AbstractHistoryService {
  shouldUpdateHistoryOnBoot(): boolean {
    return false;
  }

  async updateHistoryOnBoot(): Promise<void> {
    // Do nothing
  }
}
