import { AppData, Router } from '@lightningjs/sdk';
import ParentalPin from 'components/widgets/parental-pin/ParentalPin';
import { translate } from 'support/translate';
import { getFocusDepth } from 'support/generalUtils';

export default class SetParentalPin extends ParentalPin {
  private pinToMatch: string | null = null;

  protected override update() {
    if (this.pinToMatch) {
      this.title = translate('ParentalPin.setPin.titleReEnter');
      this.description = translate('ParentalPin.setPin.descriptionReEnter');
    } else {
      this.title = translate('ParentalPin.setPin.title');
      this.description = translate('ParentalPin.setPin.description');
    }
    super.update();
  }

  override closeWidget() {
    this.pinToMatch = null;
    this._Content.alpha = 0;
    if (SetParentalPin.focusWidgetOnClose) {
      Router.focusWidget(SetParentalPin.focusWidgetOnClose);
    } else {
      Router.focusPage();
    }
    this.resetStaticStates();
  }

  protected override resetStaticStates() {
    SetParentalPin.focusWidgetOnClose = null;
    SetParentalPin.onValidateCallback = null;
    SetParentalPin.onCancelCallback = null;
  }

  override $cancel() {
    SetParentalPin.onCancelCallback?.();
    this.closeWidget();
  }

  override $validate() {
    if (this.pin.length !== 4) {
      this.handleInvalidPin();
      return;
    }

    if (!this.pinToMatch) {
      this.pinToMatch = this.pin;
      this.clearInput();
      this.update();

      this.fireAncestors(
        '$announcerRefresh',
        getFocusDepth(
          // @ts-ignore
          this.application,
          this as SetParentalPin,
        ),
      );
      return;
    }

    if (this.pinToMatch === this.pin) {
      AppData!.parentalPinService.setNewPin(this.pin, true);
      SetParentalPin.onValidateCallback?.();
      SetParentalPin.focusWidgetOnClose = null; // we always return to page after validation
      this.closeWidget();
    } else {
      this.handleInvalidPin();
    }
  }
}
