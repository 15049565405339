import { Lightning } from '@lightningjs/sdk';
import Placeholder from 'components/common/Placeholder';
import { SelectItemContext } from 'types/events';
import VisibilityIndicator from 'components/common/VisibilityIndicator';
import {
  updateContentHubThumbnailUrl,
  updateImageSize,
} from 'support/cwImageUtils';
import { HoverableListItem } from 'components/common/HoverableListItem';
import { HoverableComponentTypeConfig } from 'components/common/HoverableComponent';
import { ContentHub } from 'services/cwData';
import { Media } from 'types/api/media';
import { SpeechType } from '@lightningjs/ui-components';
import { isContentHub } from 'support/contentUtils';

export interface TileTemplateSpec extends Lightning.Component.TemplateSpec {
  action: SelectItemContext['action'];
  data: Media | ContentHub | undefined;
  imageUrl: string;
  notifyWhenFullyVisible: boolean;
  parentContext: unknown;

  Image: Lightning.textures.ImageTexture;
  Placeholder: typeof Placeholder;
  VisibilityIndicator: typeof VisibilityIndicator;
}

export default abstract class Tile<
  TemplateSpec extends TileTemplateSpec = TileTemplateSpec,
  TypeConfig extends HoverableComponentTypeConfig = HoverableComponentTypeConfig,
> extends HoverableListItem<TemplateSpec, TypeConfig> {
  protected _Image = (this as Tile).getByRef('Image')!;
  protected _Placeholder = (this as Tile).getByRef('Placeholder')!;
  protected _VisibilityIndicator = (this as Tile).getByRef(
    'VisibilityIndicator',
  )!;

  protected _data: TemplateSpec['data'] = undefined;
  protected _imageUrl: TemplateSpec['imageUrl'] = '';
  protected _action: TemplateSpec['action'] = undefined;
  private _notifyWhenFullyVisible = false;

  // Internal properties
  protected _hasImageLoaded = false;
  protected _isFullyVisible = false;
  protected _hasSignaledVisibility = false;

  /*
  used for storing data from the parent such tile index
  type is set to unknown to ensure no coupling between tile and parent (we should never have to know/use the type in the tile)
  */
  parentContext: unknown | null = null;

  get title(): SpeechType {
    return this._data?.title ?? '';
  }

  get data() {
    return this._data;
  }

  set data(data: TemplateSpec['data']) {
    this._data = data;

    this.updateVisibilityIndicator();
    this.updateImage();
    this.updateAction();
  }

  get action(): TemplateSpec['action'] {
    return this._action;
  }

  set imageUrl(imageUrl: TemplateSpec['imageUrl']) {
    this._imageUrl = imageUrl;
    this.updateImage();
  }

  get notifyWhenFullyVisible() {
    return this._notifyWhenFullyVisible;
  }

  set notifyWhenFullyVisible(notifyWhenFullyVisible: boolean) {
    this._notifyWhenFullyVisible = notifyWhenFullyVisible;
    this.updateVisibilityIndicator();
  }

  // static getters required for collection wrapper (List, Carousel, etc.)
  static get width() {
    return 0;
  }

  static get height() {
    return 0;
  }

  static get highlightWidth() {
    return 0;
  }

  static get highlightHeight() {
    return 0;
  }

  static get expandedWidth(): number | null {
    return null;
  }

  static get expandedHeight(): number | null {
    return null;
  }

  static override _template(): Lightning.Component.Template<TileTemplateSpec> {
    return {
      shader: {
        type: Lightning.shaders.RoundedRectangle,
        radius: 10,
      },
      Placeholder: {
        type: Placeholder,
        visible: true,
        w: this.highlightWidth,
        h: this.highlightHeight,
      },
      Image: {
        rect: true,
        w: this.highlightWidth,
        h: this.highlightHeight,
      },
      VisibilityIndicator: {
        type: VisibilityIndicator,
        w: this.highlightWidth,
        h: this.highlightHeight,
        signals: { indicateFullyVisible: '$indicateFullyVisible' },
        visible: false,
      },
    };
  }

  override _setup() {
    super._setup();
    this._hasImageLoaded = false;
    this._isFullyVisible = false;
    this._hasSignaledVisibility = false;

    this._Image.on('txLoaded', () => {
      this._Placeholder.patch({ visible: false });
      this._hasImageLoaded = true;

      this.handleVisibilitySignal();
    });

    this._Image.on('txUnloaded', () => {
      this._Placeholder.patch({ visible: true });
      this._hasImageLoaded = false;
    });
  }

  override _inactive() {
    super._inactive();
    this.clearListeners();
  }

  override _handleEnter() {
    this.fireAncestors('$onTileSelected', this._data, {
      action: this.action,
      imageUrl: this._Image.src,
      parentContext: this.parentContext,
    });
  }

  private $indicateFullyVisible() {
    this._isFullyVisible = true;
    this.handleVisibilitySignal();
  }

  private updateVisibilityIndicator() {
    this._VisibilityIndicator.patch({
      visible: this._notifyWhenFullyVisible && !!this._data,
    });
  }

  private updateImage() {
    // Only update image when we have data and image URL
    if (!this._data || !this._imageUrl) return;

    const src = isContentHub(this._data)
      ? updateContentHubThumbnailUrl(this._imageUrl, this._Image.w)
      : updateImageSize(this._imageUrl, this._Image.w);
    this._Image.patch({ src });
  }

  private updateAction() {
    if (!this._data) return;
    this._action = isContentHub(this._data) ? 'navigation' : 'details';
  }

  protected handleVisibilitySignal() {
    if (
      this._notifyWhenFullyVisible &&
      !this._hasSignaledVisibility &&
      this._isFullyVisible &&
      this._hasImageLoaded &&
      this._data
    ) {
      this.fireAncestors(
        '$onTileFullyVisible',
        this.parentContext,
        this._data,
        this._Image.src ?? '',
      );

      this._hasSignaledVisibility = true;
    }
  }

  /**
   * Clears all txLoaded/txUnloaded listeners on the Image component, including
   * those created by components that extend the Tile class
   */
  protected clearListeners() {
    this._Image.removeAllListeners('txLoaded');
    this._Image.removeAllListeners('txUnloaded');
  }
}
